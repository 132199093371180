<div class="row">
    <div class="col-6">
        <form novalidate class="form-inline">
            <div class="form-row col-12 check-date-cycle">
                <div class="col">
                    <label class="col-form-label" for="checkDate">Check Date</label>
                </div>
                <div class="col">
                    <ng-select
                            class="mr-2"
                            id="checkDate"
                            name="checkDate"
                            placeholder="Select a Payroll Period"
                            [options]="checkDates"
                            [(ngModel)]="selectedCheckDate"
                            (ngModelChange)="checkDateChanged(selectedCheckDate)"
                            #selectCheckDateField>
                    </ng-select>

                </div>
                <div class="col-3"><small class="form-text text-muted">{{ duration }}</small></div>
                <div class="col">
                    <button type="button" class="btn btn-primary" (click)="openModal()">Payroll Report</button>
                </div>
            </div>
        </form>
    </div>
</div>

<!--<div class="row">-->
<!--    <div class="col-6 offset-1">-->
<!--        <div class="period-duration">-->
<!--            <div class="col-sm-1"></div>-->
<!--            <div class="col-sm-3"><small class="form-text text-muted">{{ duration }}</small></div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="row">
    <div class="col">
        <div class="fg-earnings-list">
            <tabset class="col-12">
                <tab *ngFor="let payCode of activePayCodes"
                     heading="{{payCode.description}} ({{payCode.code}})"
                     id="{{payCode.code}}">
                    <app-pay-earning-type [payCode]="payCode" [checkDate]="checkDate"></app-pay-earning-type>
                </tab>
            </tabset>
        </div>
    </div>
</div>





