export class PickupData {
    id: number;
    ticket_number: string;
    odometer: number;
    obstemp: number;
    avg_temp: number;
    obsgravity: number;
    corrgravity: number;
    bsw: number;
    tank_top_feet: number;
    tank_top_inches: number;
    tank_top_quarter_inches: number;
    tank_bottom_feet: number;
    tank_bottom_inches: number;
    tank_bottom_quarter_inches: number;
    seal_open: number;
    seal_close: number;
    gauge_reading: number;
    gross_barrels: number;
    created_at: string;
    updated_at: string;
    meter_factor: number;
    meter_open: number;
    meter_close: number;
    bol: string;
    notes: string;
    load_start: any;
    load_start_formatted: any;
    load_end: any;
    load_end_formatted: any;
    temp_top: number;
    temp_bottom: number;
    truck: any;
}
