import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class ReportsService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/reports');
    }

    getReports(): Observable<any[]> {
        return this.http.get<any[]>(this.getEndpoint())
            .pipe(  // TODO: for debugging...Remove this
                tap(
                    (result) => {
                        console.log(result);
                    }
                )
            );
    }

    getFilters(category: string, report: string, search): Observable<any> {
        category = category.toLowerCase();
        report = this.toCamelCase(report);
        return this.http.get<any>(this.getEndpoint() + `/${category}/${report}/filters`, {params: search})
            .pipe(  // TODO: for debugging...Remove this
                tap(
                    (result) => {
                        console.log(result);
                    }
                )
            );
    }

    processReport(category: string, report: string, filters: any, excludes: string[]): Observable<any[]> {
        category = category.toLowerCase();
        report = this.toCamelCase(report);
        return this.http.post<any[]>(this.getEndpoint() + `/${category}/${report}`, {
            filters: filters,
            excludes: excludes
        });
    }

    excelReport(category: string, report: string, filters: any, excludes: string[]): Observable<any[]> {
        category = category.toLowerCase();
        report = this.toCamelCase(report);
        return this.http.post<any[]>(this.getEndpoint() + `/${category}/${report}/excel`, {
            filters: filters,
            excludes: excludes
        });
    }


    toCamelCase(string) {
        const parts = string.split(' ');
        for (let i = 0; i < parts.length; i++) {
            if (i === 0) {
                parts[i] = parts[i].toLowerCase();
            } else {
                parts[i] = parts[i].toLowerCase().charAt(0).toUpperCase() + parts[i].slice(1);
            }
        }

        return parts.join('');
    }
}
