import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {retry, retryWhen} from 'rxjs/operators';
import {map} from 'rxjs/operators';
import {UserPermission} from '../../models/UserPermission';
import {UserPermissionAcl} from '../../models/UserPermissionAcl';

@Injectable()
export class UserPermissionsAclService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/permissions/permission');
    }

    getAcls(permissionId): Observable<UserPermissionAcl[]> {
        return this.http.get<UserPermissionAcl[]>(this.getEndpoint() + '/' + permissionId)
            .pipe(
                map((permissionsResponse: UserPermissionAcl[]) => {
                    return permissionsResponse;
                })
            );
    }

    async addAcl(permission: UserPermission, endpoint: string, method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE') {
        const options = {
            endpoint: endpoint,
            method: method
        };
        return await this.http.post(this.getEndpoint() + '/' + permission.id, options)
            .pipe(
                retry(3)
            ).toPromise();
    }

    async deleteAcl(permission: UserPermission, acl: UserPermissionAcl) {
        const options = {
            endpoint: acl.endpoint,
            method: acl.method
        };
        return await this.http.post(this.getEndpoint() + '/' + permission.id + '/delete', options)
            .pipe(
                retry(3)
            ).toPromise();
    }
}
