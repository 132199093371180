import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Terminal} from '../../models/terminal.model';

@Injectable()
export class TerminalsService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/terminal');
    }
    getTerminals(): Observable<Terminal[]> {
        return this.http.get<Terminal[]>(this.getEndpoint());
    }
}
