import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Service} from '../../models/service.model';

@Injectable()
export class ServicesService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/service');
    }
    getServices(): Observable<Service[]> {
        return this.http.get<Service[]>(this.getEndpoint())
    }
}
