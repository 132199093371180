import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Origin} from '../../models/origin.model';

@Injectable()
export class OriginsService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/origin');
    }
    getOrigins(): Observable<Origin[]> {
        return this.http.get<Origin[]>(this.getEndpoint());
    }
}
