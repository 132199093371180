<div class="card">
    <div class="card-header">
        <span class="float-left"><b>Orders</b> <i>There are {{orderCount}} orders.</i></span>
        <label class="switch switch-3d switch-text float-right mb-0" style="">
            <input class="switch-input" type="checkbox" [(ngModel)]="isHidden">
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
        </label>
    </div>
    <div class="card-body" [style.display]="isHidden ? 'none': 'block'">
        <div>
            <div class="input-group input-group-lg mb-2 mt-2" *ngIf="advancedSearch">
                <div class="input-group mb-3 col-md-4" *ngFor="let i of objectKeys(searchParams)">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{searchParams[i].label}}</span>
                    </div>
                    <input *ngIf="searchParams[i].type == 'text'" type="text" class="form-control" (change)="onSearch()" [(ngModel)]="searchParams[i].value"/>
                    <input *ngIf="searchParams[i].type == 'date'" type="text" class="form-control" (change)="onSearch()" [bsConfig]="dpConfig"
                           onkeydown="return false" (bsValueChange)="bsDateRangeChange($event, i)"
                           [(ngModel)]="searchParams[i].value" bsDaterangepicker/>
                    <ng-select *ngIf="searchParams[i].type == 'select'" [(ngModel)]="searchParams[i].value" class="form-control custom-select" style="padding: 0;" [options]="searchParams[i].options"
                               [allowClear]="true" filterPlaceholder="Type to search..." (selected)="onSearch()" [multiple]="true" (deselected)="onSearch()"></ng-select>

                    <div class="input-group-append" *ngIf="searchParams[i].value.length > 0">
                        <span class="input-group-text" (click)="searchParams[i].value = ''; onSearch()">
                            <i class="icon-trash"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="input-group input-group-lg mb-2 mt-2">
                <div class="input-group-prepend btn-toolbar">
                    <button (click)="onEditOrder()" class="btn btn-outline-primary">Edit Order</button>
                    <button (click)="gridApi.refresh()" class="btn btn-outline-primary">Refresh Orders</button>
                    <button (click)="exportCsv()" class="btn btn-outline-primary">CSV</button>
                    <button class="btn btn-outline-primary" (click)="advancedSearch = !advancedSearch"><i
                            class="icon-magnifier"></i> More</button>
                </div>
                <input
                        [readonly]="advancedSearch"
                        type="text"
                        [value]="searchString"
                        [(ngModel)]="searchString"
                        (change)="onSearch()"
                        class="form-control"
                        aria-describedby="inputGroup-sizing-lg"/>
                <div class="input-group-append">
                    <button class="btn btn-outline-primary" (click)="toggleSettings()"><i class="icon-settings"></i></button>
                </div>
            </div>
            <div class="table-responsive" [ng2-loading]="gridLoading">
                <lib-table-grid [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"></lib-table-grid>
            </div>
        </div>
    </div>
</div>
