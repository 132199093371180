import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Status} from '../../models/status.model';

@Injectable()
export class StatusesService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/status');
    }

    getStatuses(): Observable<Status[]> {
        return this.http.get<Status[]>(this.getEndpoint());
    }
}
