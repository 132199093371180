import {Component} from '@angular/core';
import {AppVersionService} from './services/appVersion.service';

@Component({
    // tslint:disable-next-line
    selector: 'body',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {

    public constructor(private _appVersionService: AppVersionService) {
        this._appVersionService.checkVersion().then();
    }
}
