import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Lease} from '../../../models/Lease.model';

@Component({
    selector: 'app-lease-restriction',
    templateUrl: './lease-restriction.component.html',
    styleUrls: ['./lease-restriction.component.scss']
})
export class LeaseRestrictionComponent implements OnInit, OnChanges {
    @Input() restriction: any;
    @Input() showEdit = false;
    @Output() onEdit = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();
    @Output() restricted = new EventEmitter<boolean>();
    progress = 0;

    constructor() {
    }

    onEditEvent() {
        this.onEdit.emit(this.restriction);
    }

    onDeleteEvent() {
        this.onDelete.emit(this.restriction);
    }

    getProgress() {
        const restriction = this.restriction;
        let progress = Math.round((restriction.total_hauled / restriction.total_allowance) * 100);
        if (progress < 0) {
            progress = Math.abs(progress) + 100;
        }

        if (progress >= 100) {
            this.restricted.emit(true);
        } else {
            this.restricted.emit(false);
        }

        this.progress = progress;

        return progress;
    }

    getProgressClass() {
        const progress = this.progress;
        let progressClass = 'progress-bar bg';
        if (progress >= 75) {
            progressClass += '-danger';
        } else if (progress >= 50) {
            progressClass += '-warning';
        } else {
            progressClass += '-success';
        }

        return progressClass;
    }

    fixDates() {
        if (typeof this.restriction.created_at.date !== 'undefined') {
            const created_at = new Date(this.restriction.created_at.date);
            const updated_at = new Date(this.restriction.updated_at.date);
            this.restriction.created_at = created_at.toLocaleString();
            this.restriction.updated_at = updated_at.toLocaleString();
        }
    }

    ngOnChanges() {
        this.fixDates();
    }

    ngOnInit() {
        this.fixDates();
        this.getProgress();
    }

}
