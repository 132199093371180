import {Component, ViewEncapsulation} from '@angular/core';
import {environment} from '../../../environments/environment';
import {UsersService} from '../../services/api/users.service';
import {AppVersionService} from '../../services/appVersion.service';
import {AuditLogService} from '../../services/api/audit-log.service';

declare var Headway: any;

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppHeaderComponent {
    apiUrl = environment.apiUrl;
    baseUrl;
    avatar: string;
    isCollapsed = false;

    private static _getBaseUrl() {
        const getUrl = window.location;
        return getUrl.protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[1];
    }

    constructor(private usersService: UsersService, public appVersionService: AppVersionService) {
        this.avatar =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABsUlEQVR42u3d4U3DUAyF0ZpRWIJRmJFRugSrwAJIqO2L33VyPEGbo89W1B+93UzU1LQP/PP5/vHwl/z6vgPZ9PCnI9VVAKYA1dUh0mAKQhZOgciCKRhZKAUiC6ZAZMEUiCyYt5s574vhlco4qpSCkYVSMLJQCkYWSsHIQikYWSgFIwulYGSheDGc+mKojJ5SFDKxEHX0VaKQaYWoo7cShUwqRB39lRSMLBQra8LKUse+ShQSNkDSV5Z1tXdtKSS5EHXsr0QhjroBMvGGuB8Zd0QhVpYBAsS8dNQddIUYIEAMECAGCBADBIgBYoAAMY+OXwyTMPxiaGUZIEDMiqPusO8/6AqxsgyQyTfEHdl7PxQyoRCV7KtDIY66eWplWVt71pVCJhWikv46/gWB0othZU1bWSrprUMhUwtRSU8dCplciFKOLUMhZylEJcfV8TQIlGMwXgKBsh7jZRAoazGWgEBZh7EMBMoajKUgV0eJ+y/cq8L4+24vhkrpKKMF5IwwR0G0gpwB5miILSBTUbowtoBMgumE2A6SirMDIQ4kAWY3RCRIJ1AKwCiQVUipD/+v+QU/Ato/BY+rhQAAAABJRU5ErkJggg==";
        this.usersService.getAvatar().subscribe(data => {
            this.avatar = data.avatar.encoded;
        });
        this.baseUrl = AppHeaderComponent._getBaseUrl();
    }

    getHeaderClass() {
        const isDevApi = (this.apiUrl === 'https://dev-api.eastexcrudeapp.com');
        const isProdApi = (this.apiUrl === 'https://api.eastexcrude.com');

        const isProdWeb = (this.baseUrl === 'https://dispatch.eastexcrude.com/');

        let className = 'app-header navbar';

        if (isProdApi && !isProdWeb) {
            className += ' bg-danger';
        } else if (isDevApi) {
            className += ' bg-success';
        }

        return className;
    }

    getNotifications(number: number) {
        this.isCollapsed = !this.isCollapsed;
        console.log('legit user ' + number);
        // this.usersService.getNotifications(number)
    }
}
