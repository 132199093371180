import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {map} from 'rxjs/operators';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';

@Injectable()
export class PageFiltersApiService extends ApiService {
    public bsModalRef;

    constructor(private http: HttpClient, public modalService: BsModalService) {
        super('/api/pageFilters');
    }

    getFilters(page: string, search) {
        page = this.toCamelCase(page);
        return this.http.get(this.getEndpoint() + `/${page}`, {params: search})
            .pipe(map((res) => this.mapResponse(res)));
    }

    mapResponse(res) {
        for (let i = 0; i < res.length; i++) {
            const filter = res[i];
            if (typeof filter.options !== 'undefined' && filter.options) {
                for (let i2 = 0; i2 < filter.options.length; i2++) {
                    res[i].options[i2].value = res[i].options[i2].value.toString();
                }
            }
        }
        return res;
    }

    toCamelCase(string) {
        const parts = string.split(' ');
        for (let i = 0; i < parts.length; i++) {
            parts[i] = parts[i].toLowerCase().charAt(0).toUpperCase() + parts[i].slice(1);
        }

        return parts.join('');
    }
}
