import {Injectable, Injector} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authService;
    private returnUrl;
    private refreshingToken = false;

    constructor(private injector: Injector, private router: Router) {
        this.returnUrl = this.router.url;
        /*Had to use this hack to avoid the cyclic dependency injection issue present
        in the current build of angular
         */
        setTimeout(() => {
            this.authService = this.injector.get(AuthService);
        })
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.access_token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.access_token}`
                }
            });
        }

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // Add caching here
                if (typeof event.body !== 'undefined' && typeof event.body.expired !== 'undefined') {
                    if (event.body.expired === true && this.router.url !== '/user') {
                        this.router.navigate(['/user'], {
                            queryParams: {
                                expired: true
                            }
                        }).then();
                    }
                }
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401 && request.url.indexOf('oauth/token') === -1) {
                    this.authService.logout();
                    this.router.navigate(['/pages/login'], {
                        queryParams: {
                            returnUrl: this.returnUrl
                        }
                    }).then();
                }
            }
        }));
    }
}
