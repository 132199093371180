<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>

<div class="modal-body modal-lg">
    <div class="row" *ngFor="let filterName of objectKeys(pageFilters); ">
        <div class="col-md-12">
            <div class="form-group" >
                <label>{{filterName}}</label>
                <input *ngIf="pageFilters[filterName].type == 'Text'" type="text" class="form-control form-control-sm" [(ngModel)]="pageFilterValues[pageFilters[filterName].name]" />
                <input *ngIf="pageFilters[filterName].type == 'Date' || pageFilters[filterName].type == 'DateTime'" type="text" class="form-control form-control-sm theme-dark-blue" [(ngModel)]="pageFilterValues[pageFilters[filterName].name]" bsDatepicker/>
                <ng-select *ngIf="pageFilters[filterName].type == 'Select'"class="bootstrap" [(ngModel)]="pageFilterValues[pageFilters[filterName].name]"
                           placeholder="Select" [options]="pageFilters[filterName].options" (filterInputChanged)="filterSearch(pageFilters[filterName], $event)"
                           (selected)="filterSelected(pageFilters[filterName], $event)" (deselected)="filterDeselected(pageFilters[filterName], $event)" [allowClear]="true" filterPlaceholder="Type to search..."
                            [multiple]="pageFilters[filterName].multiSelect"></ng-select>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="onCancel()">{{closeBtnName}}</button>
    <button type="button" class="btn btn-success" (click)="onConfirm()">Submit</button>
</div>

