<header [class]="getHeaderClass()">
    <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
    <a class="navbar-brand"></a>
    <button class="navbar-toggler d-md-down-none mr-auto" type="button" appSidebarToggler>&#9776;</button>
    <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>
        <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="nav navbar-nav ml-auto">
        <li>
            <a class="nav-link" role="button" aria-haspopup="false" aria-expanded="false" (click)="appVersionService.showWhatsNew()">
                <i class="icon-bell"></i>
            </a>
        </li>
        <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
               dropdownToggle (click)="false">
                <img src="{{avatar}}" class="img-avatar" alt="zach.rogers@eastexcrude.com" *ngIf="avatar"/>
            </a>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="dropdown-header text-center"><strong>Account</strong></div>
                <!--<a class="dropdown-item"><i class="fa fa-bell-o"></i> Updates<span class="badge badge-info">42</span></a>-->
                <a class="dropdown-item" [routerLink]="['/user']"><i class="fa fa-wrench"></i> Settings</a>
                <a class="dropdown-item" [routerLink]="['/pages/login/logout']"><i class="fa fa-lock"></i> Logout</a>
            </div>
        </li>
        <button class="navbar-toggler d-md-down-none" type="button" appAsideMenuToggler>
            <span class="navbar-toggler-icon"></span>
        </button>
    </ul>
    <button class="navbar-toggler" type="button" appAsideMenuToggler>&#9776;</button>
</header>
