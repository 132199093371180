import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Pickup} from '../../models/pickup.model';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';

@Component({
    selector: 'app-modal-component',
    templateUrl: './delete-pickup-modal.component.html',
    styleUrls: ['./delete-pickup-modal.component.scss']
})
export class DeletePickupModalComponent implements OnInit {
    title: string;
    closeBtnName: string;
    list: any[] = [];
    active: boolean;
    onClose: Subject<boolean>;
    data: any[] = [];
    template = '';
    pickup: Pickup;
    id = 0;
    confirm = false;

    constructor(public bsModalRef: BsModalRef,
                private changeDetector: ChangeDetectorRef
    ) {
        this.onClose = new Subject();

    }

    async ngOnInit() {
        // Yes this is on purpose to clone the object without reference.
    }

    public onConfirm(): void {
        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.active = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this.bsModalRef.hide();
    }
}
