import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {LoadType} from '../../models/load-type.model';

@Injectable()
export class LoadTypesService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/load-type');
    }
    getLoadTypes(): Observable<LoadType[]>{
        return this.http.get<LoadType[]>(this.getEndpoint());
    }
}
