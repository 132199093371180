import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// Import Containers
import {
    FullLayoutComponent,
    SimpleLayoutComponent
} from './containers';
import {AuthGuard} from './services/auth/auth.guard';
import {FormsModule} from '@angular/forms';

export const routes: Routes = [
    {path: '', redirectTo: '/pages/login', pathMatch: 'full'},
    {
        path: '', component: FullLayoutComponent, data: {title: 'Home'}, canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'order',
                loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'dispatch',
                loadChildren: () => import('./views/dispatch/dispatch.module').then(m => m.DispatchModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'driver-log',
                loadChildren: () => import('./views/driver-log/driver-log.module').then(m => m.DriverLogModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'eom',
                loadChildren: () => import('./views/eom/eom.module').then(m => m.EomModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'tank-strappings',
                loadChildren: () => import('./views/tank-strappings/tank-strappings.module').then(m => m.TankStrappingsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'reports',
                loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'restrictions',
                loadChildren: () => import('./views/restrictions/restrictions.module').then(m => m.RestrictionsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'audit-log',
                loadChildren: () => import('./views/audit-log/audit-log.module').then(m => m.AuditLogModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'users',
                loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'drivers',
                loadChildren: () => import('./views/drivers/drivers-page.module').then(m => m.DriversPageModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'payroll',
                loadChildren: () => import('./views/payroll/payroll.module').then(m => m.PayrollModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'user',
                loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'scheduled-tasks',
                loadChildren: () => import('./views/task-scheduler/task-scheduler.module').then(m => m.TaskSchedulerModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'ticket/:number',
                loadChildren: () => import('./views/ticket/ticket.module').then(m => m.TicketModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'ticket/eom/:number/:id',
                loadChildren: () => import('./views/ticket/ticket.module').then(m => m.TicketModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'ticket/unload/:number',
                loadChildren: () => import('./views/ticket/ticket.module').then(m => m.TicketModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'permissions',
                loadChildren: () => import('./views/permissions-page/permissions-page.module').then(m => m.PermissionsPageModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'third-party-haulers',
                loadChildren: () => import('./views/third-party-haulers/third-party-haulers.module').then(m => m.ThirdPartyHaulersModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'sellers',
                loadChildren: () => import('./views/sellers/sellers.module').then(m => m.SellersModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'leases',
                loadChildren: () => import('./views/leases/leases.module').then(m => m.LeasesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'operators',
                loadChildren: () => import('./views/operators/operators.module').then(m => m.OperatorsModule),
                canActivate: [AuthGuard]
            },
        ],
    },
    {
        path: 'pages', component: SimpleLayoutComponent,
        data: {title: 'Pages'},
        children: [
            {
                path: '',
                loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes), FormsModule],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
