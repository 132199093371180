import {Component} from '@angular/core';
import {TableGridDetails, TableGridOptions} from 'angular-table-grid';

@Component({
    selector: 'app-google-map-component',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements TableGridDetails {
    public data: any;
    public params: any;
    public gridOptions: TableGridOptions;
    public gridRow;
    public gridCell;

    constructor() {
    }

    detailsInit(node) {
        this.data = node;
    }
}
