import {Component} from '@angular/core';
import {DispatchPickupDataOptionsProvider} from '../../providers/dispatch-pickup-data-options.provider';
import {Unload} from '../../models/unload.model';
import {Pickup} from '../../models/pickup.model';
import {DispatchUnloadDataOptionsProvider} from '../../providers/dispatch-unload-data-options.provider';
import {TableGridComponent, TableGridDetails, TableGridOptions, TableGridRowDataResponse} from 'angular-table-grid';
import {TableGridRowDataRequest} from 'angular-table-grid';
import {Observable} from 'rxjs';
import {GoogleMapComponent} from '../googleMap/google-map.component';
import {PickupData} from '../../models/pickup-data.model';
import {UnloadData} from '../../models/unload-data.model';
import * as moment from 'moment';
import {OrdersService} from '../../services/api/orders.service';

@Component({
    selector: 'app-master-detail-component',
    templateUrl: './master-detail.component.html',
    styleUrls: ['./master-detail.component.scss']
})
export class MasterDetailComponent implements TableGridDetails {
    public data: any;
    public params: any;
    public pickupGridOptions: TableGridOptions;
    public unloadGridOptions: TableGridOptions;
    public pickupGridApi;
    public unloadGridApi;
    public pickupGridColumnApi;
    public unloadGridColumnApi;
    public gridOptions: TableGridOptions;
    public gridRow;
    public gridCell;
    public pickupData: Pickup[];
    public unloadData: Unload[];
    private _selectedRow: any;

    constructor(public orderService: OrdersService) {
        this.pickupGridOptions = <TableGridOptions>{
            columns: DispatchPickupDataOptionsProvider.getPickupColumns(),
            getRowData: (rowDataRequest: TableGridRowDataRequest) => {
                return new Observable<any>(observer => {
                    this.data.pickups.map(pickup => {
                        if (pickup.pickup_data === null) {
                            pickup.pickup_data = new PickupData()
                        }
                        if (pickup.corrected_at) {
                            pickup.corrected_at_formatted = moment.utc(pickup.corrected_at).local().format('M/D/YY h:mm a');
                        }

                        pickup.alt_ticket_number = '';
                        if (pickup.ticket_number) {
                            pickup.alt_ticket_number = parseInt(pickup.ticket_number, 32).toString();
                        }
                        return pickup;
                    });
                    const rowData = <TableGridRowDataResponse>{
                        rows: this.data.pickups,
                        totalRows: this.data.pickups.length
                    };
                    observer.next(rowData);
                    return {
                        unsubscribe() {
                        }
                    };
                });
            },
            getDetails(rowNode: any) {
                if (!rowNode.pickup_data) {
                    return '';
                }
                if (rowNode.pickup_data.latitude && rowNode.pickup_data.longitude) {
                    const latLng = rowNode.pickup_data.latitude + ',' + rowNode.pickup_data.longitude;
                    return 'https://maps.googleapis.com/maps/api/staticmap?center='
                        + latLng + '&zoom=13&size=600x300&maptype=roadmap&markers=icon:|' + latLng
                        + '&key=AIzaSyDkZ_Fri8SAgjwDiE6QNaajZGOFnvQVPjI';
                } else {
                    return '';
                }
            },
            getRowStyles: (node: any) => {
                const styles = {
                    'border-left': 'none',
                };
                if (node.corrected_by) {
                    styles['border-left'] = '6px solid rgba(255, 204, 0, 0.5)';
                } else if (node.reject) {
                    styles['border-left'] = '6px solid rgba(255, 0, 0, 0.5)';
                } else if (node.pickup_data) {
                    styles['border-left'] = '6px solid rgba(3, 171, 122, 0.5)';
                }
                return styles;
            },
            enableDetails: true,
            detailComponent: GoogleMapComponent
        };

        this.unloadGridOptions = <TableGridOptions>{
            columns: DispatchUnloadDataOptionsProvider.getColumns(),
            getRowData: (rowDataRequest: TableGridRowDataRequest) => {
                return new Observable<any>(observer => {
                    this.data.unloads.map(unload => {
                        if (unload.unload_data === null) {
                            unload.unload_data = new UnloadData()
                        }
                        if (unload.corrected_at) {
                            unload.corrected_at_formatted = moment.utc(unload.corrected_at).local().format('M/D/YY h:mm a');
                        }

                        unload.alt_ticket_number = '';
                        if (unload.ticket_number) {
                            unload.alt_ticket_number = parseInt(unload.ticket_number, 32).toString();
                        }
                        return unload;
                    });
                    const rowData = <TableGridRowDataResponse>{
                        rows: this.data.unloads,
                        totalRows: this.data.unloads.length
                    };
                    observer.next(rowData);
                    return {
                        unsubscribe() {
                        }
                    };
                });
            },
            getDetails(rowNode: any) {
                if (!rowNode.unload_data) {
                    return '';
                }
                if (rowNode.unload_data.latitude && rowNode.unload_data.longitude) {
                    const latLng = rowNode.unload_data.latitude + ',' + rowNode.unload_data.longitude;
                    return 'https://maps.googleapis.com/maps/api/staticmap?center='
                        + latLng + '&zoom=13&size=600x300&maptype=roadmap&markers=icon:|' + latLng
                        + '&key=AIzaSyDkZ_Fri8SAgjwDiE6QNaajZGOFnvQVPjI';
                } else {
                    return '';
                }
            },
            getRowStyles: (node: any) => {
                const styles = {
                    'border-left': 'none',
                };
                if (node.corrected_by) {
                    styles['border-left'] = '6px solid rgba(255, 204, 0, 0.5)';
                } else if (node.unload_data) {
                    styles['border-left'] = '6px solid rgba(63, 191, 63, 0.5)';
                }
                return styles;
            },
            enableDetails: true,
            detailComponent: GoogleMapComponent
        };
    }

    detailsInit(node) {
        this.data = node;
    }

    unloadCanGenerateTicket() {
        return this._selectedRow && !this._selectedRow.ticket_number;
    }

    unloadGenerateTicket() {
        console.log(this._selectedRow);
        this.orderService.generateUnloadTicketNumber(this._selectedRow).then((response: any) => {
            console.log(response);
            this._selectedRow.ticket_number = response.data.ticket_number;
        });
    }

    unloadGridReady(gridApi: TableGridComponent) {
        this.unloadGridApi = gridApi;
        this.unloadGridApi.select.subscribe((node) => {
            this._selectedRow = node;
        });

        this.unloadGridApi.deselect.subscribe((row) => {
            if (this.unloadGridApi.getSelectedRows().length > 0) {
                this._selectedRow = this.unloadGridApi.getSelectedRows()[0];
            } else {
                this._selectedRow = null;
            }
        });
    }
}
