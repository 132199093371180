import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService} from '../api.service';
import {catchError, map, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {ITankStrapping} from '../../models/tank-strapping.model';
// import { MessageService } from '../message.service';
import {debug, RxJsLoggingLevel} from '../../common/debug';




export class TankStrappingResponse {
    data: {
        lastRow: number;
        rows: ITankStrapping[];
        query: string;
    };
    errors: any[];
    success: boolean;
    expired: boolean;
    version: string;
}


@Injectable()
export class TankStrappingsService extends ApiService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    // private tankStrappingSubject = new BehaviorSubject([]);
    // tankStrappings$ = this.tankStrappingSubject.asObservable();

    constructor(
        private http: HttpClient,
        // private messageService: MessageService
    ) {
        super('/api/tank-strappings');
    }


    /**
     *
     * @param field
     */
    getValues(field): Observable<any> {
        const setFilterUrl = this.getEndpoint() + '/field/' + field;

        return this.http.get(setFilterUrl, this.httpOptions)
            .pipe(
                catchError(this.handleError<any>('getValues', []))
            );
    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }


    /** Log a HeroService message with the MessageService */
    // private log(message: string) {
    //     console.log(message);
    //     this.messageService.add(`HeroService: ${message}`);
    // }

    /**
     * <TankStrappingResponse>
     * @param header
     */
    getStrappings(header): Observable<TankStrappingResponse> {
        return this.http.post<TankStrappingResponse>(this.getEndpoint() + '/loadGrid', header, this.httpOptions)
            .pipe(
                // debug(RxJsLoggingLevel.INFO, 'AG-GRID TankStrappingResponse: '),
                map((response: any) => {
                    response.data.rows = response.data.rows.map(this.mapAgGridStrappings)
                    return response;
                }),
                // debug(RxJsLoggingLevel.INFO, 'AG-GRID TankStrappings: '),

                catchError(this.handleError<any>('getStrappings', ))
            );
    }

    getTankStrappings(start = null, end = null, search = null, sort = null, order = null): Observable<any> {
        let params: any = {};
        if (start !== null && end !== null) {
            params = {start: start, end: end}
        }

        if (sort !== null) {
            params.sort = sort;
        }

        if (order !== null) {
            params.order = order;
        }
        return this.http.post<any>(this.getEndpoint(), {_pageFilters: search}, {
            params: params
        })
            .pipe(
                map((response: any) => {
                    response.data.data = response.data.data.map(this.mapStrappings);
                    return response;
                })
            );
    }

    saveStrapping(strapping: any) {
        const record = this.mapForApi(strapping);
        return this.http.post<any>(this.getEndpoint() + '/save', record);
    }

    getExcel(ids: any) {
        return this.http.post(this.getEndpoint() + '/excel', ids);
    }

    sync(ids: any) {
        return this.http.post<any>(this.getEndpoint() + '/sync', {ids: ids});
    }

    mapForApi(strapping) {
        const record = {
            user_id: strapping.user_id,
            strapped_by: strapping.strapped_by,
            net_barrels: strapping.net_barrels,
            tank_number: strapping.tank_number,
            tank_height_ft: strapping.height.feet,
            tank_height_in: strapping.height.inches,
            tank_height_qtr_in: strapping.height.quarterInches,
            manhole_height_in: strapping.cleanOut.height.inches,
            manhole_height_qtr_in: strapping.cleanOut.height.quarterInches,
            manhole_width_in: strapping.cleanOut.width.inches,
            manhole_width_qtr_in: strapping.cleanOut.width.quarterInches,
            manhole_arched_smallest_in: strapping.cleanOut.thinnest.inches,
            manhole_arched_smallest_qtr_in: strapping.cleanOut.thinnest.quarterInches,
            manhole_arched_largest_in: strapping.cleanOut.thickest.inches,
            manhole_arched_largest_qtr_in: strapping.cleanOut.thickest.quarterInches,
            comments_for_office_use_only: null,
            certified_by_remarks: null,
            certified_by_date: strapping.certified_by_date,
            barrels: strapping.height.barrels,
            rings: [],
            notes: strapping.notes,
            increments: strapping.increments
        };
        for (let i = 0; i < strapping.rings.length; i++) {
            const ring = strapping.rings[i];
            if (ring.feet + ring.inches + ring.quarterInches > 0) {
                record.rings.push({
                    feet: ring.feet,
                    inches: ring.inches,
                    quarter_inches: ring.quarterInches,
                    thickness: ring.thickness,
                    circumference: ring.circumference,
                    increment: strapping.increments.rings[i + 1].increment
                });
            }
        }
        return record;
    }

    mapStrappings(dataRow) {
        dataRow.tank.strapping = {
            rings: dataRow.rings.map((ring: any) => {
                return {
                    feet: ring.feet,
                    inches: ring.inches,
                    quarterInches: ring.quarter_inches,
                    circumference: ring.circumference,
                    thickness: ring.thickness
                }
            }),
            height: {
                feet: dataRow.tank_height_ft,
                inches: dataRow.tank_height_in,
                quarterInches: dataRow.tank_height_qtr_in,
                barrels: dataRow.barrels
            },
            cleanOut: {
                height: {
                    inches: dataRow.manhole_height_in,
                    quarterInches: dataRow.manhole_height_qtr_in
                },
                width: {
                    inches: dataRow.manhole_width_in,
                    quarterInches: dataRow.manhole_width_qtr_in
                },
                thinnest: {
                    inches: dataRow.manhole_arched_smallest_in,
                    quarterInches: dataRow.manhole_arched_smallest_qtr_in
                },
                thickest: {
                    inches: dataRow.manhole_arched_largest_in,
                    quarterInches: dataRow.manhole_arched_largest_qtr_in
                },
            },
            increments: {
                rings: [
                    {
                        feet: '',
                        inches: '',
                        quarterInches: '',
                        increment: ''
                    }
                ]
            }
        };
        return dataRow;
    }

    /**
     *
     * @param dataRow
     */
    mapAgGridStrappings(dataRow) {

        dataRow.created_at = moment.utc(dataRow.created_at).local().format('M/D/YY h:mm a');
        dataRow.net_barrels = Number(dataRow.net_barrels);

        let inches = dataRow.tank_net_height_inches;
        if (dataRow.tank_net_height_qtr_inches > 0) {
            inches += dataRow.tank_net_height_qtr_inches / 4;
        }
        dataRow.strapped_height = dataRow.tank_net_height_feet + '\' ' + inches + '"';

        if (dataRow.tank) {

            dataRow.tank.strapping = {
                rings: dataRow.rings.map((ring: any) => {
                    return {
                        feet: ring.feet,
                        inches: ring.inches,
                        quarterInches: ring.quarter_inches,
                        circumference: ring.circumference,
                        thickness: ring.thickness
                    }
                }),
                height: {
                    feet: dataRow.tank_height_ft,
                    inches: dataRow.tank_height_in,
                    quarterInches: dataRow.tank_height_qtr_in,
                    barrels: dataRow.barrels
                },
                cleanOut: {
                    height: {
                        inches: dataRow.manhole_height_in,
                        quarterInches: dataRow.manhole_height_qtr_in
                    },
                    width: {
                        inches: dataRow.manhole_width_in,
                        quarterInches: dataRow.manhole_width_qtr_in
                    },
                    thinnest: {
                        inches: dataRow.manhole_arched_smallest_in,
                        quarterInches: dataRow.manhole_arched_smallest_qtr_in
                    },
                    thickest: {
                        inches: dataRow.manhole_arched_largest_in,
                        quarterInches: dataRow.manhole_arched_largest_qtr_in
                    },
                },
                increments: {
                    rings: [
                        {
                            feet: '',
                            inches: '',
                            quarterInches: '',
                            increment: ''
                        }
                    ]
                }
            };
        }
        return dataRow;

    }
}
