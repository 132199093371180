import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { LicenseManager } from '@ag-grid-enterprise/core'
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

{
    // check for environment mismatches between the web and the API
    const loc = window.location;
    const baseUrl = loc.protocol + '//' + loc.host + '/' + loc.pathname.split('/')[1];
    const isProdWeb = (baseUrl.indexOf('//dispatch.eastexcrude.com') > -1);
    const isProdApi = (environment.apiUrl.indexOf('//api.eastexcrude.com') > -1);

    if (isProdWeb && !isProdApi) {
        // we won't crash production, but we need to be alerted if the API is not set correctly
        console.error("API endpoint is NOT set to production ('" + environment.apiUrl + "') for '" + baseUrl + "'");
    } else if (!isProdWeb) {
        // if not production, make the site is not targeting the production API
        if (isProdApi) {
            throw new Error("API endpoint is set to production ('" + environment.apiUrl + "') for '" + baseUrl + "'");
        } else {
            // otherwise, attempt to detect that there is not an environment mismatch (local vs. dev vs. staging, etc.)
            let match : RegExpMatchArray, apiSubdomain : string, dispatchSubdomain : string;
            if (match = environment.apiUrl.match(/^\w+:\/+(.*?)(\.eastexcrude|[:/])/)) {
                // prod: api, staging: api-stg, dev: api-dev or dev-api
                apiSubdomain = match[1].toLowerCase().replace(/-*api-*/g, "");
            }
            if (match = baseUrl.match(/^\w+:\/+(.*?)(\.eastexcrude|[:/])/)) {
                // prod: dispatch, staging: dispatch-stg, dev: dispatch-dev or dev-web
                dispatchSubdomain = match[1].toLowerCase().replace(/-*(dispatch|web)-*/g, "");
            }
            if (apiSubdomain != dispatchSubdomain) {
                console.warn("API environment mismatch: " + environment.apiUrl + " (comparing '" + apiSubdomain + "' to '" + dispatchSubdomain + "')");
            } else {
                console.log("API endpoint matches ('" + environment.apiUrl + "') for '" + baseUrl + "'");
            }
        }
    }
}

if (environment.production) {
    enableProdMode();
}

LicenseManager.setLicenseKey(environment.gridLicense);

platformBrowserDynamic().bootstrapModule(AppModule);
