import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {navigation} from './../../_nav';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    private _currentUser;
    public navigation = navigation;
    private _timer;

    constructor(private router: Router, private authService: AuthService) {
        this._updatePermissions().then();
    }

    canActivate(route: ActivatedRouteSnapshot) {
        this._currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this._currentUser) {
            // logged in so return true
            const path = '/' + route.routeConfig.path;
            const filterNav = this.navigation.filter((item) => {
                return (typeof item.url !== 'undefined' && item.url === path);
            });

            if (filterNav.length > 0) {
                const navItem = filterNav[0];
                return this.can(navItem);
            }
            return true;
        }


        // not logged in so redirect to login page with the return url
        this.router.navigate(['/pages/login'], {queryParams: {returnUrl: route.url}});
        return false;
    }

    public can(item) {
        if (typeof item.guarded === 'undefined' || !item.guarded) {
            return true;
        }

        if (typeof this._currentUser.access === 'undefined') {
            return false;
        }

        const guards = item.guarded;
        let can = false;
        for (let i = 0; i < guards.length; i++) {
            const guard = guards[i].split(':');
            const guardType = guard[0];
            const guardKey = guard[1];
            if (typeof this._currentUser === 'undefined') {
                return false;
            }
            if (guardType === 'role') {
                if (this.authService.hasRole(guardKey)) {
                    can = true;
                    break;
                }
            } else if (guardType === 'permission') {
                if (this.authService.hasPermission(guardKey)) {
                    can = true;
                    break;
                }
            }
        }
        return can;
    }

    private async _updatePermissions() {
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }
        if (this.authService.isLoggedIn()) {
            this._currentUser = await this.authService.getPermissions()
            this._timer = setTimeout(() => {
                this._updatePermissions().then();
            }, 30000);
        } else {
            this._timer = setTimeout(() => {
                this._updatePermissions().then();
            }, 30000);
        }
    }
}
