<div class="form-group" [ng2-loading]="showLoading">
    <div *ngIf="restriction !== false">
        <div class="alert alert-warning">Restrictions</div>
        <app-lease-restriction [restriction]="restriction"
                               (restricted)="onLeaseRestricted($event)"></app-lease-restriction>
    </div>
    <div *ngIf="pickups.length === 0 || addSplit" class="b-b-1">
        <div class="form-group row">
            <div class="col-md">
                <label for="operator" class="col-form-label ">Operator</label>
                <!-- Operator field -->
                <ng-select *ngIf="pageFiltersLoaded" class="bootstrap" [(ngModel)]="pageFilterValues['Operator']"
                           #pageFilter
                           placeholder="Select" [options]="pageFilters['Operator'].options"
                           (filterInputChanged)="filterSearch(pageFilters['Operator'], $event)"
                           (selected)="filterSelected(pageFilters['Operator'], $event)"
                           (deselected)="filterDeselected(pageFilters['Operator'], $event)" [allowClear]="true"
                           filterPlaceholder="Select Operator..."
                           [multiple]="pageFilters['Operator'].multiSelect"></ng-select>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md">
                <label for="operator" class="col-form-label ">Lease</label>
                <!-- Lease field -->
                <ng-select *ngIf="pageFiltersLoaded" class="bootstrap" [(ngModel)]="pageFilterValues['Lease']"
                           placeholder="Select" [options]="pageFilters['Lease'].options"
                           (filterInputChanged)="filterSearch(pageFilters['Lease'], $event)"
                           (selected)="filterSelected(pageFilters['Lease'], $event)"
                           (deselected)="filterDeselected(pageFilters['Lease'], $event)" [allowClear]="true"
                           filterPlaceholder="Select Lease..."
                           [multiple]="pageFilters['Lease'].multiSelect"></ng-select>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md">
                <label for="operator" class="col-form-label ">Equipment</label>
                <!-- Equipment Field -->
                <ng-select *ngIf="pageFiltersLoaded" class="bootstrap" [(ngModel)]="pageFilterValues['Equipment']"
                           placeholder="Select" [options]="pageFilters['Equipment'].options"
                           (filterInputChanged)="filterSearch(pageFilters['Equipment'], $event)"
                           (selected)="filterSelected(pageFilters['Equipment'], $event); selectEquipment($event)"
                           (deselected)="filterDeselected(pageFilters['Equipment'], $event); selectedEquipment = null"
                           [allowClear]="true" filterPlaceholder="Select Equipment..."
                           [multiple]="pageFilters['Equipment'].multiSelect"></ng-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="alert alert-danger" role="alert" *ngIf="leaseIsRestricted">
                This lease is currently restricted.
            </div>
            <button *ngIf="pickups.length > 0 && addSplit" style="margin-top: 7px;" type="button"
                    class="btn btn-danger btn-block" (click)="cancelSplit();">
                <span *ngIf="pickups.length === 0 || addSplit">Cancel Split</span>
            </button>
            <button *ngIf="pickups.length === 0 || addSplit" style="margin-top: 7px;"
                    [disabled]="!((pageFilterValues['Meter'] || pageFilterValues['Tank'] || unknowEquipment || pageFilterValues['Equipment']) && pageFilterValues['Lease'])"
                    type="button"
                    class="btn btn-info btn-block" (click)="addPickup()">
                <span *ngIf="pickups.length === 0 || addSplit">Load</span>
            </button>
            <button *ngIf="pickups.length > 0 && !addSplit" style="margin-top: 7px;" type="button"
                    class="btn btn-info btn-block" (click)="addSplit = true">
                <span>Add Split</span>
            </button>
        </div>
    </div>
    <div class="row mx-0 mt-3" *ngIf="pickups.length > 0">

    </div>
    <div *ngIf="pickups.length > 0 && !addSplit">
        <div class="form-group row">
            <div class="col-md-3">
                <table class="table table-responsive table-striped table-sm">
                    <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Lease</th>
                        <th>Equipment</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let pickup of pickups">
                        <td>
                            <button type="button" class="btn btn-outline-danger"
                                    (click)="removePickup(pickup)"><i class="icon icon-trash"></i>
                            </button>
                            <button type="button"
                                    [class]="(pickup.lease_id === leaseDetails) ? 'btn btn-info' : 'btn btn-outline-info'"
                                    (click)="showDetails(pickup)"><i class="icon icon-screen-desktop"></i>
                            </button>
                        </td>
                        <td>{{pickup.lease_name}}</td>
                        <td>{{pickup.equipment_name}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!-- List of offload locations associated with the lease should populate here for user to select -->
            <div class="col-md-3">
                <label for="offloadloc" class="col-form-label ">Offload</label>
                <ng-select *ngIf="pageFiltersLoaded" class="bootstrap" [(ngModel)]="pageFilterValues['Offload']"
                           placeholder="Select" [options]="pageFilters['Offload'].options"
                           (filterInputChanged)="filterSearch(pageFilters['Offload'], $event)"
                           (selected)="filterSelected(pageFilters['Offload'], $event)"
                           (deselected)="filterDeselected(pageFilters['Offload'], $event)" [allowClear]="true"
                           filterPlaceholder="Select Tank..."
                           [multiple]="pageFilters['Offload'].multiSelect"></ng-select>
            </div>
            <div class="col-md-3 form-group">
                <label for="externalticket" class="col-form-label ">External Ticket Number</label>
                <div class="input-group">
                    <input type="text" id="externalticket" [(ngModel)]="externalTicketNumber"
                           class="form-control form-control-sm">
                </div>
            </div>

        </div>
        <div class="form-group row">
            <div class="col-md-3">
                <label for="status" class="col-form-label ">Status</label>
                <ng-select *ngIf="pageFiltersLoaded" class="bootstrap" [(ngModel)]="pageFilterValues['Status']"
                           placeholder="Select" [options]="pageFilters['Status'].options"
                           (filterInputChanged)="filterSearch(pageFilters['Status'], $event)"
                           (selected)="filterSelected(pageFilters['Status'], $event)"
                           (deselected)="filterDeselected(pageFilters['Status'], $event)" [allowClear]="false"
                           filterPlaceholder="Select Tank..."
                           [multiple]="pageFilters['Status'].multiSelect"></ng-select>
            </div>
            <!-- Driver is if the order was called or emailed in. User must select one -->
            <div class="col-md-3 form-group">
                <label for="driver" class="col-form-label ">Driver</label>
                <ng-select *ngIf="pageFiltersLoaded" class="bootstrap" [(ngModel)]="pageFilterValues['Driver']"
                           placeholder="Select" [options]="pageFilters['Driver'].options"
                           (filterInputChanged)="filterSearch(pageFilters['Driver'], $event)"
                           (selected)="filterSelected(pageFilters['Driver'], $event)"
                           (deselected)="filterDeselected(pageFilters['Driver'], $event)" [allowClear]="true"
                           filterPlaceholder="Select Tank..."
                           [multiple]="pageFilters['Driver'].multiSelect"></ng-select>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-3">
                <label for="priority" class="col-form-label ">Priority</label>
                <ng-select *ngIf="pageFiltersLoaded" class="bootstrap" [(ngModel)]="pageFilterValues['Priority']"
                           placeholder="Select" [options]="pageFilters['Priority'].options"
                           (filterInputChanged)="filterSearch(pageFilters['Priority'], $event)"
                           (selected)="filterSelected(pageFilters['Priority'], $event)"
                           (deselected)="filterDeselected(pageFilters['Priority'], $event)" [allowClear]="true"
                           filterPlaceholder="Select Tank..."
                           [multiple]="pageFilters['Priority'].multiSelect"></ng-select>
            </div>
            <!-- Origin is if the order was called or emailed in. User must select one -->
            <div class="col-md-3 form-group">
                <label for="origin" class="col-form-label ">Origin</label>
                <ng-select *ngIf="pageFiltersLoaded" class="bootstrap" [(ngModel)]="pageFilterValues['Origin']"
                           placeholder="Select" [options]="pageFilters['Origin'].options"
                           (filterInputChanged)="filterSearch(pageFilters['Origin'], $event)"
                           (selected)="filterSelected(pageFilters['Origin'], $event)"
                           (deselected)="filterDeselected(pageFilters['Origin'], $event)" [allowClear]="true"
                           filterPlaceholder="Select Tank..."
                           [multiple]="pageFilters['Origin'].multiSelect"></ng-select>
            </div>
            <div class="col-md-3 form-group">
                <label for="multipleorder" class="col-form-label ">Multiple Orders</label>
                <div class="input-group">
                    <input type="text" id="multipleorder" [(ngModel)]="multipleOrders"
                           class="form-control form-control-sm">
                    <span class="text-danger" *ngIf="multipleOrders > 25">You cannot create more than 25 orders at once.</span>
                </div>
            </div>

        </div>

        <!-- Recurring orders is when dispatcher needs to schedule this order to reoccur daily, week, monthly, etc until set cancelled date -->
        <div class="form-group row">
            <div class="col-md-3">
                <label for="multipleorder" class="col-form-label ">Recurring Orders</label>
                <div>
                    <div class="form-check-inline">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="recurring">
                        <label class="form-check-label">Yes</label>
                    </div>
                </div>
            </div>
            <div class="col-md-9" *ngIf="recurring">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <label for="product" class="col-form-label ">Start</label>
                        <input type="text" id="startdate"
                               class="form-control form-control-sm"
                               placeholder="Start Date" #dp="bsDatepicker"
                               bsDatepicker [(ngModel)]="recurringStartDate">
                    </div>
                    <div class="col-md-6">
                        <label for="priority" class="col-form-label ">End </label>
                        <input type="text" id="enddate"
                               class="form-control form-control-sm"
                               placeholder="End Date" #dp="bsDatepicker"
                               bsDatepicker [(ngModel)]="recurringEndDate">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col">
                <label for="notes" class="">Dispatch Notes</label>
                <textarea id="notes" class="form-control form-control-sm"
                          rows="4" [(ngModel)]="notes"></textarea>
            </div>
        </div>
        <br>
        <div class="row form-group">
            <div class="col">
                <button type="submit" class="btn btn-primary" [disabled]="pickups.length == 0 || leaseIsRestricted || multipleOrders > 25"
                        (click)="onSubmit()">
                    <i class="fa fa-check"></i>&nbsp; Create Order
                </button>&nbsp;
                <button type="button" class="btn btn-danger" (click)="reset();">
                    <i class="fa fa-close"></i>&nbsp; Cancel Order
                </button>&nbsp;
            </div>
        </div>
    </div>
</div>
