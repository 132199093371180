import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {ModalAlertComponent} from '../components/modal-alert/modal-alert.component';

@Injectable()
export class ApiService {
    private endPoint: string;
    public bsModalRef;
    public modalService: BsModalService;

    constructor(endpoint: string) {
        this.endPoint = endpoint;
    }

    public getEndpoint() {
        return environment.apiUrl + this.endPoint;
    }

    public errorAlert(errors = []) {
        this.bsModalRef = this.modalService.show(ModalAlertComponent, {
            initialState: {
                errors: errors
            }
        });
        this.bsModalRef.content.template = '';

        this.modalService.onHide.subscribe(result => {
            console.log(errors);
        });
    }
}
