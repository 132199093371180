<div class="modal-header">

    <h4 class="modal-title text-center">{{title}}</h4>

    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>

</div>


<div class="modal-body">

    <div class="card-header">Driver Log Payroll Report</div>
    <div class="card-body form-report-criteria">
        <form novalidate [formGroup]="payrollReportForm" (ngSubmit)="submit()">

            <div class="form-group row form-elements mb-2">
                <label class="col-md-4 col-form-label" for="driverSelect" >Select Driver(s)</label>
                <div class="col-md-8">
                    <ng-select
                        id="driverSelect"
                        formControlName="drivers"
                        [multiple]="true"
                        [options]="drivers"
                        (selected)="onSelected($event)"
                        highlightColor="#9575cd"
                        highlightTextColor="#fff"
                        [ngClass]="{'is-invalid': ( (payrollReportForm.get('drivers').touched || payrollReportForm.get('drivers').dirty) && !payrollReportForm.get('drivers').valid ) }" >
                    </ng-select>
                    <span class="invalid-feedback">
                        <span *ngIf="payrollReportForm.get('drivers').errors?.required">
                            Select Driver(s) or 'All Active Drivers'.
                        </span>
                    </span>
                </div>
            </div>

            <div class="form-group">
                <div class="row d-flex justify-content-center">
                    <div class="col-sm-3">

                        <button class="btn btn-primary mr-3"
                            type="submit"
                            style="width: 250px"
                            [title]="payrollReportForm.valid ? 'All Validation Passed.' : 'Disabled until the form data is valid'"
                            [disabled]="!payrollReportForm.valid">
                            Create Report
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>
