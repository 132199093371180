import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {finalize, map, catchError} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class UploadService extends ApiService {

    // only true if there is an error
    // this flag is going to set to false at the beginning of each file upload http request
    fileUploadError = false;

    constructor(private httpClient: HttpClient) {
        super('/api/upload');
    }
    public upload(parameters, data) {

        // this number is going to be undefined if an upload is not in progress
        // and it is going to contain the percentage of the upload already completed
        // when the upload is in progress
        let uploadProgress: number;

        const uploadURL = this.getEndpoint() + parameters;

        // needs to be set to true if an error occurs
        this.fileUploadError = false;

        return this.httpClient.post<any>(uploadURL, data, {
            reportProgress: true,
            observe: 'events'
        }).pipe(
            catchError(error => {
                this.fileUploadError = true;
                return of(error)
            }),
            finalize(() => {
                uploadProgress = null;
            }),
            map((event)  => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        uploadProgress = Math.round(100 * event.loaded / event.total);
                        return uploadProgress;
                    case HttpEventType.Response:
                        return event.body;
                }
            })
        );
    }
}
