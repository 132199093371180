import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Product} from '../../models/product.model';

@Injectable()
export class ProductsService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/product');
    }
    getProducts(): Observable<Product[]>{
        return this.http.get<Product[]>(this.getEndpoint());
    }
}
