import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Tank} from '../../models/tank.model';

@Injectable()
export class TanksService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/tank');
    }
    getTanks(): Observable<Tank[]>{
        return this.http.get<Tank[]>(this.getEndpoint());
    }
}
