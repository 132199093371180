import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {map} from 'rxjs/operators';
import {UserRole} from '../../models/UserRole';

@Injectable()
export class RolesService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/permissions/roles');
    }

    getRoles(): Observable<UserRole[]> {
        return this.http.get<UserRole[]>(this.getEndpoint())
            .pipe(
                map((rolesResponse: UserRole[]) => {
                    return rolesResponse;
                })
            );
    }
}
