<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>

<div class="modal-body modal-lg">
    <div class="alert alert-danger" role="alert">
        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="defaultChecked2" [(ngModel)]="confirm">
            <label class="custom-control-label" for="defaultChecked2">
                <p>Are you sure you want to delete this pickup? {{pickup.ticket_number}}</p>
                <p>This pickup will no longer show up on: Reports, Tablets or be searchable on Dispatch Board</p>
                <b>THIS ACTION IS NOT REVERSIBLE</b>
            </label>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="onCancel()">{{closeBtnName}}</button>
    <button type="button" class="btn btn-success" (click)="onConfirm()" [disabled]="!confirm">DELETE</button>
</div>

