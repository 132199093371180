import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {map, retry} from 'rxjs/operators';
import {UserPermission} from '../../models/UserPermission';
import {UserRole} from '../../models/UserRole';

@Injectable()
export class PermissionsService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/permissions');
    }

    getPermissions(): Observable<UserPermission[]> {
        return this.http.get<UserPermission[]>(this.getEndpoint())
            .pipe(
                map((permissionsResponse: UserPermission[]) => {
                    return permissionsResponse;
                })
            );
    }

    getPermissionsByRole(roleId): Observable<UserPermission[]> {
        return this.http.get<UserPermission[]>(this.getEndpoint() + '/role/' + roleId)
            .pipe(
                map((permissionsResponse: UserPermission[]) => {
                    return permissionsResponse;
                })
            );
    }

    async revokePermission(role: UserRole, permission: UserPermission) {
        const options = {
            permission: permission.id
        };
        return await this.http.post(this.getEndpoint() + '/role/' + role.id + '/revoke', options)
            .pipe(
                retry(3)
            ).toPromise();
    }

    async deletePermission(permission: UserPermission) {
        return await this.http.delete(this.getEndpoint() + '/' + permission.id)
            .pipe(
                retry(3)
            ).toPromise();
    }

    async addPermission(role: UserRole, permissionName: string) {
        const options = {
            permissionName: permissionName
        };
        return await this.http.post(this.getEndpoint() + '/role/' + role.id + '/add', options)
            .pipe(
                retry(3)
            ).toPromise();
    }
}
