import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule, ErrorHandler, Injectable} from '@angular/core';
import {LocationStrategy, HashLocationStrategy, CommonModule} from '@angular/common';

import {AppComponent} from './app.component';
import {CookieService} from 'ngx-cookie-service';

// tslint:disable-next-line: import-spacing

// Import containers
import {
    FullLayoutComponent,
    SimpleLayoutComponent
} from './containers';

const APP_CONTAINERS = [
    FullLayoutComponent,
    SimpleLayoutComponent
]

/// Import components
import {
    AppAsideComponent,
    AppBreadcrumbsComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    AppSidebarFooterComponent,
    AppSidebarFormComponent,
    AppSidebarHeaderComponent,
    AppSidebarMinimizerComponent,
    APP_SIDEBAR_NAV,
} from './components';

const APP_COMPONENTS = [
    AppAsideComponent,
    AppBreadcrumbsComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    AppSidebarFooterComponent,
    AppSidebarFormComponent,
    AppSidebarHeaderComponent,
    AppSidebarMinimizerComponent,
    APP_SIDEBAR_NAV
]

// Import directives
import {
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    ReplaceDirective,
    SIDEBAR_TOGGLE_DIRECTIVES
} from './directives';

const APP_DIRECTIVES = [
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    ReplaceDirective,
    SIDEBAR_TOGGLE_DIRECTIVES
];

// Import routing module
import {AppRoutingModule} from './app.routing';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {OrdersService} from './services/api/orders.service';
import {OperatorsService} from './services/api/operators.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RegionsService} from './services/api/regions.service';
import {LeasesService} from './services/api/leases.service';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {LeaseSearchService} from './services/api/lease-search.service';
import {AuthGuard} from './services/auth/auth.guard';
import {AuthService} from './services/auth/auth.service';
import {AuthInterceptor} from './services/auth/auth.interceptor';
import {ProductsService} from './services/api/products.service';
import {ServicesService} from './services/api/services.service';
import {PrioritiesService} from './services/api/priorities.service';
import {OriginsService} from './services/api/origins.service';
import {LoadTypesService} from './services/api/load-type.service';
import {DriversService} from './services/api/drivers.service';
import {EmployeesService} from './services/api/employees.service';
import {TanksService} from './services/api/tanks.service';
import {MetersService} from './services/api/meters.service';
import {TerminalsService} from './services/api/terminals.service';
import {TrucksService} from './services/api/trucks.service';
import {TrailersService} from './services/api/trailers.service';
import {OffloadsService} from './services/api/offloads.service';
import {DispatchOrderOptionsProvider} from './providers/dispatch-order-options.provider';
import {NewOrderOptionsProvider} from './providers/new-order-options.provider';
import {OrderLeaseHistoryOptionsProvider} from './providers/order-lease-history-options.provider';
import {OrderLeaseDetailOptionsProvider} from './providers/order-lease-detail-options.provider';
import {ModalContentComponent} from './components/modal-content/modal-content.component';
import {SelectModule} from 'ng-select';
import {StatusesService} from './services/api/statuses.service';
import {UsersService} from './services/api/users.service';
import {ModalContentDispatchComponent} from './components/modal-content-dispatch/modal-content-dispatch.component';
import {ModalCreateOrderComponent} from './components/modal-create-order/modal-create-order.component';
import {DispatchPickupDataOptionsProvider} from './providers/dispatch-pickup-data-options.provider';
import {DispatchUnloadDataOptionsProvider} from './providers/dispatch-unload-data-options.provider';
import {AngularTableGridModule} from 'angular-table-grid'
import {EditOrderModalComponent} from './components/edit-order-modal/edit-order-modal.component';
import {EditPickuprModalComponent} from './components/edit-pickup-modal/edit-pickup-modal.component';
import {EditUnloadModalComponent} from './components/edit-unload-modal/edit-unload-modal.component';
import {RolesService} from './services/api/roles.service';
import {PermissionsService} from './services/api/UserPermissionsService.service';
import {UserPermissionsAclService} from './services/api/UserPermissionsAcl.service';
import {RejectReasonsService} from './services/api/rejectreasons.service';
import {TabComponent} from './components/tab';
import {TabsComponent} from './components/tabs';
import {EomService} from './services/api/eom.sevice';
import {EditEomModalComponent} from './components/edit-eom-modal/edit-eom-modal.component';
import {ReportsService} from './services/api/reports.service';
import {ReportFiltersModalComponent} from './components/report-filters-modal/report-filters-modal.component';
import {RestrictionsService} from './services/api/restriction.service';
import {LeaseRestrictionsModule} from './lease-restrictions/lease-restrictions.module';
import {LeaseRestrictionsModalComponent} from './components/lease-restrictions-modal/lease-restrictions-modal.component';
import {PageFiltersService} from './services/api/page-filters.service';
import {RejectPickupModalComponent} from './components/reject-pickup-modal/reject-pickup-modal.component';
import {DeletePickupModalComponent} from './components/delete-pickup-modal/delete-pickup-modal.component';
import {ClientErrorHandler} from './clienterror.handler';
import {AuditLogService} from './services/api/audit-log.service';
import {PageFiltersModalComponent} from './components/page-filters-modal/page-filters-modal.component';
import {AppVersionService} from './services/appVersion.service';
import {ModalAppVersionComponent} from './components/modal-app-version/modal-app-version.component';
import {Ng2LoadingSpinnerModule} from 'ng2-loading-spinner';
import {TankStrappingsService} from './services/api/tank-strappings.service';
import {PageFiltersApiService} from './services/api/page-filters-api.service';
import {BackgroundTaskService} from './services/api/background-task.service';
import {ScheduledTasksService} from './services/api/scheduled-tasks.service';
import {ModalAlertComponent} from './components/modal-alert/modal-alert.component';
import {ThirdPartyHaulersModule} from './views/third-party-haulers/third-party-haulers.module';
import {ThirdPartyHaulersService} from './services/api/third-party-haulers.service';
import {SellersService} from './services/api/sellers.service';
import {UploadService} from './services/api/upload.service';
import {SamsaraService} from './services/api/samsara.service';
import {PayrollService} from './services/api/payroll.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        SelectModule,
        AlertModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        AngularTableGridModule,
        LeaseRestrictionsModule,
        Ng2LoadingSpinnerModule.forRoot({}),
        ThirdPartyHaulersModule
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        ...APP_COMPONENTS,
        ...APP_DIRECTIVES,
        ModalContentComponent,
        ModalContentDispatchComponent,
        ModalCreateOrderComponent,
        EditOrderModalComponent,
        EditPickuprModalComponent,
        EditUnloadModalComponent,
        EditEomModalComponent,
        ReportFiltersModalComponent,
        LeaseRestrictionsModalComponent,
        TabComponent,
        TabsComponent,
        RejectPickupModalComponent,
        DeletePickupModalComponent,
        PageFiltersModalComponent,
        ModalAppVersionComponent,
        ModalAlertComponent
    ],
    providers: [
        OrdersService,
        EomService,
        OperatorsService,
        RegionsService,
        LeasesService,
        LeaseSearchService,
        ProductsService,
        ServicesService,
        DriversService,
        UploadService,
        SamsaraService,
        EmployeesService,
        TanksService,
        MetersService,
        TerminalsService,
        TrucksService,
        TrailersService,
        OffloadsService,
        CookieService,
        PrioritiesService,
        OriginsService,
        LoadTypesService,
        StatusesService,
        UsersService,
        AuthGuard,
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: ErrorHandler, useClass: ClientErrorHandler },
        DispatchOrderOptionsProvider,
        DispatchPickupDataOptionsProvider,
        DispatchUnloadDataOptionsProvider,
        NewOrderOptionsProvider,
        OrderLeaseHistoryOptionsProvider,
        OrderLeaseDetailOptionsProvider,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        RolesService,
        PermissionsService,
        UserPermissionsAclService,
        RejectReasonsService,
        ReportsService,
        RestrictionsService,
        PageFiltersService,
        PageFiltersApiService,
        AuditLogService,
        AppVersionService,
        TankStrappingsService,
        BackgroundTaskService,
        ScheduledTasksService,
        ThirdPartyHaulersService,
        SellersService,
        PayrollService
    ],
    entryComponents: [
        ModalContentComponent,
        ModalContentDispatchComponent,
        ModalCreateOrderComponent,
        EditOrderModalComponent,
        EditPickuprModalComponent,
        EditUnloadModalComponent,
        EditEomModalComponent,
        ReportFiltersModalComponent,
        LeaseRestrictionsModalComponent,
        RejectPickupModalComponent,
        DeletePickupModalComponent,
        PageFiltersModalComponent,
        ModalAppVersionComponent,
        ModalAlertComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
