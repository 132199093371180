import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Operator} from 'app/models/operator.model';
import {ApiService} from '../api.service';

@Injectable()
export class OperatorsService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/operator');
    }

    public getOperators(search = {}, start = null, end = null, sort = null, order = null): Observable<any> {
        const params: any = {};
        if (start !== null && end !== null) {
            params.start = start;
            params.end = end;
        }

        if (sort !== null) {
            params.sort = sort;
        }

        if (order !== null) {
            params.order = order;
        }
        return this.http.post<any>(this.getEndpoint() + '/list', {_pageFilters: search}, {params: params})
    }

    public getOperatorByOperator(operatorId: number): Observable<Operator[]> {
        return this.http.post<Operator[]>(this.getEndpoint(), {
            data: {
                search_for: operatorId
            }
        });
    }

    public operatorSearch(term: any): Observable<Operator[]> {
        return this.http.post<Operator[]>(this.getEndpoint() + '/search', {
            data: {
                search_for: term
            }
        });
    }
}

