import {Component, Input, OnInit} from '@angular/core';
import {PayCodeResponse, PayrollService} from '../../services/api/payroll.service';
import {PayCode} from '../../models/pay-code.model';
import {ANIMATION_TYPES, INg2LoadingSpinnerConfig} from 'ng2-loading-spinner';
import {ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent} from '@ag-grid-community/core';

@Component({
    selector: 'app-pay-earning-type',
    templateUrl: './pay-earning-type.component.html',
    styleUrls: ['./pay-earning-type.component.scss']
})
export class PayEarningTypeComponent implements OnInit {
    show = false;

    loadingConfig: INg2LoadingSpinnerConfig = {
        animationType  : ANIMATION_TYPES.dualCircle,
        backdropColor  : 'rgba(0, 0, 0, 0.3)',
        spinnerColor   : '#fff',
        spinnerPosition: 'center',
        backdropBorderRadius: '15px',
        spinnerSize: 'md',
        spinnerFontSize: '2rem'
    };


    @Input()
    payCode: PayCode;

    @Input()
    checkDate: string;

    errorMessage;

    api: GridApi;
    columnApi: ColumnApi;

    // public loadingCellRenderer: any = CustomLoadingCellRenderer;
    // public loadingCellRendererParams: any = {
    //     loadingMessage: 'One moment please...',
    // };


    payCodeGridOptions: GridOptions;

    rowData = [];

    payCodeCount: number = null;

    //
    // public payCodeItems: PayCodeObj[] = [
    //     {
    //         'id': 0,
    //         'employeeNumber': '',
    //         'driver': '',
    //         'loadedAt': '',
    //         'periodCovered': '',
    //         'homeLocation': '',
    //         'defaultHaulRate': 0,
    //         'haulRate': 0,
    //         'ticketNumber': '',
    //         'status': '',
    //         'totalAmount': 0
    //     }
    // ];
    public payCodeItems: PayCode[] = [
        {
            'id': 0,
            'code': '',
            'description': '',
            'rate': 0
        }
    ];
    constructor(
        private payrollService: PayrollService
    ) { }

    ngOnInit(): void {
        this.payCodeGridOptions = this.configurePayCodeGrid();
    }


    showLoading() {
        this.show = true;
        // setTimeout(() => {
        //     this.show = false;
        // }, 1500);
    }


    /**
     * Configure the ag-grid that contains the pay code data
     */
    configurePayCodeGrid(): GridOptions {
        return {

            // PROPERTIES

            columnDefs: <ColDef[]> [
                {
                    headerName: 'Driver',
                    field: 'driver',
                    cellRenderer: 'agGroupCellRenderer',
                },
                { headerName: 'Loaded At', field: 'loadedAt' },
                { headerName: 'Home Location', field: 'homeLocation' },
                { headerName: 'Period Covered', field: 'periodCovered' },
                { headerName: 'Ticket Number', field: 'ticketNumber' },
                { headerName: 'Status', field: 'status' },
                { headerName: 'Default Haul Rate', field: 'defaultHaulRate' },
                { headerName: 'Haul Rate', field: 'haulRate' },
                { headerName: 'Load Pay', field: 'totalAmount' }
            ],
            defaultColDef: <ColDef> {
                flex: 1,
            },
            pagination: true,
            rowSelection: 'single',


            // EVENTS
            // Add event handlers
            onGridReady: event => this.onGridReady(event)
        };
    }

    /**
     * Fetch All Load Pay Codes refresh the grid
     */
    getRowData(code) {
        this.payrollService.getPayCodeItems(code, this.checkDate)
            .subscribe(
                (payCodes: PayCodeResponse) => {
                    this.payCodeItems = payCodes.data;
                    // this.checkDate = payCodes.checkDate;
                    this.payCodeCount = payCodes.rowCount;
                },
                (error) => console.log('ERROR: ' + error.message),
                () => this.show = false
            );

        // refresh the grid
        const params = {
            force: true,
            suppressFlash: true,
        };
        this.payCodeGridOptions.api.refreshCells(params);
    }


    /**
     *
     * @param params
     */
    onGridReady(params: GridReadyEvent) {
        this.api = params.api;
        this.columnApi = params.columnApi;
    }



    getPayCodes(code: string) {
        if (this.checkDate != null) {
            if (this.api !== undefined) {
                this.showLoading();
                this.getRowData(code);
            }
        }

    }
}
