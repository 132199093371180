import { Injectable } from "@angular/core";
@Injectable()
export class OrderLeaseDetailOptionsProvider {
    getColumns() {
        const columnDefs = [
            {
                headerName: 'Operator',
                field: 'lease_operator',
                width: 100
            },
            {
                headerName: 'Number',
                field: 'lease_number',
                width: 100
            },
            {
                headerName: 'Contact',
                field: 'lease_contact',
                width: 100
            },
            {
                headerName: 'Offload',
                field: 'primary_offload',
                width: 100
            }
        ];
        return columnDefs;
    }
}
