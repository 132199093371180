// /app/atatus.handler.ts
import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable()
export class ClientErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        console.log(error);
        try {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", environment.apiUrl + "/apiV2/log");
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.send(
                JSON.stringify(this.getLogEntry(error.originalError || error))
            );
        } catch (e) {
            console.log(e);
        }
    }

    private getLogEntry(error: Error): any {
        return {
            timestamp: new Date().toISOString(),
            user: JSON.parse(localStorage?.getItem("currentUser"))?.username,
            severity: "error",
            message: error?.message,
            source: "dispatch-web",
            url: window?.location?.href,
            trace: error?.stack,
        };
    }
}
