import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LeaseSearch} from '../../models/lease-search.model';
import {ApiService} from '../api.service';

@Injectable()
export class LeaseSearchService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/lease/search');
    }

    leasePost(search: string): Observable<LeaseSearch> {
        return this.http.post<LeaseSearch>(this.getEndpoint(),  {
            data: {
                lease_search: search
            }
        });
    }
}
