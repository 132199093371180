import {TableGridColumn} from 'angular-table-grid';
import { Injectable } from "@angular/core";

@Injectable()
export class DispatchOrderOptionsProvider {
    getTableGridColumns() {
        return <TableGridColumn[]>[
            {
                headerTitle: 'Status',
                fieldName: 'status_name'
            },
            {
                headerTitle: 'Order #',
                fieldName: 'id',
                sortable: true
            },
            {
                headerTitle: 'Hauler',
                fieldName: 'hauler',
            },
            {
                headerTitle: 'Created By',
                fieldName: 'created_by.formatted_name'
            },
            {
                headerTitle: 'Created',
                fieldName: 'created_at_date',
                sortable: true
            },
            {
                headerTitle: 'Dispatched',
                fieldName: 'dispatch_date',
                sortable: false
            },
            {
                headerTitle: 'Dispatcher',
                fieldName: 'dispatched_by.formatted_name'
            },
            {
                headerTitle: 'Driver',
                fieldName: 'driver_name',
                sortable: true
            },
            {
                headerTitle: 'Received',
                fieldName: 'received_at',
                sortable: true
            },
            {
                headerTitle: 'Priority',
                fieldName: 'priority_name'
            },
            {
                headerTitle: 'Pickup Start',
                fieldName: 'pickups.0.pickup_data.load_start_formatted',
                fieldFn: (row: any) => {
                    for (let i = 0; i < row.pickups.length; i++) {
                        if (!row.pickups[i].corrected_by) {
                            if (row.pickups[i].pickup_data) {
                                return row.pickups[i].pickup_data.load_start_formatted;
                            }
                        }
                    }
                    return '';
                },
                sortable: true
            },
            {
                headerTitle: 'Unload End',
                fieldName: 'unloads.0.unload_data.load_end_formatted',
                fieldFn: (row: any) => {
                    for (let i = 0; i < row.unloads.length; i++) {
                        if (!row.unloads[i].corrected_by) {
                            if (row.unloads[i].unload_data) {
                                return row.unloads[i].unload_data.load_end_formatted;
                            }
                        }
                    }
                    return '';
                },
                sortable: true
            },
            {
                headerTitle: 'Pickup',
                fieldName: 'pickups.0.lease_name',
                fieldFn: (row: any) => {
                    for (let i = 0; i < row.pickups.length; i++) {
                        if (!row.pickups[i].corrected_by) {
                            return row.pickups[i].lease_name;
                        }
                    }
                    return '';
                }
            },
            {
                headerTitle: 'Equipment',
                fieldName: 'pickups.0.equipment_name',
                fieldFn: (row: any) => {
                    for (let i = 0; i < row.pickups.length; i++) {
                        if (!row.pickups[i].corrected_by) {
                            return row.pickups[i].equipment_name;
                        }
                    }
                    return '';
                }
            },
            {
                headerTitle: 'Unload',
                fieldName: 'unloads.0.offload_name',
                fieldFn: (row: any) => {
                    for (let i = 0; i < row.unloads.length; i++) {
                        if (!row.unloads[i].corrected_by) {
                            return row.unloads[i].offload_name;
                        }
                    }
                    return '';
                },
                sortable: true
            },
            {
                headerTitle: 'Product',
                fieldName: 'product_code'
            },
            {
                headerTitle: 'Type',
                fieldName: 'load_type_name'
            },
            {
                headerTitle: 'BBLs',
                fieldName: 'gross_barrels'
            },
            {
                headerTitle: 'Notes',
                fieldName: 'notes'
            },
        ];
    }
}
