export const navigation = [
    {
        title: true,
        name: 'Menu'
    },
    {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        guarded: ['role:super-admin']
    },
    {
        name: 'Orders',
        url: '/order',
        icon: 'icon-notebook',
        guarded: [
            'role:super-admin',
            'role:admin',
            'role:order-creator',
            'role:third-party'
        ]
    },
    {
        name: 'Driver Logs',
        url: '/driver-log',
        icon: 'fa fa-tasks fa-la',
        guarded: [
            'role:super-admin',
            'role:back-office'
        ]
    },
    {
        name: 'Dispatch',
        url: '/dispatch',
        icon: 'fa fa-truck fa-lg',
        guarded: [
            'role:super-admin',
            'role:admin',
            'role:dispatcher',
            'role:manager',
            'role:third-party'
        ]
    },
    {
        name: 'EOM',
        url: '/eom',
        icon: 'fa fa-calendar fa-lg',
        guarded: [
            'role:super-admin',
            'role:admin',
            'role:back-office',
        ]
    },
    {
        name: 'Tank Strappings',
        url: '/tank-strappings',
        icon: 'fa fa-ruler-combined fa-lg',
        guarded: [
            'role:super-admin',
            'role:admin',
            'role:tank-strapper'
        ]
    },
    {
        name: 'Reports',
        url: '/reports',
        icon: 'fa fa-file-pdf fa-lg',
        guarded: [
            'role:super-admin',
            'role:admin',
            'role:reports'
        ]
    },
    {
        title: true,
        name: 'Administration',
        divider: true,
        guarded: [
            'role:super-admin',
            'role:admin',
            'role:owner',
            'role:lease-admin',
            'permission:view audit log',
        ]
    },
    {
        name: 'Sellers',
        url: '/sellers',
        icon: 'fa fa-money fa-lg',
        guarded: [
            'role:super-admin',
            'role:owner'
        ]
    },
    {
        name: 'Lease',
        url: '/leases',
        icon: 'fa fa-money fa-lg',
        guarded: [
            'role:super-admin',
            'role:owner'
        ]
    },
    {
        name: 'Operators',
        url: '/operators',
        icon: 'fa fa-money fa-lg',
        guarded: [
            'role:super-admin',
            'role:owner'
        ]
    },
    {
        name: 'Permissions',
        url: '/permissions',
        icon: 'fa fa-lock fa-lg',
        guarded: [
            'role:super-admin',
            'role:owner'
        ]
    },
    {
        name: 'Restrictions',
        url: '/restrictions',
        icon: 'fa fa-exclamation-triangle fa-lg',
        guarded: [
            'role:super-admin',
            'role:admin',
            'role:lease-admin'
        ]
    },
    {
        name: 'Audit Logs',
        url: '/audit-log',
        icon: 'fa fa-clipboard fa-lg',
        guarded: [
            'role:super-admin',
            'role:admin',
            'permission:view audit log'
        ]
    },
    {
        name: 'Users',
        url: '/users',
        icon: 'fa fa-users fa-lg',
        guarded: [
            'role:super-admin',
            'role:admin',
            'role:owner'
        ]
    },
    {
        name: 'Payroll',
        url: '/payroll',
        icon: 'fa fa-road fa-lg',
        guarded: [
            'role:super-admin',
            'role:admin'
        ]
    },
    {
        name: 'Scheduled Task',
        url: '/scheduled-tasks',
        icon: 'fa fa-calendar-alt fa-lg',
        guarded: ['role:super-admin']
    },
    {
        name: 'Third Party Haulers',
        url: '/third-party-haulers',
        icon: 'fa fa-truck fa-lg',
        guarded: [
            'role:super-admin',
            'role:admin',
            'role:owner',
            'role:third-party-manager'
        ]
    },
];

