import {Component, ElementRef, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {TableGridComponent, TableGridFilters, TableGridOptions, TableGridRowDataRequest} from 'angular-table-grid';
import {map} from 'rxjs/operators';
import {RolesService} from '../../services/api/roles.service';
import {PageFiltersService} from '../../services/api/page-filters.service';
import {ThirdPartyHaulersService} from '../../services/api/third-party-haulers.service';
import {ThirdPartyHaulerDetailsComponent} from './components/third-party-hauler-details.component';

@Component({
    templateUrl: 'third-party-haulers.component.html',
    styles: [`
    `],
})
export class ThirdPartyHaulersComponent {
    pageFilterServiceValues = {};
    pageFilterServiceSearch = {};
    public gridApi;
    public gridColumnApi;
    bsModalRef: BsModalRef;
    gridOptions: TableGridOptions;
    _selectedRow: any;
    gridLoading = false;
    modalTitle = 'New Task';
    _blank = {
        id: '',
        name: ''
    };
    _current: any = {};
    @ViewChild('addModal') public addModal: TemplateRef<any>;

    constructor(
        private thirdPartyHaulersService: ThirdPartyHaulersService,
        private modalService: BsModalService,
        private rolesService: RolesService,
        private pageFiltersService: PageFiltersService
    ) {
        this.pageFilterServiceValues = this.pageFiltersService.getFiltersState('Third Party Haulers').pageFilterValues;
        this.gridOptions = <TableGridOptions>{
            columns: [
                {
                    headerTitle: 'Name',
                    fieldName: 'name',
                    sortable: true
                },
                {
                    headerTitle: 'New',
                    fieldName: 'new',
                },
                {
                    headerTitle: 'Hauled',
                    fieldName: 'hauled',
                },
                {
                    headerTitle: 'Leases',
                    fieldName: 'leases',
                    fieldFn: (row) => {
                        return row.leases.length
                    }
                },
                {
                    headerTitle: 'Offloads',
                    fieldName: 'offloads',
                    fieldFn: (row) => {
                        return row.offloads.length
                    }
                },
                {
                    headerTitle: 'Users',
                    fieldName: 'users'
                }
            ],
            getRowData: (rowDataRequest: TableGridRowDataRequest) => {
                this.gridLoading = true;
                const page = rowDataRequest.pagination.page;
                const perPage = rowDataRequest.pagination.perPage;
                const start = (page - 1) * perPage;
                const end = page * perPage;
                const sortStr = rowDataRequest.params.get('_sort');
                const orderStr = rowDataRequest.params.get('_order');
                return this.search(start, end, sortStr, orderStr).pipe(
                    map((response: any) => {
                        this.gridLoading = false;
                        return {
                            rows: response.data.data,
                            totalRows: response.data.rowCount
                        }
                    })
                );
            },
            getDetails: (node) => {
                return node;
            },
            enableDetails: true,
            detailComponent: ThirdPartyHaulerDetailsComponent,
            perPage: 5,
            page: 1,
            multiSelectOn: false
        };
    }

    search(start, end, sortStr, orderStr) {
        return this.thirdPartyHaulersService.getHaulers(this.pageFilterServiceValues, start, end, sortStr, orderStr);
    }

    refresh() {
        this.gridApi.refresh();
    }

    onGridReady(gridApi: TableGridComponent) {
        this.gridApi = gridApi;
        this.gridApi.rowDataRequest.filters[0] = <TableGridFilters>{
            q: ''
        };

        this.gridApi.select.subscribe((row) => {
            this._selectedRow = row;
        });

        this.gridApi.deselect.subscribe((row) => {
            this._selectedRow = null;
        });
    }

    canEdit() {
        return !!(this._selectedRow);
    }

    add() {
        this.modalTitle = 'New Hauler';
        this._current = JSON.parse(JSON.stringify(this._blank));
        this.bsModalRef = this.modalService.show(this.addModal);
    }

    edit() {
        this.modalTitle = 'Edit Hauler';
        const selected = this._selectedRow;
        this._current = JSON.parse(JSON.stringify({
            id: selected.id,
            name: selected.name
        }));
        this.bsModalRef = this.modalService.show(this.addModal);
    }

    save() {
        this.gridLoading = true;
        this.thirdPartyHaulersService.save(this._current).subscribe(() => {
            this.refresh();
            this.bsModalRef.hide();
        });
    }

    showFilters() {
        this.pageFiltersService.show('Third Party Haulers', (response, filters) => {
            if (response) {
                this.pageFilterServiceValues = filters.pageFilterValues;
                this.gridApi.refresh();
            }
        });
    }
}
