<div class="full-width-panel row">
    <div class="col-md-12">
        <div class="card" >
            <div class="card-body">
                <h5 class="card-title">Pickups</h5>
                <lib-table-grid [gridOptions]="pickupGridOptions" class="table-responsive"></lib-table-grid>
            </div>
        </div>
    </div>
</div>
<div class="full-width-panel row" >
    <div class="col-md-12">
        <div class="card" >
            <div class="card-body">
                <h5 class="card-title">Unloads</h5>
                <button class="btn btn-outline-primary" (click)="unloadGenerateTicket()" *ngIf="unloadCanGenerateTicket()"><i class="icon-exclamation"></i>Generate Ticket Number</button>
                <lib-table-grid [gridOptions]="unloadGridOptions" (gridReady)="unloadGridReady($event)" class="table-responsive"></lib-table-grid>
            </div>
        </div>
    </div>
</div>
