import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {OrdersService} from 'app/services/api/orders.service';
import {DispatchOrderOptionsProvider} from '../../providers/dispatch-order-options.provider';
import {DriversService} from '../../services/api/drivers.service';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerConfig, BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsModalRef, BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {DispatchPickupDataOptionsProvider} from '../../providers/dispatch-pickup-data-options.provider';
import {MasterDetailComponent} from '../master-detail/master-detail.component';
import {TableGridComponent, TableGridFilters, TableGridRowDataRequest, TableGridOptions} from 'angular-table-grid';
import {map} from 'rxjs/operators';
import {OrderResponse} from '../../services/api/orders.service';
import {EditOrderModalComponent} from '../edit-order-modal/edit-order-modal.component';
import {ngxCsv} from 'ngx-csv';
import {OrderObj} from '../../models/order.model';
import * as moment from 'moment';
import {PageFiltersService} from '../../services/api/page-filters.service';
import {PageFiltersApiService} from '../../services/api/page-filters-api.service';

@Component({
    selector: 'app-dispatch-board',
    templateUrl: './dispatch-board.component.html',
    styleUrls: ['./dispatch-board.component.scss'],
})
export class DispatchBoardComponent {
    public isHidden;
    public gridApi;
    public gridColumnApi;
    public orderCount;
    public editOrdersClicked;
    bsModalRef: BsModalRef;
    gridOptions: TableGridOptions;
    searchString: string;
    showMasterDetails = [];
    advancedSearch = false;
    objectKeys = Object.keys;
    searchParams = {
        order: {
            label: 'Order #',
            value: '',
            type: 'text'
        },
        ticket: {
            label: 'Ticket #',
            value: '',
            type: 'text'
        },
        operator: {
            label: 'Operator',
            value: '',
            type: 'text'
        },
        lease: {
            label: 'Lease',
            value: '',
            type: 'text'
        },
        equipment: {
            label: 'Equipment',
            value: '',
            type: 'text'
        },
        offload: {
            label: 'Offload',
            value: '',
            type: 'text'
        },
        terminal: {
            label: 'Terminal',
            value: '',
            type: 'text'
        },
        driver: {
            label: 'Driver',
            value: '',
            type: 'text'
        },
        status: {
            label: 'Status',
            value: '',
            type: 'select',
            options: [
                {label: 'Dispatched', value: 'DISPATCHED'},
                {label: 'Hold', value: 'HOLD'},
                {label: 'Picked Up', value: 'PICKED UP'},
                {label: 'Hauled', value: 'HAULED'},
                {label: 'Cancelled', value: 'CANCELLED'},
                {label: 'Void', value: 'VOID'},
                {label: 'Rejected', value: 'REJECTED'},
            ]
        },
        'created-at': {
            label: 'Created At',
            value: '',
            type: 'date'
        },
        'created-by': {
            label: 'Created By',
            value: '',
            type: 'text'
        },
        'dispatched-at': {
            label: 'Dispatched At',
            value: '',
            type: 'date'
        },
        'dispatched-by': {
            label: 'Dispatched By',
            value: '',
            type: 'text'
        },
        priority: {
            label: 'Priority',
            value: '',
            type: 'text'
        },
        county: {
            label: 'County',
            value: '',
            type: 'text'
        },
        region: {
            label: 'Region',
            value: '',
            type: 'select',
            options: [
                {label: 'West Texas', value: '6'},
                {label: 'Panhandle', value: '7'},
                {label: 'Eastex Texas', value: '8'},
                {label: 'Southeast Texas', value: '9'},
                {label: 'South Texas', value: '10'},
            ]
        },
        'load-start': {
            label: 'Load Start',
            value: '',
            type: 'date'
        },
        'corrected-at': {
            label: 'Corrected At',
            value: '',
            type: 'date'
        },
        product: {
            label: 'Product',
            value: '',
            type: 'select',
            options: [
                {label: 'Crude', value: 'Crude'},
                {label: 'Diesel', value: 'Diesel'}
            ]
        },
        'third-party': {
            label: 'Third Party Hauler',
            value: '',
            type: 'text'
        }
    };
    pageFilters = [];
    pageFilterValues = [];
    pageFilterSearch = {};
    filterSearchDebounce;
    orders: OrderObj[];
    csvData = [];
    gridLoading = false;
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    static parseBsDateRange(event) {
        if (event) {
            if (typeof event === 'string') {
                event = event.split(' - ');
            }
            const start = event[0];
            const end = event[1];
            return moment(start).format('M/D/Y') + ' - ' + moment(end).format('M/D/Y');
        }
        return '';
    }

    constructor(private dispatchOrderOptionsProvider: DispatchOrderOptionsProvider,
                private dispatchPickupDataOptionsProvider: DispatchPickupDataOptionsProvider,
                private ordersService: OrdersService,
                private driverService: DriversService,
                private modalService: BsModalService,
                private pageFilterService: PageFiltersApiService) {
        this.isHidden = false;
        this.editOrdersClicked = true;
        const ref = this;
        this.dpConfig.containerClass = 'theme-dark-blue';
        this.getPageFilters();
        this.gridOptions = <TableGridOptions>{
            columns: this.dispatchOrderOptionsProvider.getTableGridColumns(),
            getRowData: (rowDataRequest: TableGridRowDataRequest) => {
                this.gridLoading = true;
                const page = rowDataRequest.pagination.page;
                const perPage = rowDataRequest.pagination.perPage;
                const start = (page - 1) * perPage;
                const end = page * perPage;
                let sortStr = rowDataRequest.params.get('_sort');
                if (sortStr) {
                    sortStr = sortStr.replace('pickups.0.pickup_data.load_start_formatted', 'load_start');
                    sortStr = sortStr.replace('unloads.0.unload_data.load_end_formatted', 'load_end');
                    sortStr = sortStr.replace('pickups.0.', '');
                    sortStr = sortStr.replace('unloads.0.', '');
                }
                const orderStr = rowDataRequest.params.get('_order');
                return this.ordersService.getOrders(start, end, this.searchString, sortStr, orderStr).pipe(
                    map((orders: OrderResponse) => {
                        this.orderCount = orders.rowCount;
                        orders.data.map((row) => {
                            const filterPickups = row.pickups.filter((thisPickup) => {
                                return !thisPickup.corrected_at;
                            });
                            if (filterPickups.length > 1) {
                                row.load_type_name = 'Split';
                            }

                            row.pickups.map(pickup => {
                                pickup.alt_ticket_number = '';
                                if (pickup.ticket_number) {
                                    pickup.alt_ticket_number = parseInt(pickup.ticket_number, 32).toString();
                                }
                                return pickup;
                            });
                            row.created_at = moment.utc(row.created_at).local().format('M/D/YY h:mm a');

                            if (row.received_at) {
                                row.received_at = moment.utc(row.received_at).local().format('M/D/YY h:mm a');
                            }
                        });
                        this.orders = orders.data;
                        this.gridLoading = false;
                        return {
                            rows: orders.data,
                            totalRows: orders.rowCount
                        };
                    })
                );
            },
            getDetails: (node) => {
                return node;
            },
            getRowStyles: (node: OrderObj) => {
                const styles = {};
                // if (node.from_driver === 1) {
                //   styles['background-color'] = 'rgba(3, 171, 122, 0.5)';
                //   console.log(styles);
                // }
                if (node.status_name === 'HAULED') {
                    const totalPickups = node.pickups.length;
                    let hasCorrection = false;
                    for (let i = 0; i < totalPickups; i++) {
                        const pickup = node.pickups[i];
                        if (pickup.corrected_at) {
                            hasCorrection = true;
                        }
                    }
                    const totalUnloads = node.unloads.length;
                    for (let i = 0; i < totalUnloads; i++) {
                        const unload = node.unloads[i];
                        if (unload.corrected_at) {
                            hasCorrection = true;
                        }
                    }
                    if (hasCorrection) {
                        styles['background-color'] = 'rgba(150, 100, 0, 0.3)';
                    }
                }

                return styles;
            },
            getCellStyles: (value, columnDef) => {
                if (columnDef.headerTitle === 'Hauler' && value !== 'Eastex') {
                    const styles = {};
                    styles['background-color'] = 'rgba(255, 204, 0, 0.5)';
                    return styles;
                }
                if (columnDef.headerTitle === 'Status') {
                    const styles = {
                        'border-left': 'none',
                    };
                    if (value === 'VOID') {
                        styles['border-left'] = '6px solid rgba(12, 12, 12, 0.5)'
                    } else if (value === 'DISPATCHED') {
                        styles['border-left'] = '6px solid rgba(38, 154, 237, 0.5)';
                    } else if (value === 'HOLD') {
                        styles['border-left'] = '6px solid rgba(255, 204, 0, 0.5)';
                    } else if (value === 'CANCELLED') {
                        styles['border-left'] = '6px solid rgba(255, 133, 102, 0.5)';
                    } else if (value === 'REJECTED') {
                        styles['border-left'] = '6px solid rgba(255, 0, 0, 0.5)';
                    } else if (value === 'PICKED UP') {
                        styles['border-left'] = '6px solid rgba(3, 171, 122, 0.5)';
                    } else if (value === 'HAULED') {
                        styles['border-left'] = '6px solid rgba(63, 191, 63, 0.5)';
                    }
                    return styles;
                }
                return {};
            },
            enableDetails: true,
            detailComponent: MasterDetailComponent,
            perPage: 5,
            page: 1,
            multiSelectOn: true
        };
    };

    onGridReady(gridApi: TableGridComponent) {
        this.gridApi = gridApi;
        this.gridApi.rowDataRequest.filters[0] = <TableGridFilters> {
            q: ''
        };
    }

    @Input()
    set jobs(updated) {
        if (updated) {
            this.gridApi.refresh();
        }
    }

    bsDateRangeChange(event, key) {
        if (event) {
            this.searchParams[key].value = DispatchBoardComponent.parseBsDateRange(event);
            this.onSearch();
        }
    }

    getPageFilters() {
        // this.pageFilterService.getFilters('Dispatched Orders', {}).subscribe((response) => {
        //     this.pageFilters = response;
        //     for (let i = 0; i < this.pageFilters.length; i++) {
        //         this.pageFilterValues[this.pageFilters[i].name] = '';
        //         if (this.pageFilters[i].searchable) {
        //             this.pageFilterValues[this.pageFilters[i].name] = '';
        //         }
        //     }
        // });
    }

    private updatePageFilters() {
        // this.pageFilterService.getFilters('Dispatched Orders', this.pageFilterSearch).subscribe((response) => {
        //     for (let i = 0; i < this.pageFilters.length; i++) {
        //         if (this.pageFilters[i].searchable) {
        //             this.pageFilters[i].options = response[i].options;
        //         }
        //     }
        // });
    }

    filterDeselected(filter, $event) {
        this.filterSelected(filter, $event);
    }

    filterSelected(filter, $event) {
        const filterName = filter.name.split(' ').join('');
        const key = `selected${filterName}`;
        const selectedValue = (typeof filter.multiSelect !== 'undefined' && filter.multiSelect)
            ? this.pageFilterValues[filter.name].join(',') : this.pageFilterValues[filter.name];
        this.pageFilterSearch[key] = selectedValue;
        this.updatePageFilters();
    }

    public filterSearch(filter, $event) {
        if (filter.searchable) {
            this.pageFilterSearch[filter.name] = $event;
            if (this.filterSearchDebounce) {
                clearTimeout(this.filterSearchDebounce);
            }

            this.filterSearchDebounce = setTimeout(() => {
                this.filterSearchDebounce = null;
                this.pageFilterService.getFilters('Dispatched Orders', this.pageFilterSearch).subscribe((response) => {
                    for (let i = 0; i < this.pageFilters.length; i++) {
                        if (this.pageFilters[i].searchable) {
                            this.pageFilters[i].options = response[i].options;
                        }
                    }
                });
            }, 250);
        }
    }

    onSearch() {
        if (this.advancedSearch) {
            let query = '';
            for (const param in this.searchParams) {
                if (this.searchParams[param].value.length > 0) {
                    const dateFields = ['created-at', 'load-start', 'load-end', 'dispatched-at'];
                    if (dateFields.indexOf(param) !== -1) {
                        query += param + ':' + DispatchBoardComponent.parseBsDateRange(this.searchParams[param].value) + ' ';
                    } else {
                        query += param + ':' + this.searchParams[param].value + ' ';
                    }
                }
            }
            this.searchString = query;
        } else {
            const regex = /([a-z\-]+:[a-z0-9\-,\s\/]+)(?![a-z\-:])/gi;
            const matches = this.searchString.match(regex);
            if (matches) {
                const count = matches.length;
                if (count > 0) {
                    for (let i = 0; i < count; i++) {
                        const parts = matches[i].split(':');
                        const dateFields = ['created-at', 'load-start', 'load-end', 'dispatched-at'];
                        if (dateFields.indexOf(parts[0]) !== -1) {
                            this.searchParams[parts[0]].value = DispatchBoardComponent.parseBsDateRange(parts[1]);
                        } else {
                            this.searchParams[parts[0]].value = parts[1];
                        }
                    }
                }
            }
        }
        this.gridApi.rowDataRequest.filters[0] = <TableGridFilters> {
            q: this.searchString
        };
        this.gridApi.firstPage();
    }

    onShowHide() {
        this.isHidden = !this.isHidden;
    }

    selectAllRows() {
        // this.gridOptions.api.selectAll();
    }

    onEditOrder() {
        const selectedRows = this.gridApi.getSelectedRows();
        const rowCount = selectedRows.length;
        if (rowCount > 0) {
            const order = selectedRows[0];
            const initialState = {
                orders: selectedRows,
                order: order,
                selectedOrder: order.id
            };
            this.bsModalRef = this.modalService.show(EditOrderModalComponent, {
                class: 'modal-lg',
                initialState: initialState
            });
            this.bsModalRef.content.closeBtnName = 'Close';
            this.bsModalRef.content.title = 'Edit Order: ' + order.id + '';
            this.bsModalRef.content.template = '';
            this.bsModalRef.content.order = order;

            this.bsModalRef.content.onClose.subscribe(result => {
                if (result === true) {
                    // They pressed submit
                    this.gridApi.refresh();
                } else if (result === false) {
                    // They pressed No
                } else {
                    // Modal was just hidden
                }
            });
        }
    }

    showDetails(order) {
        // console.log(order);
    }

    exportCsv() {
        const data = [];
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            useBom: true,
            noDownload: false,
            headers: [
                'Status',
                'Order #',
                'Create Date',
                'Dispatched By',
                'Driver',
                'Priority',
                'Product',
                'Start Time',
                'Pickup',
                'Equipment',
                'Ticket',
                'Offload Name',
                'Offload Number',
                'BBLs',
                'Start',
                'Notes',
            ]
        };
        for (const i in this.orders) {
            if (typeof this.orders[i] !== 'undefined') {
                const order: OrderObj = this.orders[i];
                data.push({
                    status: order.status_name,
                    id: order.id,
                    'created at': order.created_at_date,
                    'dispatch by': order.dispatched_by.first_name,
                    driver: '',
                    priority: order.priority_name,
                    product: order.product_code,
                    start: '',
                    pickup: '',
                    equipment: '',
                    ticket: '',
                    'offload name': '',
                    'offload number': '',
                    bbls: order.gross_barrels,
                    notes: order.notes
                });

                const count = order.pickups.length;
                for (let i2 = 0; i2 < count; i2++) {
                    if (order.pickups[i2].corrected_at) {
                        continue;
                    }

                    data.push({
                        status: '',
                        id: '',
                        'created at': '',
                        'dispatch by': '',
                        driver: order.driver_name,
                        priority: '',
                        product: '',
                        start: (typeof order.pickups[i2].pickup_data !== 'undefined' && order.pickups[i2].pickup_data)
                            ? order.pickups[i2].pickup_data.load_start_formatted : '',
                        pickup: order.pickups[i2].lease_name,
                        equipment: order.pickups[i2].equipment_name,
                        ticket: order.pickups[i2].ticket_number,
                        'offload name': '',
                        'offload number': '',
                        bbls: (typeof order.pickups[i2].pickup_data !== 'undefined' && order.pickups[i2].pickup_data)
                            ? order.pickups[i2].pickup_data.gross_barrels : 0,
                        notes: (typeof order.pickups[i2].pickup_data !== 'undefined' && order.pickups[i2].pickup_data)
                            ? order.pickups[i2].pickup_data.notes : ''
                    });
                }
                const unloadCount = order.unloads.length;
                for (let i2 = 0; i2 < unloadCount; i2++) {
                    if (order.unloads[i2].corrected_at) {
                        continue;
                    }
                    data.push({
                        status: '',
                        id: '',
                        'created at': '',
                        'dispatch by': '',
                        driver: order.driver_name,
                        priority: '',
                        product: '',
                        start: (typeof order.unloads[i2].unload_data !== 'undefined' && order.unloads[i2].unload_data)
                            ? order.unloads[i2].unload_data.load_start_formatted : '',
                        pickup: '',
                        equipment: '',
                        ticket: order.unloads[i2].ticket_number,
                        'offload name': order.unloads[i2].offload_name,
                        'offload number': order.unloads[i2].offload_number,
                        notes: (typeof order.unloads[i2].unload_data !== 'undefined' && order.unloads[i2].unload_data)
                            ? order.unloads[i2].unload_data.notes : ''
                    });
                }
            }
        }

        const csv = new ngxCsv(data, 'Orders', options);
    }

    toggleSettings() {
        this.gridApi.toggleSettings();
    }
}
