import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {OffloadsService} from '../../services/api/offloads.service';
import {IOption} from 'ng-select';
import {StatusesService} from '../../services/api/statuses.service';
import {DriversService} from '../../services/api/drivers.service';
import {Pickup} from '../../models/pickup.model';
import {LeasesService} from '../../services/api/leases.service';
import {Equipment} from '../../models/equipment.model';
import * as moment from 'moment';

@Component({
    selector: 'app-modal-component',
    templateUrl: './edit-pickup-modal.component.html',
    styleUrls: ['./edit-pickup-modal.component.scss']
})
export class EditPickuprModalComponent implements OnInit {

    title: string;
    closeBtnName: string;
    list: any[] = [];
    active: boolean;
    onClose: Subject<boolean>;
    data: any[] = [];
    template = '';
    offloads: Array<IOption> = [];
    defaultOffload = '';
    statuses: Array<IOption> = [];
    defaultStatus = '2';
    drivers: Array<IOption> = [];
    defaultDriver = '1';
    pickup: Pickup;
    leaseSearchDebounce;
    leases: Array<IOption> = [];
    lease;
    changePickup;
    leaseSelect;
    equipment: Equipment[];
    selectedEquipmentStr;
    selectedEquipment: Equipment = {
        capacity: 0,
        units_per_increment: 0,
        equipment_no: '',
        id: 0,
        type: undefined
    };
    equipmentType;
    originalPickup: Pickup;
    loadStartDate;
    loadStartTime;
    loadEndDate;
    loadEndTime;
    selectedDriver = '';

    constructor(public bsModalRef: BsModalRef,
                private offloadService: OffloadsService,
                private statusesService: StatusesService,
                private driversService: DriversService,
                private leasesService: LeasesService,
                private changeDetector: ChangeDetectorRef) {
        this.onClose = new Subject();
    }

    async onLeaseSelect(event) {
        const lease = await this.leasesService.getLease(this.lease.id).toPromise();
        this.pickup.lease_id = lease.data.id;
        this.pickup.lease_name = lease.data.name;
        this.pickup.lease_number = lease.data.number;
        this.getEquipment(lease);
    }

    async onDriverSelect(event) {
        this.pickup.driver_id = event.value;
    }

    async onEquipmentSelect(event) {
        const equipment: Equipment = JSON.parse(event.value);
        this.selectedEquipmentStr = JSON.stringify(equipment);
        this.selectedEquipment = equipment;
        this.pickup.equipment_name = equipment.equipment_no;
        if (equipment.type === 'tank') {
            this.pickup.tank_id = equipment.id;
            this.equipmentType = 'tank';
            this.pickup.meter_id = null;
        } else {
            this.pickup.meter_id = equipment.id;
            this.equipmentType = 'meter';
            this.pickup.tank_id = null;
        }
        this.calculateGrossBarrels();
    }

    async ngOnInit() {
        // Yes this is on purpose to clone the object without reference.
        this.originalPickup = JSON.parse(JSON.stringify(this.pickup));
        this.originalPickup = JSON.parse(JSON.stringify(this.pickup));
        this.pickup.pickup_data.load_start = moment(this.pickup.pickup_data.load_start_formatted, 'MM/DD/yyyy hh:mm').toJSON();
        this.pickup.pickup_data.load_end = moment(this.pickup.pickup_data.load_end_formatted, 'MM/DD/yyyy hh:mm').toJSON();
        console.log(this.pickup.pickup_data);
        console.log(this.originalPickup.pickup_data);
        this.driversService.getDrivers().subscribe((response: any) => {
            const count = response.data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                const option = {
                    value: response.data[i].id.toString(),
                    label: `${response.data[i].driver_name} (${response.data[i].driver_number})`,
                    disabled: false
                };
                if (this.pickup.driver_id !== null && response.data[i].id.toString() === this.pickup.driver_id.toString()) {
                    this.selectedDriver = response.data[i].id.toString();
                }
                options.push(option);
            }
            this.drivers = options;
        });

        this.leasesService.getLeases().subscribe(response => {
            const count = response.data.length;
            const options = [];
            let leaseFound = false;
            for (let i = 0; i < count; i++) {
                const option = {
                    value: response.data[i].id.toString(),
                    label: response.data[i].name,
                    disabled: false
                };
                options.push(option);
                if (response.data[i].id.toString() === this.pickup.lease_id.toString()) {
                    leaseFound = true;
                    this.lease = response.data[i];
                    this.getEquipment(this.lease);
                }
            }
            if (!leaseFound) {
                (async () => {
                    const lease = await this.leasesService.getLease(this.pickup.lease_id).toPromise();
                    const option = {
                        value: lease.data.id.toString(),
                        label: lease.data.name,
                        disabled: false
                    };
                    options.push(option);
                    this.leases = options;
                    this.lease = lease.data;
                    this.getEquipment(lease);
                    this.changeDetector.detectChanges();
                })();
            } else {
                this.leases = options;
            }
        });

        this.statusesService.getStatuses().subscribe(data => {
            const count = data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: data[i].id.toString(),
                    label: data[i].name,
                    disabled: false
                });
            }
            this.statuses = options;
        });
    }

    getEquipment(lease) {
        const options = [];
        let equipment: Equipment;
        const thisLease = lease.data;
        if (typeof thisLease.tanks !== 'undefined' && thisLease.tanks.length > 0) {
            const tankCount = thisLease.tanks.length;
            for (let tankIndex = 0; tankIndex < tankCount; tankIndex++) {
                equipment = {
                    id: thisLease.tanks[tankIndex].id,
                    equipment_no: 'T:' + thisLease.tanks[tankIndex].number,
                    type: 'tank',
                    units_per_increment: thisLease.tanks[tankIndex].units_per_increment,
                    capacity: thisLease.tanks[tankIndex].capacity
                };
                options.push({
                    value: JSON.stringify(equipment),
                    label: 'Tank: ' + thisLease.tanks[tankIndex].number + ' (' + equipment.capacity + 'bbls)',
                    disabled: false
                });
                if (this.pickup.tank_id === equipment.id) {
                    this.equipmentType = 'tank';
                    this.selectedEquipmentStr = JSON.stringify(equipment);
                    this.selectedEquipment = equipment;
                }
            }
        }
        if (typeof thisLease.meters !== 'undefined' && thisLease.meters.length > 0) {
            const meterCount = thisLease.meters.length;
            for (let meterIndex = 0; meterIndex < meterCount; meterIndex++) {
                equipment = {
                    id: thisLease.meters[meterIndex].id,
                    equipment_no: 'M:' + thisLease.meters[meterIndex].number,
                    type: 'meter',
                    units_per_increment: 0
                };
                options.push({
                    value: JSON.stringify(equipment),
                    label: 'Meter: ' + thisLease.meters[meterIndex].number,
                    disabled: false
                });
                if (this.pickup.meter_id === equipment.id) {
                    this.equipmentType = 'meter';
                    this.selectedEquipmentStr = JSON.stringify(equipment);
                    this.selectedEquipment = equipment;
                }
            }
        }
        this.equipment = options;
    }

    public onConfirm(): void {
        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.pickup = Object.assign(this.pickup, this.originalPickup);
        this.active = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this.bsModalRef.hide();
    }

    public _calculateGravity() {
        let observedGravity;
        let observedTemperature;

        if (typeof this.pickup.pickup_data.obsgravity === 'string') {
            observedGravity = parseFloat(this.pickup.pickup_data.obsgravity)
        } else {
            observedGravity = this.pickup.pickup_data.obsgravity;
        }

        if (typeof this.pickup.pickup_data.obstemp === 'string') {
            observedTemperature = parseFloat(this.pickup.pickup_data.obstemp);
        } else {
            observedTemperature = this.pickup.pickup_data.obstemp;
        }

        if (observedGravity == null || observedTemperature == null) {
            return;
        }
        let ExpansionCoefficient;
        let VolumeCorrectionFactor;
        let CorrectedDenominator;
        let TrueGravity;
        let TemperatureDifference;
        let TemperatureCoefficient;
        let Density;
        let CorrectedDensity;
        let loopI;
        const StandardTemperature = 60;
        TemperatureDifference = observedTemperature - StandardTemperature;
        TemperatureCoefficient = 1 -
            0.00001278 * TemperatureDifference -
            0.0000000062 * Math.pow(TemperatureDifference, 2);
        Density = (141.5 * 999.012) / (131.5 + observedGravity);
        CorrectedDensity = TemperatureCoefficient * Density;
        CorrectedDenominator = CorrectedDensity;
        for (loopI = 1; loopI <= 10; loopI++) {
            ExpansionCoefficient = 341.0957 / Math.pow(CorrectedDenominator, 2);
            VolumeCorrectionFactor = Math.exp(-ExpansionCoefficient *
                TemperatureDifference -
                0.8 * (Math.pow(ExpansionCoefficient, 2) * Math.pow(TemperatureDifference, 2)));
            CorrectedDenominator = CorrectedDensity / VolumeCorrectionFactor;
        }
        TrueGravity = 141.5 * 999.012 / CorrectedDenominator - 131.5;

        this.pickup.pickup_data.corrgravity = parseFloat(parseFloat(TrueGravity).toFixed(2));

        return;
    }

    calculateGrossBarrels() {
        let barrels = 0;
        if (this.equipmentType === 'tank') {
            let topIncrements = 0;
            topIncrements += this.pickup.pickup_data.tank_top_feet * 48;
            topIncrements += this.pickup.pickup_data.tank_top_inches * 4;
            topIncrements += this.pickup.pickup_data.tank_top_quarter_inches;
            let bottomIncrements = 0;
            bottomIncrements += this.pickup.pickup_data.tank_bottom_feet * 48;
            bottomIncrements += this.pickup.pickup_data.tank_bottom_inches * 4;
            bottomIncrements += this.pickup.pickup_data.tank_bottom_quarter_inches;
            const diff = topIncrements - bottomIncrements;
            const equipment = this.selectedEquipment;
            barrels = parseFloat((diff * equipment.units_per_increment).toFixed(2));
        } else {
            const open: number = this.pickup.pickup_data.meter_open;
            const close: number = this.pickup.pickup_data.meter_close;
            barrels = parseFloat((close - open).toFixed(2));
        }
        this.pickup.pickup_data.gross_barrels = barrels;
        return barrels;
    }

    leaseSearch(term: string): void {
        if (this.leaseSearchDebounce) {
            clearTimeout(this.leaseSearchDebounce);
        }
        this.leaseSearchDebounce = setTimeout(() => {
            if (term.length > 0) {
                this.leasesService.leaseSearch(term).subscribe(response => {
                    const count = response.data.length;
                    const options = [];
                    for (let i = 0; i < count; i++) {
                        options.push({
                            value: response.data[i],
                            label: response.data[i].name,
                            disabled: false
                        });
                    }
                    this.leases = options;
                });
            } else {
                this.leasesService.getLeases().subscribe(data => {
                    const count = data.length;
                    const options = [];
                    for (let i = 0; i < count; i++) {
                        options.push({
                            value: data[i].id.toString(),
                            label: data[i].name,
                            disabled: false
                        });
                    }
                    this.leases = options;
                });
            }
        }, 250);

    }
}
