<div *ngIf="errorMessage" class="alert alert-warning">
    <strong>Warning!</strong> {{errorMessage | json}}
</div>

<div class="form-group row col-sm-12 pay-codes-loading"  >
    <div class="col-sm-12">
        <button class="btn btn-dark" (click)="getPayCodes(payCode.code)"
                [ng2-loading]="show"
                [config]="loadingConfig">Load
        </button>
    </div>
</div>

<div class="form-group row col-sm-12 grid-pc-items"  >
    <div class="col-sm-12">
<!--        <ag-grid-angular-->
<!--                domLayout='autoHeight'-->
<!--                class="ag-theme-alpine"-->
<!--                [id]="payCode.code"-->
<!--                [rowData]="payCodeItems"-->
<!--                [gridOptions]="payCodeGridOptions">-->
<!--        </ag-grid-angular>-->
    </div>
</div>

