import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Trailer} from '../../models/trailer.model';

@Injectable()
export class TrailersService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/trailer');
    }
    getTrailers(): Observable<Trailer[]> {
        return this.http.get<Trailer[]>(this.getEndpoint());
    }
}
