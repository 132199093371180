import {Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input, ViewChild, ViewChildren, QueryList} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {IOption, SelectComponent} from 'ng-select';
import {OperatorsService} from '../../services/api/operators.service';
import {LeasesService} from '../../services/api/leases.service';
import {Lease} from '../../models/Lease.model';
import {ProductsService} from '../../services/api/products.service';
import {ServicesService} from '../../services/api/services.service';
import {PrioritiesService} from '../../services/api/priorities.service';
import {OriginsService} from '../../services/api/origins.service';
import {LoadTypesService} from '../../services/api/load-type.service';
import {OffloadsService} from '../../services/api/offloads.service';
import {Order, OrderObj} from '../../models/order.model';
import {OrdersService} from '../../services/api/orders.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ModalCreateOrderComponent} from '../modal-create-order/modal-create-order.component';
import {Pickup} from '../../models/pickup.model';
import {Equipment} from '../../models/equipment.model';
import {Unload} from '../../models/unload.model';
import {Operator} from '../../models/operator.model';
import {RestrictionsService} from '../../services/api/restriction.service';
import {PageFiltersService} from '../../services/api/page-filters.service';
import {toNumber} from 'ngx-bootstrap/timepicker/timepicker.utils';
import {PageFiltersApiService} from '../../services/api/page-filters-api.service';
import {EditOrderModalComponent} from '../edit-order-modal/edit-order-modal.component';

@Component({
    selector: 'app-create-order',
    templateUrl: './create-order.component.html',
    styleUrls: [
        './create-order.component.scss',
        '../../../scss/vendors/bs-datepicker/bs-datepicker.scss',
        '../../../scss/vendors/ng-select/ng-select.scss',
    ],
    encapsulation: ViewEncapsulation.None,
})

export class CreateorderComponent implements OnInit {
    private _clickedAddPickup = false;
    bsModalRef: BsModalRef;
    selectedLease: Lease;
    pickups: Pickup[] = [];
    lease: Lease;
    selectedEquipment: Equipment;
    addSplit;
    restriction: any = false;
    leaseDetails: boolean | number = false;
    leaseIsRestricted = false;
    multipleOrders = 1;
    externalTicketNumber;
    recurring = false;
    recurringStartDate;
    recurringEndDate;
    notes = '';
    unknowEquipment = false;
    showLoading = false;
    // PAGE FILTER PROPERTIES
    pageFilters = {};
    pageFilterValues = {};
    pageFilterSearch = {};
    pageFiltersLoaded = false;
    filterSearchDebounce;
    // END PAGE FILTER PROPERTIES

    @Input() leaseNotes = '';
    @Output() leaseEvent = new EventEmitter<Lease | Boolean>();

    constructor(private modalService: BsModalService,
                private leasesService: LeasesService,
                private orderService: OrdersService,
                private restrictionsService: RestrictionsService,
                private pageFilterService: PageFiltersApiService) {
        this.getPageFilters();
    }

    ngOnInit() {
        // console.log(this);
    }

    async onSubmit() {
        this.showLoading = true;
        if (this.pickups.length === 0 || this.addSplit) {
            return false;
        }
        let orderArr: OrderObj[] = [];
        const order = new Order;
        order.service_id = 2;
        order.notes = this.notes ? this.notes : '';
        order.from_web = true;
        order.status_id = this.pageFilterValues['Status'] ? this.pageFilterValues['Status'] : 1;
        order.driver_id = this.pageFilterValues['Driver'] ? this.pageFilterValues['Driver'] : null;
        order.origin_id = this.pageFilterValues['Origin'] ? this.pageFilterValues['Origin'] : 1;
        order.priority_id = this.pageFilterValues['Priority'] ? parseInt(this.pageFilterValues['Priority'], 10) : 1;
        // order.product_id = this.pageFilterValues['Product'] ? this.pageFilterValues['Product'] : 1;
        order.product_id = 1;
        order.load_type_id = 1;
        order.external_number = '';
        order.pickups = this.pickups;
        if (this.externalTicketNumber) {
            for (let i = 0; i < order.pickups.length; i++) {
                order.pickups[i].external_ticket_number = this.externalTicketNumber;
            }
        }
        this.multipleOrders = Math.max(1, this.multipleOrders);
        order.multiple_orders = this.multipleOrders;
        if (this.recurring) {
            order.recurring_start_date = this.recurringStartDate;
            order.recurring_end_date = this.recurringEndDate;
        }

        order.unloads = [];
        if (this.pageFilterValues['Offload']) {
            const unload = new Unload();
            unload.offload_id = this.pageFilterValues['Offload'];
            order.unloads.push(unload);
        }
        orderArr = await this.orderService.create(order);
        this.showLoading = false;
        if (typeof orderArr !== 'undefined') {
            // console.log(orderArr);
            if (typeof orderArr[0].pickups[0].lease_id !== 'undefined') {
                this.leasesService.getLease(orderArr[0].pickups[0].lease_id).subscribe((lease) => {
                    this.leaseEvent.emit(lease.data);
                    this.selectedLease = null;
                });
            }

            if (orderArr[0].status_id === 5) {
                this.onOrderCorrection(orderArr);
            } else {
                this.openModal(orderArr);
            }
        } else {
            // Something went wrong
        }
        this.reset();
        // this.router.navigate(['/dispatch']);

    }

    cancelSplit() {
        this.addSplit = false;
        this.leaseEvent.emit(false);
    }

    reset() {
        this.pickups = [];
        this.multipleOrders = 1;
        this.recurring = false;
        this.recurringStartDate = null;
        this.recurringEndDate = null;
        this.notes = '';
        this.pageFilterValues['Product'] = '';
        this.pageFilterValues['Offload'] = '';
        this.pageFilterValues['Priority'] = '';
        this.pageFilterValues['Status'] = '';
        this.pageFilterValues['Driver'] = '';
        this.pageFilterValues['Origin'] = '';
        this.unknowEquipment = false;
    }

    onLeaseRestricted(event) {
        this.leaseIsRestricted = event;
    }

    openModal(order: OrderObj[]) {
        const initialState = {
            orders: order,
            closeBtnName: 'Close'
        };
        // console.log(order);
        const bsModalRef = this.modalService.show(ModalCreateOrderComponent, {initialState: initialState});
        bsModalRef.content.onClose.subscribe(() => {
            // console.log('close called');
        });
    }

    addPickup() {
        if (this._clickedAddPickup) {
            return;
        }
        this._clickedAddPickup = true;
        this.addSplit = false;
        const pickup = new Pickup();
        const leaseId = this.pageFilterValues['Lease'];


        this.leasesService.getLease(leaseId).subscribe(data => {
            this.selectedLease = data.data;
            pickup.lease_name = this.selectedLease.name;
            pickup.lease_id = this.selectedLease.id;
            pickup.lease_number = this.selectedLease.number;


            if (this.selectedEquipment && this.selectedEquipment.id.toString() !== '-1' && !this.unknowEquipment) {
                if (this.selectedEquipment.type === 'tank') {
                    pickup.tank_id = this.selectedEquipment.id;
                } else {
                    pickup.meter_id = this.selectedEquipment.id;
                }
                pickup.equipment_name = this.selectedEquipment.equipment_no;
            } else {
                pickup.equipment_name = 'unknown';
                pickup.tank_id = null;
                pickup.meter_id = null;
            }
            this.pickups.push(pickup);
            this.showDetails(pickup);
            if (this.selectedLease.notes) {
                let orderNotes = this.notes;
                if (orderNotes) {
                    orderNotes += '\n';
                } else {
                    orderNotes = '';
                }
                orderNotes += `[${this.selectedLease.name}]: ${this.selectedLease.notes}`;
                this.notes = orderNotes;
            }
            this.pageFilterSearch = {};
            this.pageFilterValues['Operator'] = '';
            this.pageFilterValues['Lease'] = '';
            // this.pageFilterValues['Meter'] = '';
            // this.pageFilterValues['Tank'] = '';
            this.pageFilterValues['Equipment'] = '';
            this.pageFilterValues['Status'] = '1';
            this.pageFilterValues['Driver'] = '';
            this.unknowEquipment = false;
            this.pageFilterValues['Priority'] = '2';
            this.updatePageFilters();
            this._clickedAddPickup = false;
        });
    }

    removePickup(pickup: Pickup) {
        this.pickups = this.pickups.filter((item: Pickup) => {
            return (JSON.stringify(pickup) !== JSON.stringify(item));
        });
    }

    async getRestrictions(lease) {
        const restrictionResponse = await this.restrictionsService.getLeaseRestrictions(lease.id).toPromise();
        // console.log(restrictionResponse);
        if (!restrictionResponse.errors.length) {
            this.restriction = restrictionResponse.data;
            // console.log(this.restriction);
        } else {
            this.restriction = false;
        }
    }

    showDetails(pickup: Pickup) {
        const leaseId = pickup.lease_id;
        if (this.leaseDetails === leaseId) {
            this.leaseDetails = false;
        } else {
            this.leaseDetails = leaseId;
        }

        if (this.leaseDetails !== false && typeof leaseId !== 'undefined') {
            this.leasesService.getLease(leaseId).subscribe((data: Lease) => {
                if (typeof data.id !== 'undefined') {
                    this.leaseEvent.emit(data);
                }
            });
        } else {
            this.leaseEvent.emit(false);
        }
    }


    // BEGIN PAGE FILTERS
    getPageFilters() {
        this.pageFilterService.getFilters('Create Order', {}).subscribe((response) => {
            for (let i = 0; i < response.length; i++) {
                this.pageFilters[response[i].name] = response[i];
                this.pageFilterValues[response[i].name] = '';
                if (this.pageFilters[response[i].name].searchable) {
                    this.pageFilterValues[this.pageFilters[response[i].name].name] = '';
                }
            }
            this.pageFiltersLoaded = true;
            // console.log(this.pageFilters);
            // console.log(this.pageFilterValues);
        });
    }

    private updatePageFilters() {
        let hasChanged = false;
        this.pageFilterService.getFilters('Create Order', this.pageFilterSearch).subscribe((response) => {
            for (let i = 0; i < response.length; i++) {
                if (response[i].searchable) {
                    this.pageFilters[response[i].name].options = response[i].options;
                    if (response[i].options.length === 1 && this.pageFilterValues[response[i].name] !== response[i].options[0].value) {
                        if (!(response[i].name === 'Tank' || response[i].name === 'Meter' || response[i] === 'Equipment')) {
                            this.pageFilterValues[response[i].name] = response[i].options[0].value;
                            this.filterSelected(this.pageFilters[response[i].name], {}, false);
                            // if (response[i].name === 'Tank' || response[i].name === 'Meter') {
                            //     this.selectEquipment(response[i].options[0], response[i].name.toLowerCase());
                            // }
                            hasChanged = true;
                        }
                    }
                }
            }
            if (hasChanged) {
                this.updatePageFilters();
            }
        });
    }

    filterDeselected(filter, $event) {
        this.filterSelected(filter, $event, false);
    }

    filterSelected(filter, $event, autoUpdate = true) {
        const filterName = filter.name.split(' ').join('');
        const key = `selected${filterName}`;
        const selectedValue = (typeof filter.multiSelect !== 'undefined' && filter.multiSelect)
            ? this.pageFilterValues[filter.name].join(',') : this.pageFilterValues[filter.name];
        if (typeof selectedValue !== 'undefined') {
            if (filterName === 'Lease' && typeof selectedValue !== 'undefined') {
                this.leaseDetails = selectedValue;
                this.leasesService.getLease(selectedValue).subscribe((data) => {
                    if (typeof data.data.id !== 'undefined') {
                        this.leaseEvent.emit(data.data);
                    }
                });
                this.getRestrictions({id: selectedValue}).then();
            }
            this.pageFilterSearch[filter.name] = '';
            this.pageFilterSearch[key] = selectedValue;
        } else {
            if (filter.name === 'Lease') {
                this.leaseEvent.emit(false);
                this.restriction = false;
            }
            this.pageFilterSearch[filter.name] = '';
            this.pageFilterSearch[key] = '';
        }
        if (autoUpdate) {
            this.updatePageFilters();
        }
    }

    public filterSearch(filter, $event) {
        if (filter.searchable) {
            this.pageFilterSearch[filter.name] = $event;
            if (this.filterSearchDebounce) {
                clearTimeout(this.filterSearchDebounce);
            }

            this.filterSearchDebounce = setTimeout(() => {
                this.filterSearchDebounce = null;
                this.pageFilterService.getFilters('Create Order', this.pageFilterSearch).subscribe((response) => {
                    for (let i = 0; i < response.length; i++) {
                        if (response[i].searchable) {
                            this.pageFilters[response[i].name].options = response[i].options;
                        }
                    }
                });
            }, 250);
        }
    }

    // END PAGE FILTERS
    public selectEquipment($event) {
        // console.log($event);
        const labelParts = $event.label.split(':');
        this.selectedEquipment = {
            type: labelParts[0].toLowerCase(),
            equipment_no: `${$event.label}`,
            id: $event.value
        }
    }

    onOrderCorrection(orders) {
        const rowCount = orders.length;
        if (rowCount > 0) {
            const order = orders[0];
            const initialState = {
                orders: orders,
                order: order,
                selectedOrder: order.id
            };
            this.bsModalRef = this.modalService.show(EditOrderModalComponent, {
                class: 'modal-lg',
                initialState: initialState
            });
            this.bsModalRef.content.closeBtnName = 'Close';
            this.bsModalRef.content.title = 'Edit Order: ' + order.id + '';
            this.bsModalRef.content.template = '';
            this.bsModalRef.content.order = order;

            this.bsModalRef.content.onClose.subscribe(result => {
                if (result === true) {
                    // They pressed submit;
                } else if (result === false) {
                    // They pressed No
                } else {
                    // Modal was just hidden
                }
            });
        }
    }
}
