import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';


@Injectable()
export class EomService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/eom');
    }

    getReports(start = null, end = null, search = null, sort = null, order = null): Observable<any> {
        let params: any = {};
        if (start !== null && end !== null) {
            params = {start: start, end: end}
        }
        if (search !== null) {
            params.q = search;
        }

        if (sort !== null) {
            params.sort = sort;
        }

        if (order !== null) {
            params.order = order;
        }
        return this.http.get<any>(this.getEndpoint(), {params: params});
    }

    void(ticketNumbers) {
        return this.http.post<any>(this.getEndpoint() + '/void/' + ticketNumbers, {});
    }

    forceRunStatement(ticketNumbers) {
        return this.http.post<any>(this.getEndpoint() + '/runStatement/' + ticketNumbers, {});
    }

    runTickets(ticketNumber) {
        return this.http.get<any>(this.getEndpoint() + '/runTickets/' + ticketNumber);
    }

    view(id) {
        return this.http.get<any>(this.getEndpoint() + '/' + id);
    }

    update(eomRecord) {
        return this.http.put(this.getEndpoint() + '/' + eomRecord.id, eomRecord);
    }
}
