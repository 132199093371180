import {NgModule} from '@angular/core';

import {ThirdPartyHaulersComponent} from './third-party-haulers.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AngularTableGridModule} from 'angular-table-grid';
import {Ng2LoadingSpinnerModule} from 'ng2-loading-spinner';
import {ThirdPartyHaulersRoutingModule} from './third-party-haulers-routing.module';
import {ThirdPartyHaulerDetailsComponent} from './components/third-party-hauler-details.component';

@NgModule({
    imports: [
        ThirdPartyHaulersRoutingModule,
        CommonModule,
        FormsModule,
        AngularTableGridModule,
        Ng2LoadingSpinnerModule.forRoot({})
    ],
    declarations: [ThirdPartyHaulersComponent, ThirdPartyHaulerDetailsComponent],
    entryComponents: [ThirdPartyHaulerDetailsComponent]
})
export class ThirdPartyHaulersModule {
}
