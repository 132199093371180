import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Offload} from '../../models/offload.model';

@Injectable()
export class OffloadsService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/offload');
    }
    getOffloads(): Observable<any> {
        return this.http.get<Offload[]>(this.getEndpoint());
    }

    public offloadSearch(term: any): Observable<any> {
        return this.http.post<Offload[]>(this.getEndpoint() + '/search', {
            data: {
                search_for: term
            }
        });
    }
}
