import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Order, OrderObj} from '../../models/order.model';
import {ApiService} from '../api.service';
import {PickupData} from '../../models/pickup-data.model';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';

export class OrderResponse {
    rowCount: number;
    data: OrderObj[];
}


@Injectable()
export class OrdersService extends ApiService {

    constructor(private http: HttpClient, public modalService: BsModalService) {
        super('/api/order');
    }

    getOrders(start = null, end = null, search = null, sort = null, order = null): Observable<OrderResponse> {
        let params: any = {};
        if (start !== null && end !== null) {
            params = {start: start, end: end}
        }
        if (search !== null) {
            params.q = search;
        }

        if (sort !== null) {
            params.sort = sort;
        }

        if (order !== null) {
            params.order = order;
        }
        return this.http.get<OrderResponse>(this.getEndpoint(), {params: params});
    }

    getNewOrders(start = null, end = null, search = null, showRecurring = false, sort = null, order = null): Observable<OrderResponse> {
        let params: any = {};
        if (start !== null && end !== null) {
            params = {start: start, end: end}
        }
        if (search !== null) {
            params.q = search;
        }
        if (showRecurring) {
            params.r = true;
        }

        if (sort !== null) {
            params.sort = sort;
        }

        if (order !== null) {
            params.order = order;
        }

        return this.http.get<OrderResponse>(this.getEndpoint() + '/new', {params: params});
    }

    getLeaseOrders(leaseId: number, start, end): Observable<OrderResponse> {
        let params = {};
        if (start !== null && end !== null) {
            params = {start: start, end: end}
        }
        return this.http.get<OrderResponse>(this.getEndpoint() + '/lease/' + leaseId, {params: params});
    }

    getPickupData(order_id: number): Observable<PickupData[]> {
        return this.http.get<PickupData[]>(this.getEndpoint() + '/' + order_id + '/pickup-data');
    }

    async create(order: Order): Promise<OrderObj[]> {
        return await this.http.post<OrderObj[]>(this.getEndpoint(), [order]).toPromise();
    }

    async correction(orders: OrderObj[]): Promise<any> {
        return await this.http.post<OrderObj[]>(this.getEndpoint() + '/correction', orders).toPromise();
    }

    async edit(orders: OrderObj[]): Promise<any> {
        return await this.http.post<OrderObj[]>(this.getEndpoint() + '/bulkEdit', orders).toPromise();
    }

    async reject(pickupId, data): Promise<any> {
        return await this.http.post<any>(this.getEndpoint() + '/pickup/' + pickupId + '/reject', data).toPromise();
    }

    async delete(pickupId): Promise<any> {
        return await this.http.post<any>(this.getEndpoint() + '/pickup/' + pickupId + '/delete', {}).toPromise();
    }

    async dispatch(options) {
        const response: any = await this.http.post(this.getEndpoint() + '/dispatch', options).toPromise();
        if (response.errors.length > 0) {
            this.errorAlert(response.errors);
        }
        return response;
    }

    async generateUnloadTicketNumber(unload) {
        return await this.http.post<any>(this.getEndpoint() + '/unload/' + unload.id + '/generateTicketNumber', {}).toPromise();
    }

}
