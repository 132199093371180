<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>

<div class="modal-body modal-lg">
    <div class="form-group">
        <label for="status">Reject Reason</label>
        <ng-select id="status" class="bootstrap" [options]="rejectReasons"
                   filterPlaceholder="Type to search..." aria-describedby="rejectHelp" (selected)="selected($event)"></ng-select>
        <small id="rejectHelp" class="form-text text-muted">
            <span>Select a reject reason</span>
        </small>
    </div>
    <div class="form-group">
        <label for="notes">Notes</label>
        <textarea type="text" id="notes" class="bootstrap form-control form-control-lg" [(ngModel)]="reject.notes"></textarea>
        <small id="notesHelp" class="form-text text-muted">Reject notes.</small>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="onCancel()">{{closeBtnName}}</button>
    <button type="button" class="btn btn-success" (click)="onConfirm()">Reject</button>
</div>

