import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Region} from '../../models/region.model';

@Injectable()
export class RegionsService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/region');
    }
    getRegions(): Observable<Region[]> {
        return this.http.get<Region[]>(this.getEndpoint());
    }
}
