import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class SamsaraService extends ApiService {
    constructor(private http: HttpClient) {
        super('/api/samsara');
    }
    // getSamsaraStatusLogs(search = {}): Observable<any> {
    //     return ;
    // }
}
