<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>
<div class="modal-body">
    <div>
        <div class="alert alert-danger" *ngFor="let error of errors">
            <span>{{error}}</span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="onConfirm()">{{closeBtnName}}</button>
</div>
