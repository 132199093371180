<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>

<div class="modal-body modal-lg">
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <ng-select id="offloadloc" class="bootstrap" [(ngModel)]="unload.offload_id"
                               placeholder="Choose an Offload" [options]="offloads" [allowClear]="true"
                               filterPlaceholder="Type to search..."
                               (filterInputChanged)="offloadSearch($event)"></ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label class="col-form-label ">Driver</label>
                <ng-select #driverSelect class="bootstrap" placeholder="Select driver"
                           [options]="drivers" [(ngModel)]="selectedDriver" (selected)="onDriverSelect($event)"
                           filterPlaceholder="Type to search...">
                </ng-select>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Odometer</label>
                    <input type="text" class="form-control" [(ngModel)]="unload.unload_data.odometer"/>
                </div>
                <div class="form-group">
                    <label>Meter Reading</label>
                    <input type="text" class="form-control" [(ngModel)]="unload.unload_data.meter"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Meter Open</label>
                    <input type="text" class="form-control" [(ngModel)]="unload.unload_data.meter_open"/>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Meter close</label>
                    <input type="text" class="form-control" [(ngModel)]="unload.unload_data.meter_close"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Load Start</label>
                    <input type="text" class="form-control form-control-sm"  [(ngModel)]="this.unload.unload_data.load_start" bsDatepicker/>
                    <timepicker [minuteStep]="1" [(ngModel)]="this.unload.unload_data.load_start"></timepicker>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Load End</label>
                    <input type="text" class="form-control form-control-sm"  [(ngModel)]="this.unload.unload_data.load_end" bsDatepicker/>
                    <timepicker [minuteStep]="1" [(ngModel)]="this.unload.unload_data.load_end"></timepicker>
                </div>
            </div>
        </div>
    </div>
    <div class="row" class="p-1 mb-2 bg-light text-black" *ngIf="unload.unload_data.notes">
        <div class="col-md-12">
            <div class="form-group">
                <label>Driver Notes:</label>
                <textarea readonly class="form-control form-control-lg" [(ngModel)]="unload.unload_data.notes"></textarea>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="onCancel()">Close</button>
    <button type="button" class="btn btn-success" (click)="onConfirm()">Save</button>
</div>

