// @ts-ignore
import appVersion from 'assets/appVersion.json';

import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {ModalAppVersionComponent} from '../components/modal-app-version/modal-app-version.component';


class AppVersion {
    version: string;
    updates: string[]
}

@Injectable()
export class AppVersionService {
    private _endPoint: string;
    private _timer;
    private bsModalRef;
    public versionNumber: string;
    private _liveVersion: AppVersion;

    private static _getBaseUrl() {
        const getUrl = window.location;
        return getUrl.protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[1];
    }

    private static _getEndpoint() {
        return AppVersionService._getBaseUrl() + 'assets/appVersion.json';
    }

    constructor(private _http: HttpClient, private modalService: BsModalService) {
        this._endPoint = AppVersionService._getEndpoint();
        this.versionNumber = appVersion.version;
    }

    private async _isCurrent() {
        const liveVersion = await this._getVersion();
        this._liveVersion = liveVersion;
        return appVersion.version === liveVersion.version;
    }

    private async _getVersion(): Promise<AppVersion> {
        return this._http.get<AppVersion>(AppVersionService._getEndpoint()).toPromise()
    }

    public async checkVersion() {
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }

        const isCurrent = await this._isCurrent();

        if (!isCurrent) {
            this.bsModalRef = this.modalService.show(ModalAppVersionComponent, {
                initialState: {
                    liveVersion: this._liveVersion
                }
            });
            this.bsModalRef.content.template = '';

            this.modalService.onHide.subscribe(result => {
                window.location.replace(window.location.href);
                window.location.reload();
            });
        } else {
            this._timer = setTimeout(async () => {
                await this.checkVersion();
            }, 15000);
        }
    }

    public showWhatsNew() {
        this.bsModalRef = this.modalService.show(ModalAppVersionComponent, {
            initialState: {
                liveVersion: this._liveVersion,
                closeBtnName: 'Ok!'
            }
        });
        this.bsModalRef.content.template = '';
    }
}
