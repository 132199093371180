import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {OffloadsService} from '../../services/api/offloads.service';
import {Offload} from '../../models/offload.model';
import {IOption} from 'ng-select';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StatusesService} from '../../services/api/statuses.service';
import {DriversService} from '../../services/api/drivers.service';
import {OrderObj} from '../../models/order.model';
import {Unload} from '../../models/unload.model';
import * as moment from 'moment';

@Component({
    selector: 'app-modal-component',
    templateUrl: './edit-unload-modal.component.html',
    styleUrls: ['./edit-unload-modal.component.scss']
})
export class EditUnloadModalComponent implements OnInit {

    title: string;
    closeBtnName: string;
    list: any[] = [];
    active: boolean;
    onClose: Subject<boolean>;
    data: any[] = [];
    template = '';
    offloads: Array<IOption> = [];
    defaultOffload = '';
    statuses: Array<IOption> = [];
    defaultStatus = '2';
    drivers: Array<IOption> = [];
    defaultDriver = '1';
    dispatchOrderForm: FormGroup;
    order: OrderObj;
    orders: OrderObj[];
    activeTab = 'Order';
    selectedOrder;
    offload: Offload;
    unload: Unload;
    originalUnload: Unload;
    offloadSearchDebounce;
    selectedDriver = '';

    constructor(public bsModalRef: BsModalRef,
                private offloadService: OffloadsService,
                private statusesService: StatusesService,
                private driversService: DriversService) {
        this.onClose = new Subject();
    }

    setTab(tab) {
        this.activeTab = tab;
    }

    offloadSearch(term: string): void {
        if (this.offloadSearchDebounce) {
            clearTimeout(this.offloadSearchDebounce);
        }
        this.offloadSearchDebounce = setTimeout(() => {
            if (term.length > 0) {
                this.offloadService.offloadSearch(term).subscribe(response => {
                    const count = response.data.length;
                    const options = [];
                    for (let i = 0; i < count; i++) {
                        options.push({
                            value: response.data[i].id.toString(),
                            label: response.data[i].name,
                            disabled: false
                        });
                    }
                    this.offloads = options;
                });
            } else {
                this.offloadService.getOffloads().subscribe(data => {
                    const count = data.length;
                    const options = [];
                    for (let i = 0; i < count; i++) {
                        options.push({
                            value: data[i].id.toString(),
                            label: data[i].name,
                            disabled: false
                        });
                    }
                    this.offloads = options;
                });
            }
        }, 250);
    }

    ngOnInit() {
        // this.list.push('PROFIT!!!');
        this.originalUnload = JSON.parse(JSON.stringify(this.unload));
        this.unload.unload_data.load_start = moment(this.unload.unload_data.load_start_formatted, 'MM/DD/yyyy hh:mm').local().toJSON();
        this.unload.unload_data.load_end = moment(this.unload.unload_data.load_end_formatted, 'MM/DD/yyyy hh:mm').local().toJSON();

        this.driversService.getDrivers().subscribe((response: any) => {
            const count = response.data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                const option = {
                    value: response.data[i].id.toString(),
                    label: `${response.data[i].driver_name} (${response.data[i].driver_number})`,
                    disabled: false
                };
                if (response.data[i].id.toString() === this.unload.driver_id.toString()) {
                    this.selectedDriver = response.data[i].id.toString();
                }
                options.push(option);
            }
            this.drivers = options;
        });

        this.offloadService.getOffloads().subscribe(data => {
            const count = data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: data[i].id.toString(),
                    label: data[i].name,
                    disabled: false
                });
            }
            this.offloads = options;
        });

        this.statusesService.getStatuses().subscribe(data => {
            const count = data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: data[i].id.toString(),
                    label: data[i].name,
                    disabled: false
                });
            }
            this.statuses = options;
        });

        this.driversService.getDrivers().subscribe(response => {
            const count = response.data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: response.data[i].id.toString(),
                    label: response.data[i].driver_name,
                    disabled: false
                });
            }
            this.drivers = options;
        });

        const dispatchDate = new Date();
        this.dispatchOrderForm = new FormGroup({
            'offloadloc': new FormControl(this.defaultOffload, Validators.required),
            'status': new FormControl(this.defaultStatus, Validators.required),
            'driver': new FormControl(this.defaultDriver, Validators.required),
            'dispatch_date': new FormControl(dispatchDate, Validators.required),
        });
    }

    async onDriverSelect(event) {
        this.unload.driver_id = event.value;
    }

    public onConfirm(): void {
        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.unload = Object.assign(this.unload, this.originalUnload);
        this.active = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this.bsModalRef.hide();
    }

    public orderChanged(event) {
        const filterOrders = this.orders.filter((order) => {
            return order.id === parseInt(this.selectedOrder, 10);
        });
        this.order = filterOrders[0];
        this.title = 'Edit Order: ' + this.order.id;
    }
}
