<div class="modal-header">
    <h5 class="modal-title">Success!!</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-4">
            <h4>Orders</h4>
            <ul class="list-group">
                <li class="list-group-item" [class.active]="selectedOrder == order.id"
                    (click)="selectedOrder = order.id" *ngFor="let order of orders">{{order.id}}
                </li>
            </ul>
        </div>
        <ng-template ngFor [ngForOf]="orders" let-order>
            <div class="col-md-8"  *ngIf="selectedOrder == order.id">
                <div>
                    <h4>Pickups</h4>
                    <table class="table table-responsive table-striped table-sm">
                        <thead>
                        <tr>
                            <th>Lease</th>
                            <th>Equipment</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let pickup of order.pickups">
                            <td>{{pickup.lease_name}}</td>
                            <td>{{pickup.equipment_name}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h4>Unloads</h4>
                    <table class="table table-responsive table-striped table-sm">
                        <thead>
                        <tr>
                            <th>Offload</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let unload of order.unloads">
                            <td>{{unload.offload_name}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="onCancel()">{{closeBtnName}}</button>
</div>
