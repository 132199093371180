import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';

@Injectable()
export class ThirdPartyHaulersService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/third-party-haulers');
    }

    getHaulers(search = {}, start = null, end = null, sort = null, order = null) {
        const params: any = {};
        if (start !== null && end !== null) {
            params.start = start;
            params.end = end;
        }

        if (sort !== null) {
            params.sort = sort;
        }

        if (order !== null) {
            params.order = order;
        }
        return this.http.post<any>(this.getEndpoint() + '/list', {_pageFilters: search}, {params: params})
    }

    save(thirdPartyHauler) {
        return this.http.post<any>(this.getEndpoint() + '/save', thirdPartyHauler);
    }

    getLeases(thirdPartyHaulerId, start, end, search: any = false, showUnattached = false) {
        const params: any = {};
        if (search) {
            params.q = search;
        }
        if (start !== null && end !== null) {
            params.start = start;
            params.end = end;
        }

        const showUnattachedUri = showUnattached ? '/1' : '';
        return this.http.get(this.getEndpoint() + '/leases/' + thirdPartyHaulerId + showUnattachedUri, {
            params: params
        });
    }

    getOffloads(thirdPartyHaulerId, start, end, search: any = false, showUnattached = false) {
        const params: any = {};
        if (search) {
            params.q = search;
        }
        if (start !== null && end !== null) {
            params.start = start;
            params.end = end;
        }

        const showUnattachedUri = showUnattached ? '/1' : '';
        return this.http.get(this.getEndpoint() + '/offloads/' + thirdPartyHaulerId + showUnattachedUri, {
            params: params
        });
    }

    assign(thirdPartyHaulerId, type, ids) {
        console.log(ids);
        const params = {
            'ids[]': ids
        };
        return this.http.get(this.getEndpoint() + '/assign/' + thirdPartyHaulerId + '/' + type , {
            params: params
        });
    }

    unassign(thirdPartyHaulerId, type, ids) {
        const params = {
            'ids[]': ids
        };
        return this.http.get(this.getEndpoint() + '/unassign/' + thirdPartyHaulerId + '/' + type , {
            params: params
        });
    }
}
