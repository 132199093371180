import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {PayCode} from '../../models/pay-code.model';
import {Observable} from 'rxjs';
import {DriverLogObj} from '../../models/driver-log.model';
import {PayrollPeriodCheckDatesObj} from '../../models/payroll-period.model';
import {PayCodeItems} from '../../models/pay-code-items.model';
import {tap} from 'rxjs/operators';
import { NotificationObj } from '../../notifications/notifications.service';




export class DriverLogResponse {
    rowCount: number;
    checkDate: string;
    data: DriverLogObj[];
}

export class NotificationResponse {
    unreadCount: number;
    data: NotificationObj[];
}

export class NotificationReadResponse {
    numberMarkedAsRead: number;
    data: NotificationObj[];
}

export class NotificationUnreadResponse {
    numberMarkedAsUnread: number;
    data: NotificationObj[];
}


export class PayrollPeriodResponse {
    rowCount: number;
    data: PayrollPeriodCheckDatesObj[];
}


export class PayCodeResponse {
    rowCount: number;
    data: PayCode[];
}
export class PayCodeItemsResponse {
    rowCount: number;
    data: PayCodeItems[];
}

@Injectable()
export class PayrollService extends ApiService {
    constructor(
        private http: HttpClient
    ) {
        super('/api/payroll');
    }

    /**
     * Fetch all Active Pay Codes from the pay_codes table
     */
    getActivePayCodes(): Observable<PayCodeResponse> {
        return this.http.get<PayCodeResponse>(this.getEndpoint() + `/pay-codes/active`);
    }

    /**
     * Fetch the Pay Codes for a specific code and a specific payroll period
     *
     * @param code
     * @param checkDate
     */
    getPayCodeItems(code, checkDate: string): Observable<PayCodeItemsResponse> {
        return this.http.get<PayCodeItemsResponse>(this.getEndpoint() + `/pay-codes/` + code + `/check-date/` + checkDate)
            .pipe(  // TODO: for debugging...Remove this
                tap(
                    (result) => {
                        console.log('Pay Code: ' + code + ' Payroll Period: ' + checkDate);
                        console.log(result);
                    }
                )
            );
    }


    /**
     * Fetch a list of active drivers during a specific period
     * @param checkDate
     */
    getActiveDrivers(checkDate: string): Observable<any> {
        return this.http.get<any>(
            this.getEndpoint() +
            '/drivers/active/check-date/' + checkDate
        );
    }


    /**
     * Fetch a list of Drivers that do not have a terminated_date value in the employees table
     * @param checkDate
     */
    getDriversThatAreNotTerminated(): Observable<any> {
        return this.http.get<any>(
            this.getEndpoint() +
            '/drivers/not-terminated'
        );
    }


    /**
     * Fetch a list of check dates
     */
    getAllCheckDates(): Observable<PayrollPeriodResponse> {
        return this.http.get<PayrollPeriodResponse>(this.getEndpoint() + `/check-dates`);
    }



    /**
     * Fetch all driver logs for a specific driver where the Report Date is withing the payroll period date range
     * @param employeeNumber
     * @param checkDate
     */
    getAllDriverLogs(employeeNumber: string, checkDate: string): Observable<DriverLogResponse> {
        return this.http.get<DriverLogResponse>(this.getEndpoint() + `/logs/employee-number/${employeeNumber}/check-date/` + checkDate);
    }


    /**
     *
     * @param employeeNumber
     * @param checkDate
     */
    getAllNoticationsForPayrollPeriod(employeeNumber: string, checkDate: string): Observable<NotificationResponse> {
        return this.http.get<NotificationResponse>(this.getEndpoint() + `/logs/employee-number/${employeeNumber}/check-date/${checkDate}/notifications`);
    }


    /**
     * Update the Samsara Data for a specific driver, date
     * @param employeeNumber
     * @param reportDate
     */
    updateSpecificDriverDate(employeeNumber: string, reportDate: string): Observable<DriverLogResponse> {
        return this.http.get<DriverLogResponse>(this.getEndpoint() + `/logs/employee-number/${employeeNumber}/report-date/` + reportDate + '/update');
    }


    /**
     *
     * @param checkDate
     */
    getPayrollPeriodDuration(checkDate: string): Observable<any> {
        return this.http.get<any>(
            this.getEndpoint() +
            '/check-date/' + checkDate
        );
    }

    /**
     *
     * @param checkDate
     * @param drivers
     */
    createPayrollReport(checkDate: string, drivers: any): Observable<any> {
        return this.http.post(
            this.getEndpoint() + '/check-date/' + checkDate + '/create-payroll-report' ,
            drivers
        );
    }

}
