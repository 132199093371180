<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>

<div class="modal-body modal-lg">
    <div class="input-group mb-3">
        <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-default">Order</span>
        </div>
        <select class="form-control selectpicker" [(ngModel)]="selectedOrder" (change)="orderChanged($event)">
            <option *ngFor="let thisOrder of orders">{{thisOrder.id}}</option>
        </select>
    </div>

    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link" [class.active]="activeTab == 'Order'" (click)="setTab('Order')" id="order-tab"
               aria-controls="order" aria-selected="true">Order</a>
        </li>
        <li class="nav-item" *ngIf="auth.hasPermission('view orders')">
            <a class="nav-link" [class.active]="activeTab == 'Pickups'" (click)="setTab('Pickups')" id="pickups-tab"
               aria-controls="pickups" aria-selected="false">Pickups</a>
        </li>
        <li class="nav-item" *ngIf="auth.hasPermission('view orders')">
            <a class="nav-link" [class.active]="activeTab == 'Unloads'" (click)="setTab('Unloads')" id="unloads-tab"
               aria-controls="unloads" aria-selected="false">Unloads</a>
        </li>
        <li class="nav-item" *ngIf="auth.hasPermission('correct orders') && auditLog.entries">
            <a class="nav-link" [class.active]="activeTab == 'Audit Log'" (click)="setTab('Audit Log')" id="audit-log-tab"
               aria-controls="unloads" aria-selected="false">Audit Log</a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" [class.show]="activeTab == 'Order'" [class.active]="activeTab == 'Order'" id="order"
             role="tabpanel" aria-labelledby="order-tab">

            <div class="form-group">
                <label for="status">Status</label>
                <ng-select id="status" class="bootstrap" [(ngModel)]="statusId" [options]="statuses"
                           filterPlaceholder="Type to search..." aria-describedby="statusHelp" [disabled]="readonly"></ng-select>
                <small id="statusHelp" class="form-text text-muted">
                    <span>Select a status</span>
                </small>
                <div class="form-check">
                    <small>
                        <input class="form-check-input" type="checkbox" id="globalStatus" [(ngModel)]="globalStatus" [readonly]="readonly" />
                        <label class="form-check-label" for="globalStatus">Apply to all selected orders?</label>
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label for="priority">Priority</label>
                <ng-select id="priority" class="bootstrap" [(ngModel)]="priorityId" [options]="priorities" [disabled]="readonly" filterPlaceholder="Type to search..." aria-describedby="priorityHelp"></ng-select>
                <small id="priorityHelp" class="form-text text-muted">Select a priority</small>
            </div>
            <div class="form-group">
                <label for="driver">Driver</label>
                <ng-select id="driver" class="bootstrap" [(ngModel)]="order.driver_id" [options]="drivers" [disabled]="readonly" filterPlaceholder="Type to search..." aria-describedby="driversHelp"></ng-select>
                <small id="driversHelp" class="form-text text-muted">Select a driver.</small>
            </div>
            <div class="form-group">
                <label for="driver">Notes</label>
                <textarea type="text" id="notes" class="bootstrap form-control form-control-lg" [(ngModel)]="order.notes" [readonly]="readonly"></textarea>
                <small id="notesHelp" class="form-text text-muted">Order notes.</small>
            </div>
        </div>
        <div class="tab-pane fade" [class.show]="activeTab == 'Pickups'" [class.active]="activeTab == 'Pickups'"
             id="pickups" role="tabpanel" aria-labelledby="pickups-tab" *ngIf="auth.hasPermission('view orders')">
            <table class="table table-striped table-sm">
                <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>Operator</th>
                    <th>Lease/Terminal</th>
                    <th>Equipment</th>
                    <th>Driver</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let pickup of order.pickups; index as i">
                    <td>
                        <button *ngIf="pickup.pickup_data && auth.hasPermission('correct orders')" type="button" class="btn btn-outline-primary" [class.btn-outline-warning]="pickup.corrected_by" (click)="editPickup(i, pickup)" [disabled]="pickup.corrected_by"><i class="icon icon-pencil"></i></button>
                        <button *ngIf="auth.hasPermission('correct orders') && order.pickups.length > 1" type="button" class="btn btn-outline-danger" (click)="deletePickup(i, pickup)"><i class="icon icon-trash"></i></button>
                        <button *ngIf="auth.hasPermission('correct orders')" type="button" class="btn btn-outline-danger" [class.btn-outline-warning]="pickup.corrected_by" (click)="rejectPickup(i, pickup)"><i class="icon icon-exclamation"></i></button>
                        <button *ngIf="!pickup.corrected_by && pickup.pickup_data" type="button" class="btn btn-outline-primary"  (click)="printTicket(pickup.ticket_number)" [disabled]="pickup.corrected_by"><i class="icon icon-printer"></i></button>
                    </td>
                    <td class="ticket-number">{{pickup.ticket_number}}</td>
                    <td>{{pickup.operator_name}}</td>
                    <td>{{pickup.lease_name || pickup.terminal_name || pickup.refinery_name}}</td>
                    <td>{{pickup.equipment_name}}</td>
                    <td>{{pickup.driver_name}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="tab-pane fade" [class.show]="activeTab == 'Unloads'" [class.active]="activeTab == 'Unloads'"
             id="unload"
             role="tabpanel" aria-labelledby="unloads-tab" *ngIf="auth.hasPermission('view orders')">
            <table class="table table-striped table-sm">
                <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>Offload/Terminal</th>
                    <th>Driver</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let unload of order.unloads; index as i">
                    <td>
                        <button *ngIf="unload.unload_data && auth.hasPermission('correct orders')" type="button" class="btn btn-outline-primary" [class.btn-outline-warning]="unload.corrected_by" (click)="editUnload(i, unload)" [disabled]="unload.corrected_by"><i class="icon icon-pencil"></i></button>
                        <button *ngIf="!unload.corrected_by && unload.unload_data" type="button" class="btn btn-outline-primary"  (click)="printUnloadTicket(unload.ticket_number)" [disabled]="unload.corrected_by"><i class="icon icon-printer"></i></button>
                    </td>
                    <td class="ticket-number">{{unload.ticket_number}}</td>
                    <td>{{unload.offload_name || unload.terminal_name || unload.refinery_name}}</td>
                    <td>{{unload.driver_name}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="tab-pane fade" [class.show]="activeTab == 'Audit Log'" [class.active]="activeTab == 'Audit Log'"
             id="audit-log"
             role="tabpanel" aria-labelledby="audit-log-tab" *ngIf="auth.hasPermission('correct orders')">
            <table class="table table-sm">
                <thead>
                <tr class="text-secondary">
                    <th scope="col">User</th>
                    <th scope="col">Action(s)</th>
                    <th class="text-right" align="right" scope="col">Time</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let entry of auditLog.entries">
                    <th scope="row">
                        <span>{{entry.user.first_name}} {{entry.user.last_name}}</span><br />
                        <span class="badge badge-pill badge-secondary" *ngFor="let tag of entry.tag">{{tag.name}}: {{tag.value}}</span>
                    </th>
                    <td>
                        <pre class="m-b-0">{{entry.message}}</pre>
                    </td>
                    <td align="right" class="font-weight-light">{{entry.created_at_local}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="onCancel()">{{closeBtnName}}</button>
    <button type="button" class="btn btn-success" (click)="onConfirm()" [disabled]="saved">Save</button>
</div>
