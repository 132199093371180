import {TableGridColumn} from 'angular-table-grid/lib/interfaces/table-grid-column';
import { Injectable } from "@angular/core";

@Injectable()
export class NewOrderOptionsProvider {
    getTableGridColumns() {
        return <TableGridColumn[]>[
            {
                headerTitle: 'Status',
                fieldName: 'status_name'
            },
            {
                headerTitle: 'Order #',
                fieldName: 'id'
            },
            {
                headerTitle: 'Hauler',
                fieldName: 'hauler'
            },
            {
                headerTitle: 'Created By',
                fieldName: 'created_by.formatted_name'
            },
            {
                headerTitle: 'Create Date',
                fieldName: 'created_at_date'
            },
            {
                headerTitle: 'Start Date',
                fieldName: 'recurring_start_date',
                visible: false
            },
            {
                headerTitle: 'End Date',
                fieldName: 'recurring_end_date',
                visible: false
            },
            {
                headerTitle: 'Multiple',
                fieldName: 'multiple_orders',
                visible: false
            },
            {
                headerTitle: 'Driver',
                fieldName: 'driver_name',
                sortable: true
            },
            {
                headerTitle: 'Priority',
                fieldName: 'priority_name'
            },
            {
                headerTitle: 'Operator',
                fieldName: 'pickups.0.operator_name',
                sortable: true
            },
            {
                headerTitle: 'County',
                fieldName: 'pickups.0.lease.county',
                sortable: true
            },
            {
                headerTitle: 'Pickup',
                fieldName: 'pickups.0.lease_name',
                fieldFn: (row: any) => {
                    for (let i = 0; i < row.pickups.length; i++) {
                        if (!row.pickups[i].corrected_by) {
                            return row.pickups[i].lease_name;
                        }
                    }
                    return '';
                },
                sortable: true
            },
            {
                headerTitle: 'Unload',
                fieldName: 'unloads.0.offload_name',
                fieldFn: (row: any) => {
                    for (let i = 0; i < row.unloads.length; i++) {
                        if (!row.unloads[i].corrected_by) {
                            return row.unloads[i].offload_name;
                        }
                    }
                    return '';
                },
                sortable: true
            },
            {
                headerTitle: 'Equipment',
                fieldName: 'pickups.0.equipment_name',
                fieldFn: (row: any) => {
                    for (let i = 0; i < row.pickups.length; i++) {
                        if (!row.pickups[i].corrected_by) {
                            return row.pickups[i].equipment_name;
                        }
                    }
                    return '';
                }
            },
            {
                headerTitle: 'Corr Gravity Avg',
                fieldName: 'pickups.0.pickup_data.avg_corrected_gravity',
                fieldFn: (row: any) => {
                    for (let i = 0; i < row.pickups.length; i++) {
                        if (!row.pickups[i].corrected_by) {
                            return row.pickups[i].avg_corrected_gravity;
                        }
                    }
                    return '';
                },
                sortable: false
            },
            {
                headerTitle: 'Product',
                fieldName: 'product_code'
            },
            {
                headerTitle: 'Type',
                fieldName: 'load_type_name'
            },
            {
                headerTitle: 'Notes',
                fieldName: 'notes'
            },
            {
                headerTitle: 'Origin',
                fieldName: 'origin_name'
            },
        ];
    }
}
