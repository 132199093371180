import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeaseRestrictionComponent} from './components/lease-restriction/lease-restriction.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        LeaseRestrictionComponent
    ],
    exports: [
        LeaseRestrictionComponent
    ]
})
export class LeaseRestrictionsModule {
}
