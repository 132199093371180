<div class="card">
    <div class="card-body">
        <div *ngIf="uploadError" class="field-error">
            {{ uploadError }}
        </div>
        <form #form="ngForm" (ngSubmit)="onFileUpload()">
            <div class="form-group">
                <div class="row">
                    <div class="custom-file col-6 ml-3">
                        <input type="file" class="custom-file-input" id="schedule" name="schedule" (change)="onFileSelected($event)">
                        <label class="custom-file-label" for="schedule" #labelImport>Choose file</label>
                    </div>
                    <div class="col-5">
                        <button class="btn btn-primary" type="submit" disabled #uploadButton>{{ uploadResponse ? uploadResponse + '%' : 'Upload' }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
