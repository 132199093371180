import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {Subject} from 'rxjs';
import {OffloadsService} from '../../services/api/offloads.service';
import {Offload} from '../../models/offload.model';
import {IOption} from 'ng-select';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StatusesService} from '../../services/api/statuses.service';
import {DriverService} from 'selenium-webdriver/remote';
import {DriversService} from '../../services/api/drivers.service';
import {OrderObj} from '../../models/order.model';
import {Pickup} from '../../models/pickup.model';
import {Lease} from '../../models/Lease.model';
import {LeasesService} from '../../services/api/leases.service';
import {Equipment} from '../../models/equipment.model';
import * as moment from 'moment';
import {RejectReason} from '../../models/reject-reason.model';
import {RejectReasonsService} from '../../services/api/rejectreasons.service';

@Component({
    selector: 'app-modal-component',
    templateUrl: './reject-pickup-modal.component.html',
    styleUrls: ['./reject-pickup-modal.component.scss']
})
export class RejectPickupModalComponent implements OnInit {
    title: string;
    closeBtnName: string;
    list: any[] = [];
    active: boolean;
    onClose: Subject<boolean>;
    data: any[] = [];
    template = '';
    pickup: Pickup;
    id = 0;
    reason = '';
    notes = '';
    reject: RejectReason = {
        id: 0,
        reason: '',
        notes: '',
        updated_at: '',
        created_at: ''
    };
    rejectReasons: Array<IOption> = [];
    defaultRejectReason = '1';
    rejectReasonsSrc;

    constructor(public bsModalRef: BsModalRef,
                public rejectService: RejectReasonsService,
                private changeDetector: ChangeDetectorRef
    ) {
        this.onClose = new Subject();
        this.rejectService.getRejectReasons().subscribe(data => {
            this.rejectReasonsSrc = data;
            const count = data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: data[i].id.toString(),
                    label: data[i].reason,
                    disabled: false
                });
            }
            this.rejectReasons = options;
        });
    }

    async ngOnInit() {
        // Yes this is on purpose to clone the object without reference.
    }

    selected(event) {
        const filtered = this.rejectReasonsSrc.filter((rejectReason: RejectReason) => {
            return rejectReason.id.toString() === event.value;
        });
        if (filtered.length > 0) {
            this.reject.id = filtered[0].id;
            this.reject.reason = filtered[0].reason;
            this.reject.updated_at = filtered[0].updated_at;
            this.reject.created_at = filtered[0].created_at;
        }
    }

    public onConfirm(): void {
        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.active = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this.bsModalRef.hide();
    }
}
