import { Injectable } from "@angular/core";
@Injectable()
export class DispatchPickupDataOptionsProvider {

    static getPickupColumns() {
        return [
            {
                headerTitle: 'Ticket #',
                fieldName: 'ticket_number'
            },
            {
                headerTitle: 'Ext Ticket #',
                fieldName: 'external_ticket_number'
            },
            {
                headerTitle: 'Lease',
                fieldName: 'lease_name'
            }, {
                headerTitle: 'Equipment',
                fieldName: 'equipment_name'
            },
            {
                headerTitle: 'BBL',
                fieldName: 'pickup_data.gross_barrels'
            },
            {
                headerTitle: 'Gauge',
                fieldName: 'pickup_data.gauge_reading'
            },
            {
                headerTitle: 'Corr. Gravity',
                fieldName: 'pickup_data.corrgravity'
            },
            {
                headerTitle: 'BS&W',
                fieldName: 'pickup_data.bsw'
            },
            {
                headerTitle: 'OBS Temp',
                fieldName: 'pickup_data.obstemp'
            },
            {
                headerTitle: 'OBS Grav',
                fieldName: 'pickup_data.obsgravity'
            },
            {
                headerTitle: 'Top Feet',
                fieldName: 'pickup_data.tank_top_feet'
            },
            {
                headerTitle: 'Top Inches',
                fieldName: 'pickup_data.tank_top_inches'
            },
            {
                headerTitle: 'Top Qrt Inches',
                fieldName: 'pickup_data.tank_top_quarter_inches'
            },
            {
                headerTitle: 'Bottom Feet',
                fieldName: 'pickup_data.tank_bottom_feet'
            },
            {
                headerTitle: 'Bottom Inches',
                fieldName: 'pickup_data.tank_bottom_inches'
            },
            {
                headerTitle: 'Bottom Qrt Inches',
                fieldName: 'pickup_data.tank_bottom_quarter_inches'
            },
            {
                headerTitle: 'M. Open',
                fieldName: 'pickup_data.meter_open'
            },
            {
                headerTitle: 'M. Close',
                fieldName: 'pickup_data.meter_close'
            },
            {
                headerTitle: 'Seal Open',
                fieldName: 'pickup_data.seal_open'
            },
            {
                headerTitle: 'Seal Close',
                fieldName: 'pickup_data.seal_close'
            },
            {
                headerTitle: 'Coordinates',
                fieldName: 'pickup_data.location',
                fieldFn: (row: any) => {
                    return row.pickup_data.latitude ? row.pickup_data.latitude + ', ' + row.pickup_data.longitude : '';
                }
            },
            {
                headerTitle: 'Notes',
                fieldName: 'pickup_data.notes'
            },
            /* BOL image currently disabled on the tablet
            {
                headerTitle: 'Image',
                fieldName: 'pickup_data.image',
                component: BolImageComponent
            },
            */
            {
                headerTitle: 'Corrected By',
                fieldName: 'corrected_by'
            },
            {
                headerTitle: 'Corrected At',
                fieldName: 'corrected_at_formatted'
            },
            {
                headerTitle: 'Start',
                fieldName: 'pickup_data.load_start_formatted'
            },
            {
                headerTitle: 'End',
                fieldName: 'pickup_data.load_end_formatted'
            }
        ]
    }
}
