<div class="card payroll-schedule">
    <div class="card-header"><span>Payroll Schedule</span></div>
    <div class="card-body">
        <div class="row">
            <div class="form-group col-10">
                <app-payroll-schedule requiredFileType=".xlsx"></app-payroll-schedule>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header"><span><i class="fa fa-cog fa-lg" aria-hidden="true"></i> Payroll Settings</span></div>

    <div class="card-body">
        <div class="row"  style="text-align:center">
            <div class="card col-xs-1 col-md-6 pc-card">


                <div class="card-body">
                    <h3 class="display-4"><a href="">Pay Codes</a> </h3>
                    <small class="form-text text-muted">Set the default Pay Code for all drivers</small>
                    <hr>
                    <form novalidate [formGroup]="payCodeForm" (ngSubmit)="submit()">

<!--                        -->
<!--                        <div class="form-group row mb-2">-->
<!--                            <label class="col-md-4 col-form-label" for="code">Code</label>-->
<!--                            <div class="col-md-8">-->
<!--                                <input type="text" id="code" class="form-control" placeholder="(required)"-->
<!--                                    formControlName="code"-->
<!--                                    [ngClass]="{'is-invalid': payCodeFormErrors.code }" />-->
<!--                                <span class="invalid-feedback">-->
<!--                                    {{ payCodeFormErrors.code }}-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->



                        <div class="form-group row mb-2">
                            <label class="col-md-4 col-form-label" for="code" >Pay Code</label>
                            <div class="col-md-8">
                                <ng-select
                                        id="code"
                                        placeholder="(required)"
                                        formControlName="code"
                                        [options]="payCodes"
                                        highlightColor="#9575cd"
                                        highlightTextColor="#fff"
                                        [ngClass]="{'is-invalid': payCodeFormErrors.code }" >
                                </ng-select>
                                <span class="invalid-feedback">
                                    {{ payCodeFormErrors.code }}
                                </span>
                            </div>
                        </div>



                        <div class="form-group row mb-2">
                            <label for="description" class="col-md-4 col-form-label">Description</label>
                            <div class="col-md-8">
                                <input id="description" type="text" class="form-control" placeholder="(required)"
                                       formControlName="description"
                                       [ngClass]="{'is-invalid': payCodeFormErrors.description }" />
                                <span class="invalid-feedback">
                                    {{ payCodeFormErrors.description }}
                                </span>
                            </div>
                        </div>



                        <div class="form-group row mb-2">
                            <label class="col-md-4 col-form-label" for="rate">Rate</label>
                            <div class="col-md-8">
                                <input id="rate" type="text" class="form-control" placeholder="(required)"
                                       formControlName="rate"
                                       [ngClass]="{'is-invalid': payCodeFormErrors.rate }" />
                                <span class="invalid-feedback">
                                    {{ payCodeFormErrors.rate }}
                                </span>
                            </div>
                        </div>


                        <div class="form-group row mb-2">
                            <div class="col-md-8">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input class="form-check-input"
                                            id="active"
                                            type="checkbox"
                                            formControlName="isActive"
                                            checked> Active
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mb-2">
                            <div class="offset-md-2 col-md-4">
                                <button class="btn btn-primary mr-3"
                                    type="submit"
                                    style="width: 80px"
                                    [title]="payCodeForm.valid ? 'Save your Data' : 'Disabled until the form data is valid'"
                                    [disabled]="!payCodeForm.valid">Save
                                </button>

                                <button class="btn btn-outline-secondary"
                                    type="button"
                                    (click)="populateTestData()">Test Data
                                </button>
                            </div>
                        </div>
                    </form>


                    <br><strong>Form State</strong>
                    <br>Dirty: {{ payCodeForm.dirty }}
                    <br>Touched: {{ payCodeForm.touched }}
                    <br>Valid: {{ payCodeForm.valid }}
                    <br>Value: {{ payCodeForm.value | json }}

                </div>
            </div>
            <div class="card col-xs-1 col-md-6 da-card">
                <div class="card-body da-form">
                    <h3 class="display-4"><a href="">Driver Allowances</a></h3>
                    <small class="form-text text-muted">Modify the default Pay Code for a specific driver</small>
                    <hr>

                    <form novalidate [formGroup]="driverAllowancesForm" (ngSubmit)="submit()">

                        <div class="form-group row mb-2">
                            <label class="col-md-4 col-form-label" for="driver" >Driver</label>
                            <div class="col-md-8">
                                <ng-select
                                        id="driver"
                                        placeholder="(required)"
                                        formControlName="driver"
                                        [options]="drivers"
                                        highlightColor="#9575cd"
                                        highlightTextColor="#fff"
                                        [ngClass]="{'is-invalid': driverAllowancesFormErrors.driver }" >
                                </ng-select>
                                <span class="invalid-feedback">
                                    {{ driverAllowancesFormErrors.driver }}
                                </span>
                            </div>
                        </div>


                        <div class="form-group row mb-2">
                            <label class="col-md-4 col-form-label" for="code" >Pay Code</label>
                            <div class="col-md-8">
                                <ng-select
                                        id="code"
                                        placeholder="(required)"
                                        formControlName="code"
                                        [options]="payCodes"
                                        highlightColor="#9575cd"
                                        highlightTextColor="#fff"
                                        [ngClass]="{'is-invalid': driverAllowancesFormErrors.code }" >
                                </ng-select>
                                <span class="invalid-feedback">
                                    {{ driverAllowancesFormErrors.code }}
                                </span>
                            </div>
                        </div>


<!--                        <div class="form-group row mb-2">-->
<!--                            <label class="col-md-4 col-form-label" for="code">Code</label>-->
<!--                            <div class="col-md-8">-->
<!--                                <input type="text" id="code" class="form-control" placeholder="(required)"-->
<!--                                       formControlName="code"-->
<!--                                       [ngClass]="{'is-invalid': driverAllowancesFormErrors.code }" />-->
<!--                                <span class="invalid-feedback">-->
<!--                                    {{ driverAllowancesFormErrors.code }}-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->




                        <div class="form-group row mb-2">
                            <label class="col-md-4 col-form-label" for="amount">Amount</label>
                            <div class="col-md-8">
                                <input id="amount" type="text" class="form-control" placeholder="(required)"
                                       formControlName="amount"
                                       [ngClass]="{'is-invalid': driverAllowancesFormErrors.amount }" />
                                <span class="invalid-feedback">
                                    {{ driverAllowancesFormErrors.amount }}
                                </span>
                            </div>
                        </div>


                        <div class="form-group row mb-2">
                            <div class="col-md-8">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input class="form-check-input"
                                               id="active"
                                               type="checkbox"
                                               formControlName="isActive"
                                               checked> Active
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mb-2">
                            <div class="offset-md-2 col-md-4">
                                <button class="btn btn-primary mr-3"
                                        type="submit"
                                        style="width: 80px"
                                        [title]="driverAllowancesForm.valid ? 'Save your Data' : 'Disabled until the form data is valid'"
                                        [disabled]="!driverAllowancesForm.valid">Save
                                </button>

                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        (click)="populateDriverAllowancesTestData()">Test Data
                                </button>
                            </div>
                        </div>
                    </form>

                    <br><strong>Form State</strong>
                    <br>Dirty: {{ driverAllowancesForm.dirty }}
                    <br>Touched: {{ driverAllowancesForm.touched }}
                    <br>Valid: {{ driverAllowancesForm.valid }}
                    <br>Value: {{ driverAllowancesForm.value | json }}



                </div>
            </div>
        </div>
    </div>
</div>


<div class="card">
    <div class="card-header"><span><i class="fa fa-wrench fa-lg" aria-hidden="true"></i> Payroll Cycle</span></div>

    <div class="card-body">
        <div class="row">

            <div class="card col-12">
                <div class="card-body">
                    <app-pay-codes></app-pay-codes>
                </div>
            </div>

        </div>
    </div>
</div>
