import {TableGridColumn} from 'angular-table-grid';
import { Injectable } from "@angular/core";

@Injectable()
export class OrderLeaseHistoryOptionsProvider {
    getTableGridColumns() {
        return <TableGridColumn[]>[
            {
                headerTitle: 'Status',
                fieldName: 'status_name'
            },
            {
                headerTitle: 'Order #',
                fieldName: 'id',
                sortable: true
            },
            {
                headerTitle: 'Create Date',
                fieldName: 'created_at_date',
                sortable: true
            },
            {
                headerTitle: 'Dispatch Date',
                fieldName: 'dispatch_date',
                sortable: false
            },
            {
                headerTitle: 'Dispatcher',
                fieldName: 'dispatched_by.formatted_name'
            },
            {
                headerTitle: 'Driver',
                fieldName: 'driver_name'
            },
            {
                headerTitle: 'Priority',
                fieldName: 'priority_name'
            },
            {
                headerTitle: 'Pickup',
                fieldName: 'pickups.0.lease_name'
            },
            {
                headerTitle: 'Equipment',
                fieldName: 'pickups.0.equipment_name'
            },
            {
                headerTitle: 'Unload',
                fieldName: 'unloads.0.offload_name',
                sortable: true
            },
            {
                headerTitle: 'Product',
                fieldName: 'product_code'
            },
            {
                headerTitle: 'BBLs',
                fieldName: 'gross_barrels'
            }
        ];
    }
}
