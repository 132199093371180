import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';


@Injectable()
export class DriversService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/driver');
    }

    getAllCoordinatesEndpoint(): string {
        return this.getEndpoint() + '/getAllCoordinates';
    }

    getAllCoordinates(): Observable<any> {
        return this.http.get<any>(this.getEndpoint() + '/getAllCoordinates');
    }

    getDrivers(): Observable<any> {
        return this.http.get<any>(this.getEndpoint());
    }

    /**
     * month (0-11)
     * day (1-31)
     * @return formatted string '2021-02-28'
     * @param dateNeedsFormatting
     */
    formatDate(dateNeedsFormatting: Date): string {
        const month = this.pad2(dateNeedsFormatting.getMonth() + 1);
        const day = this.pad2(dateNeedsFormatting.getDate());
        const year = dateNeedsFormatting.getFullYear();

        return year + '-' + month + '-' + day;
    }

    pad2(n) {
        return (n < 10 ? '0' : '') + n;
    }

}
