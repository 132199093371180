<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>

<div class="modal-body modal-lg">

    <div class="col-md-12">
        <div class="form-group" *ngIf="changePickup">
            <div class="col-md-12">
                <label class="col-form-label ">Lease</label>
                <ng-select #leaseSelect class="bootstrap" placeholder="Choose a Lease"
                           [options]="leases" [(ngModel)]="lease" (selected)="onLeaseSelect($event)"
                           filterPlaceholder="Type to search..." (filterInputChanged)="leaseSearch($event)">
                </ng-select>
            </div>
            <div class="col-md-12">
                <label class="col-form-label ">Equipment</label>
                <ng-select #leaseSelect class="bootstrap" placeholder="Choose equipment"
                           [options]="equipment" [(ngModel)]="selectedEquipmentStr" (selected)="onEquipmentSelect($event)"
                           filterPlaceholder="Type to search...">
                </ng-select>
            </div>
        </div>
        <div class="row">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>Ticket</th>
                    <th>Lease/Terminal</th>
                    <th>Equipment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <button class="btn btn-info" *ngIf="!changePickup" (click)="changePickup = !changePickup">Update</button>
                        <button class="btn btn-success" *ngIf="changePickup" (click)="changePickup = !changePickup">Done</button>
                    </td>
                    <td class="ticket-number">{{pickup.ticket_number}}</td>
                    <td *ngIf="pickup.lease_name">{{pickup.lease_name}}</td>
                    <td *ngIf="pickup.terminal_name">{{pickup.terminal_name}}</td>
                    <td>{{pickup.equipment_name}} ({{selectedEquipment.capacity}}bbls)</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label class="col-form-label ">Driver</label>
            <ng-select #driverSelect class="bootstrap" placeholder="Select driver"
                       [options]="drivers" [(ngModel)]="selectedDriver" (selected)="onDriverSelect($event)"
                       filterPlaceholder="Type to search...">
            </ng-select>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>External Ticket Number</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.external_ticket_number"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Odometer</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.odometer"/>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>Obs Temp</label>
                <input type="text" class="form-control form-control-sm" (keyup)="_calculateGravity()" [(ngModel)]="pickup.pickup_data.obstemp"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Obs Gravity</label>
                <input type="text" class="form-control form-control-sm" (keyup)="_calculateGravity()" [(ngModel)]="pickup.pickup_data.obsgravity"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Corrected Gravity</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.corrgravity"/>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="equipmentType == 'meter'">
        <div class="col-md-4">
            <div class="form-group">
                <label>Meter Open</label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.meter_open" (input)="calculateGrossBarrels()"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Meter Close</label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.meter_close" (input)="calculateGrossBarrels()"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Meter Factor</label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.meter_factor" (input)="calculateGrossBarrels()"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Avg Temp</label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.avg_temp"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>BS&W%</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.bsw"/>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="equipmentType == 'tank'">
        <div class="col-md-4">
            <div class="form-group">
                <label>Tank Top Feet</label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.tank_top_feet" (input)="calculateGrossBarrels()"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Tank Top Inches</label>
                <input type="number" max=11 class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.tank_top_inches" (input)="calculateGrossBarrels()"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Tank Top 1/4 Inches</label>
                <input type="number" max=3 class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.tank_top_quarter_inches" (input)="calculateGrossBarrels()"/>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="equipmentType == 'tank'">
        <div class="col-md-4">
            <div class="form-group">
                <label>Tank Bottom Feet</label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.tank_bottom_feet" (input)="calculateGrossBarrels()"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Tank Bottom Inches</label>
                <input type="number" max=11 class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.tank_bottom_inches" (input)="calculateGrossBarrels()"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Tank Bottom 1/4 Inches</label>
                <input type="number" max=3 class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.tank_bottom_quarter_inches" (input)="calculateGrossBarrels()"/>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="equipmentType == 'tank'">
        <div class="col-md-4">
            <div class="form-group">
                <label>Tank Temp Top</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.temp_top"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Tank Temp Bottom</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.temp_bottom"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>BS&W%</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.bsw"/>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>Seal Open</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.seal_open"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Seal Close</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.seal_close"/>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>Gauge Reading</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="pickup.pickup_data.gauge_reading"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Load Start</label>
                <input type="text" class="form-control form-control-sm"  [(ngModel)]="this.pickup.pickup_data.load_start" bsDatepicker/>
                <timepicker [minuteStep]="1" [(ngModel)]="this.pickup.pickup_data.load_start"></timepicker>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Load End</label>
                <input type="text" class="form-control form-control-sm"  [(ngModel)]="this.pickup.pickup_data.load_end" bsDatepicker/>
                <timepicker [minuteStep]="1" [(ngModel)]="this.pickup.pickup_data.load_end"></timepicker>
            </div>
        </div>
    </div>

    <div class="row" class="p-1 mb-2 bg-light text-black">
        <div class="col-md-12">
            <div class="form-group">
                <label>Driver Notes:</label>
                <textarea  class="form-control form-control-lg" [(ngModel)]="pickup.pickup_data.notes"></textarea>
            </div>
        </div>
    </div>

    <div class="row" class="p-1 mb-2 bg-primary text-white">
        <div class="col-md-12">
            <div class="form-group">
                <label>Gross Barrels</label>
                <input readonly type="text" class="form-control form-control-lg" [(ngModel)]="pickup.pickup_data.gross_barrels"/>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="onCancel()">{{closeBtnName}}</button>
    <button type="button" class="btn btn-success" (click)="onConfirm()">Save</button>
</div>

