import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {OrderResponse, OrdersService} from 'app/services/api/orders.service';
import {NewOrderOptionsProvider} from '../../providers/new-order-options.provider';
import {DriversService} from '../../services/api/drivers.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {ModalContentComponent} from '../modal-content/modal-content.component';
import {OrderObj} from '../../models/order.model';
import {TableGridComponent, TableGridFilters, TableGridOptions} from 'angular-table-grid';
import {TableGridRowDataRequest} from 'angular-table-grid';
import {map} from 'rxjs/operators';
import {MasterDetailComponent} from '../master-detail/master-detail.component';
import {ngxCsv} from 'ngx-csv';
import * as moment from 'moment';


@Component({
    selector: 'app-dispatch-new-order',
    templateUrl: './dispatch-new-order.component.html',
    styleUrls: ['./dispatch-new-order.component.scss'],
})
export class DispatchNewOrderComponent implements OnInit {
    public isHidden;
    public gridApi;
    bsModalRef: BsModalRef;
    gridOptions: TableGridOptions;
    orders: OrderObj[];
    orderCount;
    searchString: string;
    showRecurring = false;
    advancedSearch = false;
    objectKeys = Object.keys;
    searchParams = {
        order: {
            label: 'Order #',
            value: '',
            type: 'text'
        },
        ticket: {
            label: 'Ticket #',
            value: '',
            type: 'text'
        },
        operator: {
            label: 'Operator',
            value: '',
            type: 'text'
        },
        lease: {
            label: 'Lease',
            value: '',
            type: 'text'
        },
        equipment: {
            label: 'Equipment',
            value: '',
            type: 'text'
        },
        offload: {
            label: 'Offload',
            value: '',
            type: 'text'
        },
        terminal: {
            label: 'Terminal',
            value: '',
            type: 'text'
        },
        driver: {
            label: 'Driver',
            value: '',
            type: 'text'
        },
        status: {
            label: 'Status',
            value: '',
            type: 'select',
            options: [
                {label: 'NEW', value: 'NEW'},
                {label: 'Hold', value: 'HOLD'},
                {label: 'Picked Up', value: 'RECALL'}
            ]
        },
        'created-at': {
            label: 'Created At',
            value: '',
            type: 'date'
        },
        'created-by': {
            label: 'Created By',
            value: '',
            type: 'text'
        },
        'dispatched-at': {
            label: 'Dispatched At',
            value: '',
            type: 'date'
        },
        'dispatched-by': {
            label: 'Dispatched By',
            value: '',
            type: 'text'
        },
        priority: {
            label: 'Priority',
            value: '',
            type: 'text'
        },
        county: {
            label: 'County',
            value: '',
            type: 'text'
        },
        region: {
            label: 'Region',
            value: '',
            type: 'text'
        },
    };
    gridLoading = false;
    @Output() dispatchEvent = new EventEmitter<boolean>();


    static parseBsDateRange(event) {
        if (event) {
            if (typeof event === 'string') {
                event = event.split(' - ');
            }
            const start = event[0];
            const end = event[1];
            return moment(start).format('M/D/Y') + ' - ' + moment(end).format('M/D/Y');
        }
        return '';
    }

    constructor(
        private newOrderOptionsProvider: NewOrderOptionsProvider,
        private ordersService: OrdersService,
        private driverService: DriversService,
        private router: Router,
        private modalService: BsModalService
    ) {

        this.gridOptions = <TableGridOptions>{
            columns: this.newOrderOptionsProvider.getTableGridColumns(),
            getRowData: (rowDataRequest: TableGridRowDataRequest) => {
                this.gridLoading = true;
                const page = rowDataRequest.pagination.page;
                const perPage = rowDataRequest.pagination.perPage;
                const start = (page - 1) * perPage;
                const end = page * perPage;
                let sortStr = rowDataRequest.params.get('_sort');
                if (sortStr) {
                    sortStr = sortStr.replace(new RegExp('pickups.0.', 'g'), '');
                    sortStr = sortStr.replace(new RegExp('unloads.0.', 'g'), '');
                }
                const orderStr = rowDataRequest.params.get('_order');
                return this.ordersService.getNewOrders(start, end, this.searchString, this.showRecurring, sortStr, orderStr).pipe(
                    map((orders: OrderResponse) => {
                        this.orderCount = orders.rowCount;
                        orders.data.map((row) => {
                            const filterPickups = row.pickups.filter((thisPickup) => {
                                return !thisPickup.corrected_at;
                            });
                            if (filterPickups.length > 1) {
                                row.load_type_name = 'Split';
                            }
                            if (row.ts_call_date) {
                                row.ts_call_date_formatted = moment.utc(row.ts_call_date).local().format('M/D/YY h:mm a');
                            }

                            if (row.created_at) {
                                row.created_at_date = moment.utc(row.created_at).local().format('M/D/YY h:mm a');
                            }
                        });
                        this.orders = orders.data;
                        this.gridLoading = false;
                        return {
                            rows: orders.data,
                            totalRows: orders.rowCount
                        };
                    })
                );
            },
            getCellStyles: (value, columnDef) => {
                if (columnDef.headerTitle === 'Type') {
                    const styles = {};
                    if (value === 'Split') {
                        styles['background-color'] = 'rgba(255, 204, 0, 0.5)';
                    } else if (value === 'Full') {
                        styles['background-color'] = 'rgba(3, 171, 122, 0.5)';
                    }
                    return styles;
                }
                if (columnDef.headerTitle === 'Priority') {
                    const styles = {};
                    styles['background-color'] = 'rgba(3, 171, 122, 0.5)';
                    if (value === 'ASAP') {
                        styles['background-color'] = 'rgba(255, 0, 0, 0.5)';
                    } else if (value === 'RECALL') {
                        styles['background-color'] = 'rgba(255, 204, 0, 0.5)';
                    }
                    return styles;
                }

                if (columnDef.headerTitle === 'Hauler' && value !== 'Eastex') {
                    const styles = {};
                    styles['background-color'] = 'rgba(255, 204, 0, 0.5)';
                    return styles;
                }
                return {};
            },
            getDetails: (node) => {
                return node;
            },
            enableDetails: true,
            detailComponent: MasterDetailComponent,
            perPage: 5,
            page: 1,
            multiSelectOn: true
        }
    }

    toggleRecurringOrders() {
        this.showRecurring = !this.showRecurring;
        this.gridApi.refresh();
        if (this.showRecurring) {
            this.gridOptions.columns[6].visible = true;
            this.gridOptions.columns[7].visible = true;
            this.gridOptions.columns[8].visible = true;
        } else {
            this.gridOptions.columns[6].visible = false;
            this.gridOptions.columns[7].visible = false;
            this.gridOptions.columns[8].visible = false;
        }
    }

    onGridReady(gridApi: TableGridComponent) {
        this.gridApi = gridApi;
        this.gridApi.rowDataRequest.filters[0] = <TableGridFilters> {
            q: ''
        };
    }

    ngOnInit() {
    }

    showHide() {
        this.isHidden = !this.isHidden;
    }

    selectAllRows() {
        // this.gridOptions.api.selectAll();
    }

    deselectAllRows() {
        // this.gridOptions.api.deselectAll();
    }

    async onDispatch() {
        const selectedRows = this.gridApi.getSelectedRows();
        const rowCount = selectedRows.length;
        if (rowCount > 0) {
            let initialState = {};
            if (rowCount === 1) {
                const order: OrderObj = selectedRows[0];
                initialState = {
                    offloadloc: order.unloads[0].offload_id.toString(),
                    status: order.status_id.toString(),
                    priority: order.priority_id.toString(),
                    dispatch_date: new Date(),
                    notes: order.notes,
                    multipleOrders: order.multiple_orders,
                    driver: (typeof order.pickups[0].driver_id !== 'undefined' && order.pickups[0].driver_id)
                        ? order.pickups[0].driver_id.toString() : ''
                };
            } else {
                initialState = {dispatch_date: new Date()};
            }

            this.bsModalRef = this.modalService.show(ModalContentComponent, {initialState: initialState});
            this.bsModalRef.content.closeBtnName = 'Close';
            this.bsModalRef.content.title = 'Edit ' + rowCount + ' job(s)';
            this.bsModalRef.content.template = '';
            if (typeof selectedRows[0] !== 'undefined' && typeof selectedRows[0].offload_id !== 'undefined') {
                this.bsModalRef.content.defaultOffload = selectedRows[0].offload_id.toString();
            }
            this.bsModalRef.content.onClose.subscribe(result => {
                if (result === true) {
                    // They pressed submit
                    const statusId = this.bsModalRef.content.status;
                    const statuses = this.bsModalRef.content.statuses;
                    const status = statuses.filter((statusItem) => {
                        return statusItem.value === statusId;
                    });
                    const options = {
                        'offload': this.bsModalRef.content.offloadloc,
                        'status': this.bsModalRef.content.status,
                        'status_name': (typeof status[0] !== 'undefined') ? status[0].label : '',
                        'driver': this.bsModalRef.content.driver,
                        'dispatch_date': this.bsModalRef.content.dispatch_date,
                        'notes': this.bsModalRef.content.notes,
                        'priority': this.bsModalRef.content.priority,
                        'multiple_orders': this.bsModalRef.content.multipleOrders,
                    };
                    this.dispatchJobs(selectedRows, options);
                } else if (result === false) {
                    // They pressed No
                } else {
                    // Modal was just hidden
                }
            });
        }
    }

    async dispatchJobs(jobs, options) {
        options.jobs = jobs;
        // this.gridOptions.api.showLoadingOverlay();
        await this.dispatchJob(options);
        for (let i = 0; i < jobs.length; i++) {
            jobs[i].status_name = options.status_name;
        }
        this.dispatchEvent.emit(true);
        this.gridApi.refresh();
    }

    async dispatchJob(options) {
        await this.ordersService.dispatch(options);
    }

    bsDateRangeChange(event, key) {
        if (event) {
            this.searchParams[key].value = DispatchNewOrderComponent.parseBsDateRange(event);
            this.onSearch();
        }
    }

    onSearch() {
        if (this.advancedSearch) {
            let query = '';
            for (const param in this.searchParams) {
                if (this.searchParams[param].value.length > 0) {
                    const dateFields = ['created-at', 'load-start', 'load-end', 'dispatched-at'];
                    if (dateFields.indexOf(param) !== -1) {
                        query += param + ':' + DispatchNewOrderComponent.parseBsDateRange(this.searchParams[param].value) + ' ';
                    } else {
                        query += param + ':' + this.searchParams[param].value + ' ';
                    }
                }
            }
            this.searchString = query;
        } else {
            const regex = /([a-z\-]+:[a-z0-9\-,\s\/]+)(?![a-z\-:])/gi;
            const matches = this.searchString.match(regex);
            if (matches) {
                const count = matches.length;
                if (count > 0) {
                    for (let i = 0; i < count; i++) {
                        const parts = matches[i].split(':');
                        const dateFields = ['created-at', 'load-start', 'load-end', 'dispatched-at'];
                        if (dateFields.indexOf(parts[0]) !== -1) {
                            this.searchParams[parts[0]].value = DispatchNewOrderComponent.parseBsDateRange(parts[1]);
                        } else {
                            this.searchParams[parts[0]].value = parts[1];
                        }
                    }
                }
            }
        }
        this.gridApi.rowDataRequest.filters[0] = <TableGridFilters> {
            q: this.searchString
        };
        this.gridApi.firstPage();
        this.gridApi.refresh();
    }

    exportCsv() {
        const data = [];
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            useBom: true,
            noDownload: false,
            headers: [
                'Status',
                'Order #',
                'Created By',
                'Create Date',
                'Priority',
                'Load Type',
                'Product',
                'Operator',
                'Pickup',
                'Equipment',
                'Offload Name',
                'Offload Number',
                'Notes'
            ]
        };
        for (const i in this.orders) {
            if (typeof this.orders[i] !== 'undefined') {
                const order: OrderObj = this.orders[i];

                data.push({
                    status: order.status_name,
                    id: order.id,
                    'created by': order.created_by.first_name,
                    'created at': order.created_at_date,
                    priority: order.priority_name,
                    'load type': order.load_type_name,
                    product: order.product_code,
                    operator: order.pickups[0].operator_name,
                    pickup: order.pickups[0].lease_name,
                    equipment: order.pickups[0].equipment_name,
                    'offload name': order.unloads[0].offload_name,
                    'offload number': order.unloads[0].offload_number,
                    notes: order.notes
                });
            }
        }
        const csv = new ngxCsv(data, 'New Orders', options);
    }
}

