import {Component, OnInit, ViewChild } from '@angular/core';
import {PayCode} from '../../models/pay-code.model';

import {PayCodeResponse, PayrollService} from '../../services/api/payroll.service';
import {PayrollReportModalComponent} from '../payroll-report-modal/payroll-report-modal.component';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-pay-codes',
    templateUrl: './pay-codes.component.html',
    styleUrls: ['./pay-codes.component.scss']
})
export class PayCodesComponent implements OnInit {
    bsModalRef?: BsModalRef;

    activePayCodes: PayCode[];
    checkDate: string = null;


    @ViewChild('selectCheckDateField') selectCheckDateField;
    public selectedOption: string;     // index of selected option
    public selectedCheckDate: string;  // ngModel
    checkDates = []; // check date dropdown
    duration: string;

    constructor(
        private modalService: BsModalService,
        private payrollService: PayrollService
    ) {}

    ngOnInit(): void {
        this.payrollService.getActivePayCodes()
            .subscribe(
                (response: PayCodeResponse) => {
                    this.activePayCodes = response.data;
                }
            );

        this.loadCheckDates();
    }

    /**
     * Fetch a list of Check Dates and populate dropdown control
     * This list allows a user to switch to a different payroll period
     */
    loadCheckDates() {
        this.payrollService.getAllCheckDates()
            .subscribe(
                response => {
                    const options = [];
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].should_be_selected) {
                            this.selectedOption = i.toString();
                        }

                        options.push({
                            value: response.data[i].value,
                            label: response.data[i].label,
                            disabled: false
                        });
                    }
                    this.checkDates = options;

                    // checkDate is passed to the Input variable checkDate on the pay-earning-type component
                    this.checkDate = this.checkDates[this.selectedOption].value;
                    this.selectCheckDateField.select(this.checkDates[this.selectedOption].value);
                },
                err => {
                    console.log(err);
                }
            );
    }

    /**
     *
     * @param selectedCheckDate
     */
    checkDateChanged(selectedCheckDate: string) {
        this.checkDate = selectedCheckDate;
        this.payrollService.getPayrollPeriodDuration(selectedCheckDate)
            .subscribe(
                response => {
                    this.duration = response.startDate + ' - ' + response.endDate;
                },
                err => {
                    console.log(err);
                });

    }

    openModal() {
        const initialState: ModalOptions = {
            initialState: {
                checkDate: this.checkDate,
                title: 'For Payroll Period ' + moment(this.checkDate).format('MM/DD/YYYY')
            }
        };
        this.bsModalRef = this.modalService.show(PayrollReportModalComponent, initialState);
        this.bsModalRef.content.closeBtnName = 'Close';
    }
}
