import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';

@Injectable()
export class ScheduledTasksService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/scheduled-tasks');
    }

    getTasks(search = {}, start = null, end = null, sort = null, order = null) {
        const params: any = {};
        if (start !== null && end !== null) {
            params.start = start;
            params.end = end;
        }

        if (sort !== null) {
            params.sort = sort;
        }

        if (order !== null) {
            params.order = order;
        }
        return this.http.post<any>(this.getEndpoint() + '/list', {_pageFilters: search}, {params: params})
    }

    save(task) {
        return this.http.post<any>(this.getEndpoint() + '/save', task);
    }

}
