import {OrdersService} from '../services/api/orders.service';
import { Injectable } from "@angular/core";

@Injectable()
export class DispatchUnloadDataOptionsProvider {

    static getColumns() {
        return [
            {
                headerTitle: 'Ticket #',
                fieldName: 'ticket_number',
            },
            {
                headerTitle: 'Offload Name',
                fieldName: 'offload_name'
            },
            // {
            //     headerTitle: 'Terminal Name',
            //     fieldName: 'terminal_name'
            // },
            {
                headerTitle: 'Open',
                fieldName: 'unload_data.meter_open'
            },
            {
                headerTitle: 'Close',
                fieldName: 'unload_data.meter_close'
            },
            {
                headerTitle: 'Coordinates',
                fieldName: 'unload_data.location',
                fieldFn: (row: any) => {
                    return row.unload_data.latitude + ', ' + row.unload_data.longitude;
                }
            },
            {
                headerTitle: 'Notes',
                fieldName: 'unload_data.notes'
            },
            {
                headerTitle: 'Corrected By',
                fieldName: 'corrected_by'
            },
            {
                headerTitle: 'Corrected At',
                fieldName: 'corrected_at_formatted'
            },
            {
                headerTitle: 'Start',
                fieldName: 'unload_data.load_start_formatted'
            },
            {
                headerTitle: 'End',
                fieldName: 'unload_data.load_end_formatted'
            }
        ];
    }
}
