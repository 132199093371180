import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {PayCode} from '../../models/pay-code.model';
import { debounceTime } from 'rxjs/operators';
import {DriverAllowances} from '../../models/driver-allowances.model';
import {PayCodeResponse, PayrollService} from '../../services/api/payroll.service';

@Component({
    selector: 'app-payroll-maintenance',
    templateUrl: 'payroll-maintenance.component.html',
    styleUrls: ['./payroll-maintenance.component.scss']
})
export class PayrollMaintenanceComponent implements OnInit {
    payCodeForm: FormGroup;                 // form model
    driverAllowancesForm: FormGroup;        // form model

    payCodeModel: PayCode;                       // data model
    driverAllowancesModel: DriverAllowances;     // data model

    drivers = [];    // drivers dropdown
    payCodes = [];   // pay codes dropdown

    /**
     * Stores the validation messages of the FormControls when they actually fail the validation
     * The template will bind to this object to display the validation errors to the user
     */
    payCodeFormErrors = {
        code: '',
        description: '',
        rate: ''
    };
    driverAllowancesFormErrors = {
        code: '',
        driver: '',
        amount: ''
    };

    /**
     * Imagine these messages being populated from an angular service
     * that retrieves them from a file or back-end server
     * This property represents all available messages for a form control
     * The input element in the template will bind to this property
     *   and display the validation message as appropriate
     * Add a watcher (subscribe to the valueChanges) on the FormControl
     * @private
     */
    private payCodeValidationMessages = {
        code: {
            required: 'Pay Code is required. Please select a pay code.'
        },
        description: {
            required: 'Description is required. Please enter a description.'
        },
        rate: {
            required: 'Rate is required. Please enter rate.'
        }
    };

    private driverAllowancesValidationMessages = {
        code: {
            required: 'Pay Code is required. Please select a pay code.'
        },
        driver: {
            required: 'Driver is required. Please enter a driver number.'
        },
        amount: {
            required: 'Amount is required. Please enter an amount.'
        }
    };


    constructor(
        private payrollService: PayrollService
    ) { }

    submit() {
        console.log(this.payCodeForm);
        console.log(JSON.stringify(this.payCodeForm.value));
    }

    ngOnInit(): void {
        // TODO: create the data model
        // this.payCode = <PayCode> {
        //
        // };
        this.payCodeForm = new FormGroup({

            code: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            rate: new FormControl('', Validators.required),
            isActive: new FormControl(true)
        });
        this.watchPayCodeFormControlsForChanges(this.payCodeForm);




        this.driverAllowancesForm = new FormGroup({
            code: new FormControl('', Validators.required),
            driver: new FormControl('', Validators.required),
            amount: new FormControl('', Validators.required),
            isActive: new FormControl(true)
        });
        this.watchDriverAllowancesFormControlsForChanges(this.driverAllowancesForm);



        this.loadDriversThatAreNotTerminated();
        this.loadActivePayCodes();
        // this.loadDriversThatAreNotTerminated();

    }


    /**
     * Fetch all drivers that are not terminated
     */
    private loadDriversThatAreNotTerminated() {
        this.payrollService.getDriversThatAreNotTerminated().subscribe(response => {
            const options = [];
            for (let i = 0; i < response.data.length; i++) {
                options.push({
                    value: response.data[i].number,
                    label: response.data[i].full_name,
                    disabled: false
                });
            }
            this.drivers = options;

        });
    }


    private loadActivePayCodes() {
        this.payrollService.getActivePayCodes()
            .subscribe((response: PayCodeResponse) => {
                console.log(response);
                const options = [];
                for (let i = 0; i < response.data.length; i++) {
                    options.push({
                        value: response.data[i].code,
                        label: '( ' + response.data[i].code + ' ) ' + response.data[i].description,
                        disabled: false
                    });
                }
                this.payCodes = options;
            });

    }



    populateTestData(): void {
        this.payCodeForm.setValue({
            code: 'SM',
            description: 'Safety Miles',
            rate: .30,
            isActive: true
        });
    }

    populateDriverAllowancesTestData(): void {
        this.driverAllowancesForm.setValue({
            code: 'SM',
            driver: '1401',
            amount: .20,
            isActive: true
        });
    }

    /**
     * Determine the validation message to display, if any
     * @private
     * @param controlName
     * @param control
     */
    private setPayCodeMessage(controlName: string, control: AbstractControl): void {
        // Clear any current message
        this.payCodeFormErrors[controlName] = '';

        if ((control.touched || control.dirty) && control.errors) {
            this.payCodeFormErrors[controlName] += Object.keys(control.errors).map(
                key => this.payCodeValidationMessages[controlName][key]).join(' ');
        }
    }

    private setDriverAllowancesMessage(controlName: string, control: AbstractControl): void {
        // Clear any current message
        this.driverAllowancesFormErrors[controlName] = '';

        if ((control.touched || control.dirty) && control.errors) {
            this.driverAllowancesFormErrors[controlName] += Object.keys(control.errors).map(
                key => this.driverAllowancesValidationMessages[controlName][key]).join(' ');
        }
    }

    /**
     * Watch each control and set validation messages if any validation rules fail
     * @param group
     * @private
     */
    private watchPayCodeFormControlsForChanges(group: FormGroup) {
        Object.keys(group.controls).forEach((key: string) => {
            const abstractControl = group.get(key);
            if (abstractControl instanceof FormGroup) {
                this.watchPayCodeFormControlsForChanges(abstractControl);
            } else {
                abstractControl.valueChanges.pipe(
                    debounceTime(1000)
                ).subscribe(
                    value => {
                        this.setPayCodeMessage(key, abstractControl);
                    }
                );
            }
        });
    }
    private watchDriverAllowancesFormControlsForChanges(group: FormGroup) {
        Object.keys(group.controls).forEach((key: string) => {
            const abstractControl = group.get(key);
            if (abstractControl instanceof FormGroup) {
                this.watchDriverAllowancesFormControlsForChanges(abstractControl);
            } else {
                abstractControl.valueChanges.pipe(
                    debounceTime(1000)
                ).subscribe(
                    value => {
                        this.setDriverAllowancesMessage(key, abstractControl);
                    }
                );
            }
        });
    }

}
