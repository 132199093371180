import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {OffloadsService} from '../../services/api/offloads.service';
import {IOption} from 'ng-select';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StatusesService} from '../../services/api/statuses.service';
import {DriversService} from '../../services/api/drivers.service';
import {PrioritiesService} from '../../services/api/priorities.service';

@Component({
    selector: 'app-modal-component',
    templateUrl: './modal-content.component.html',
    styleUrls: ['./modal-content.component.scss',
    ]
})
export class ModalContentComponent implements OnInit {

    title: string;
    closeBtnName: string;
    list: any[] = [];
    active: boolean;
    onClose: Subject<boolean>;
    data: any[] = [];
    template = '';
    offloads: Array<IOption> = [];
    defaultOffload = '';
    statuses: Array<IOption> = [];
    defaultStatus = '2';
    drivers: Array<IOption> = [];
    defaultDriver = '1';
    dispatchOrderForm: FormGroup;
    offloadloc;
    status;
    priority;
    driver;
    dispatch_date;
    notes;
    priorities: Array<IOption> = [];
    multipleOrders: number;

    constructor(
        public bsModalRef: BsModalRef,
        private offloadService: OffloadsService,
        private statusesService: StatusesService,
        private driversService: DriversService,
        private prioritiesService: PrioritiesService
    ) {
        this.onClose = new Subject();
    }

    ngOnInit() {
        // this.list.push('PROFIT!!!');
        this.offloadService.getOffloads().subscribe(data => {
            const count = data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: data[i].id.toString(),
                    label: data[i].name,
                    disabled: false
                });
            }
            this.offloads = options;

        });

        const blockedStatuses = ['HAULED', 'PICKED UP', 'RECURRING', 'UNLOADING', 'REJECTED'];
        this.statusesService.getStatuses().subscribe(data => {
            const count = data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                if (blockedStatuses.indexOf(data[i].name) !== -1) {
                    continue;
                }
                options.push({
                    value: data[i].id.toString(),
                    label: data[i].name,
                    disabled: false
                });
            }
            this.statuses = options;
        });

        this.prioritiesService.getPriorities().subscribe(data => {
            const count = data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: data[i].id.toString(),
                    label: data[i].name,
                    disabled: false
                });
            }
            this.priorities = options;
        });

        this.driversService.getDrivers().subscribe(response => {
            const count = response.data.length;
            const options = [];
            for (let i = 0; i < count; i++) {
                options.push({
                    value: response.data[i].id.toString(),
                    label: response.data[i].driver_name,
                    disabled: false
                });
            }
            this.drivers = options;
        });
    }

    public onConfirm(): void {
        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.active = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this.bsModalRef.hide();
    }
}
