import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Employee} from '../../models/employee.model';

@Injectable()
export class EmployeesService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/employee');
    }
    getEmployee(): Observable<Employee[]> {
        return this.http.get<Employee[]>(this.getEndpoint());
    }
}
