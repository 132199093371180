import {Component} from '@angular/core';
import {AppVersionService} from '../../services/appVersion.service';

declare var window: any;
@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html'
})
export class AppFooterComponent {
    appVersionNumber;
    year: number = new Date().getFullYear();

    constructor(public appVersionService: AppVersionService) {
        this.appVersionNumber = appVersionService.versionNumber;
    }
}
