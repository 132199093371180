import {Unload} from './unload.model';
import {Pickup} from './pickup.model';

export class Order {
    service_id: number;
    product_id: number;
    confirmation_number: string;
    external_number: string;
    shift_id: number;
    origin_id: number;
    status_id: number;
    lease_id: number;
    offload_id: number;
    priority_id: number;
    notes: string;
    driver_id: number;
    load_type_id: number;
    meter_id: number;
    tank_id: number;
    recurring_start_date: string;
    recurring_end_date: string;
    multiple_orders: number;
    gross_barrels: number|null;
    unloads: Unload[];
    pickups: Pickup[];
    from_web: boolean;
  }


export class OrderObj {
    id: number;
    service_id: number;
    product_id: number;
    gross_barrels: number;
    product_code: number;
    confirmation_number: string;
    ticket_number: string;
    external_number: string;
    shift_id: number;
    origin_id: number;
    status_id: number;
    lease_id: number;
    offload_id: number;
    priority_id: number;
    priority_name: string;
    notes: string;
    driver_id: number;
    driver_name: string;
    load_type_id: number;
    load_type_name: string;
    offload_map: string;
    lease_map: string;
    distance: string;
    dispatched_by: any;
    created_at_date: string;
    duration: string;
    equipment_name: string;
    status_name: string;
    service_name: string;
    lease_name: string = 'Lease Name';
    lease_number: string;
    offload_name: string = 'Offload Name';
    offload_terminal_id: number;
    offload_terminal_name: string = 'Terminal Name';
    offload_number: string;
    meter_number: string;
    tank_number: string;
    offline: boolean = false;
    operator_name: string;
    tank_id: number;
    meter_id: number;
    fromDriver: boolean;
    from_driver: number;
    terminal_id: number;
    terminal_name: string = 'Terminal Name';
    unloads: Unload[];
    pickups: Pickup[];
    visible: boolean;
    created_by: any;
    received_at: string;
    ts_call_date: string;
    multiple_orders: number;
    created_at: string;
    ts_call_date_formatted: string;
}
