import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable()
export class RestrictionsService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/restriction');
    }

    getRestrictions(): Observable<any> {
        return this.http.get<any>(this.getEndpoint());
    }

    getLeaseRestrictions(leaseId): Observable<any> {
        return this.http.get<any>(this.getEndpoint() + '/lease/' + leaseId);
    }

    addLeaseRestrictions(leaseId, clearanace): Observable<any> {
        return this.http.post<any>(this.getEndpoint(), {lease_id: leaseId, clearance: clearanace});
    }

    deleteLeaseRestrictions(restrictionId): Observable<any> {
        return this.http.delete<any>(this.getEndpoint() + '/' + restrictionId);
    }

    toCamelCase(string) {
        const parts = string.split(' ');
        for (let i = 0; i < parts.length; i++) {
            if (i === 0) {
                parts[i] = parts[i].toLowerCase();
            } else {
                parts[i] = parts[i].toLowerCase().charAt(0).toUpperCase() + parts[i].slice(1);
            }
        }

        return parts.join('');
    }
}
