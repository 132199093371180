import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable()
export class AuditLogService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/audit-log');
    }

    getAuditLog(search = {}): Observable<any> {
        return this.http.post<any>(this.getEndpoint(), {_pageFilters: search});
    }
}
