import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {UserRole} from '../../models/UserRole';
import {PageFiltersModalComponent} from '../../components/page-filters-modal/page-filters-modal.component';
import * as moment from 'moment';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';

@Injectable()
export class PageFiltersService extends ApiService {
    private _filterState = {};

    constructor(private http: HttpClient, public modalService: BsModalService) {
        super('/api/pageFilters');
        const filterState = localStorage.getItem('_pageFilters')
        if (filterState) {
            this._filterState = JSON.parse(filterState);
        }
    }

    getFiltersState(page) {
        const filterState = localStorage.getItem('_pageFilters');
        if (filterState) {
            this._filterState = JSON.parse(filterState);
        }
        if (typeof this._filterState[page] === 'undefined') {
            this._filterState[page] = {
                pageFilterValues: {},
                pageFilterSearch: {}
            }
            this.setFiltersState(page, this._filterState[page]);
        }
        return this._filterState[page];
    }

    setFiltersState(page, state) {
        this._filterState[page] = state;
        localStorage.setItem('_pageFilters', JSON.stringify(this._filterState));
    }

    show(page, onClose: any) {
        if (typeof this._filterState[page] === 'undefined') {
            this._filterState[page] = {
                pageFilterValues: {},
                pageFilterSearch: {}
            }
        }
        localStorage.setItem('_pageFilters', JSON.stringify(this._filterState));
        this.bsModalRef = this.modalService.show(PageFiltersModalComponent, {
            initialState: {
                pageName: page,
                pageFilterValues: this._filterState[page].pageFilterValues,
                pageFilterSearch: this._filterState[page].pageFilterSearch
            }
        });
        this.bsModalRef.content.closeBtnName = 'Close';
        this.bsModalRef.content.title = 'Filters';
        this.bsModalRef.content.template = '';
        this.bsModalRef.content.onClose.subscribe(result => {
            this._filterState[page].pageFilterValues = this.bsModalRef.content.pageFilterValues;
            this._filterState[page].pageFilterSearch = this.bsModalRef.content.pageFilterSearch;
            this.setFiltersState(page, this._filterState[page]);
            if (result === true) {
                // They pressed submit
                for (const key in this.bsModalRef.content.filterValues) {
                    if (this.bsModalRef.content.filterValues.hasOwnProperty(key)) {
                        if (typeof this.bsModalRef.content.filterValues[key] !== 'string') {
                            if (this.bsModalRef.content.filterValues[key] instanceof Date) {
                                this.bsModalRef.content.filterValues[key] =
                                    moment(this.bsModalRef.content.filterValues[key]).format();
                            } else {
                                this.bsModalRef.content.filterValues[key] = this.bsModalRef.content.filterValues[key].toString();
                            }
                        }
                    }
                }
            } else if (result === false) {
                // They pressed No
            } else {
                // Modal was just hidden
            }
            onClose(result, this._filterState[page]);
        });
    }
}
