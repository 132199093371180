import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './app-sidebar-footer.component.html'
})
export class AppSidebarFooterComponent {

  constructor() { }
}
