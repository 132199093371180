import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ApiService} from '../api.service';
import {RejectReason} from '../../models/reject-reason.model';

@Injectable()
export class RejectReasonsService extends ApiService {
    rejectsreasons: any[];

    constructor(private http: HttpClient) {
      super('/api/reject-reason');
    }
    public getRejectReasons(): Observable<RejectReason[]> {
        return this.http.get<RejectReason[]>(this.getEndpoint());
    }
}
