import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable()
export class BackgroundTaskService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/background-tasks');
    }

    getBackgroundTasks(pageFilters = {}, page = 1, perPage = 5): Observable<any> {
        const params: any = pageFilters;
        params.page = page;
        params.perPage = perPage;
        return this.http.get<any>(this.getEndpoint(), {
            params: params
        });
    }

    download(taskId: number): Observable<HttpResponse<Blob>> {
        return this.http.get<Blob>(this.getEndpoint() + '/download/' + taskId, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    delete(taskId: number) {
        return this.http.get(this.getEndpoint() + '/delete/' + taskId);
    }

    clear(pageFilters = {}) {
        return this.http.get(this.getEndpoint() + '/clear/', {
            params: pageFilters
        });
    }
}
