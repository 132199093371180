import {Component, OnInit} from '@angular/core';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {Order, OrderObj} from '../../models/order.model';


@Component({
  selector: 'app-modal-create-order',
  templateUrl: './modal-create-order.component.html',
  styleUrls: ['./modal-create-order.component.scss']
})
export class ModalCreateOrderComponent implements OnInit {
  title: string;
  active: boolean;
  closeBtnName: string;
  onClose: Subject<boolean>;
  orders: OrderObj[];
  selectedOrder: number;

  constructor(public bsModalRef: BsModalRef) {
    this.onClose = new Subject();
  }

  public ngOnInit(): void {
    this.selectedOrder = this.orders[0].id;
  }

  public onCancel(): void {
    this.active = false;
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

}
