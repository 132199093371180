import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {UploadService} from '../../services/api/upload.service';

@Component({
    selector: 'app-payroll-schedule',
    templateUrl: './payroll-schedule.component.html',
    styleUrls: ['./payroll-schedule.component.scss']
})
export class PayrollScheduleComponent {
    @Input()
    requiredFileType: string;

    @ViewChild('labelImport') labelImport: ElementRef;
    @ViewChild('uploadButton') uploadButton: ElementRef;

    fileToUpload: File | null = null;
    fileName = '';
    uploadError: string;
    uploadResponse: number;

    constructor(private uploadService: UploadService) { }

    /**
     * User clicks the Upload Button
     */
    onFileUpload() {
        if (this.fileToUpload) {
            const formData = new FormData();
            formData.append('file', this.fileToUpload );

            this.uploadService.upload('/payroll-schedule', formData)
                .subscribe(
                    result => {
                        this.uploadResponse = result;
                    },
                    (error) => {
                        this.uploadError = error
                    },
                    () => {
                        this.labelImport.nativeElement.innerText = 'Upload Complete'
                        this.uploadButton.nativeElement.disabled = true;
                    }
                );
        }
    }

    /**
     * file input change event handler
     * @param event
     */
    onFileSelected(event) {
        const file: File = event.target.files[0];

        // if the user cancels the file upload dialog then you will get an errors in the console because file will be undefined.
        if (file) {
            this.fileName = file.name;
            this.fileToUpload = file;  // we just use the file dialog to get a reference to the file
            this.labelImport.nativeElement.innerText = this.fileName;
            this.uploadButton.nativeElement.disabled = false;
        }
    }
}
