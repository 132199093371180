import {Component, OnInit} from '@angular/core';
import { BsModalRef} from 'ngx-bootstrap/modal';
import {environment} from '../../../environments/environment';
import {PayrollService} from '../../services/api/payroll.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {IOption} from 'ng-select';

@Component({
    selector: 'app-payroll-report-modal.component',
    templateUrl: './payroll-report-modal.component.html'
})
export class PayrollReportModalComponent implements OnInit {
    url: string;



    /**
     * out template will bind to this payrollReportForm property
     *   to associate the html <form> element
     *     with this root FormGroup Instance
     */
    payrollReportForm: FormGroup;  // the form model
    selectedDriversControl = new FormControl(['*'], Validators.required);


    drivers = [];    // drivers dropdown

    checkDate?: string;

    title?: string;
    closeBtnName?: string;
    list: any[] = [];


    constructor(
        private router: Router,
        public bsModalRef: BsModalRef,
        private payrollService: PayrollService
    ) {}



    ngOnInit() {
        this.payrollReportForm = new FormGroup({
            drivers: this.selectedDriversControl,
        });

        this.loadActiveDriversDuringPeriod(this.checkDate);
    }



    loadActiveDriversDuringPeriod(checkDate: string) {
        this.payrollService.getActiveDrivers(checkDate).subscribe(response => {
            const options = [];
            let first_iteration = true;
            for (let i = 0; i < response.data.length; i++) {
                if (first_iteration) {
                    options.push({
                        value: '*',
                        label: 'All Active Drivers',
                        disabled: false
                    });
                    first_iteration = false;
                }
                options.push({
                    value: response.data[i].number,
                    label: response.data[i].full_name,
                    disabled: false
                });
            }
            this.drivers = options;

        });
    }


    /**
     *
     * @param option
     */
    onSelected(option: IOption) {

        if (option.value === '*') {
            this.payrollReportForm.controls['drivers'].setValue(['*']);
            return;
        }
        const selectedOptions = this.payrollReportForm.controls['drivers'].value;
        if (selectedOptions.includes('*')) {
            selectedOptions.splice(0, 1);
        }
        this.payrollReportForm.controls['drivers'].setValue(selectedOptions);
    }


    /**
     * Submit criteria for report
     */
    submit() {
        const apiUrl = environment.apiUrl;
        this.url = apiUrl + '/payroll-report/' + this.checkDate;


        console.log(this.payrollReportForm.value);

        // TODO: NOT IMPLEMENTED YET
        // this.payrollService.createPayrollReport(
        //     this.checkDate,
        //     this.payrollReportForm.value
        // ).subscribe(
        //     response => {
        //         console.log(response)
        //     }
        // );

    }



}

