<div class="col-sm-12">
    <div class="card">
        <div class="card-block">
            <div class="pull-right">
                <small class="pull-right text-muted">Last Updated: {{restriction.created_at}}
                    ({{restriction.user.first_name}} {{restriction.user.last_name}})
                </small>
                <br/>
                <small class="pull-right text-muted text-success">+ {{restriction.barrels_added | number}} barrels
                    added
                </small>
            </div>


            <div class="h5 m-0">{{progress}}% used</div>
            <div>{{restriction.lease.name}} ({{restriction.lease.number}})</div>
            <small>{{restriction.lease.operator.name}}</small>
            <div class="progress progress-xs my-1">
                <div [attr.class]="getProgressClass()" role="progressbar"
                     [style.width]="progress + '%'"
                     [attr.aria-valuenow]="progress"
                     aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <small><b>Allowance:</b><span class="text-success"> {{restriction.total_allowance | number}}</span></small>
            <small><b> Hauled:</b><span class="text-danger"> {{restriction.total_hauled | number}}</span></small>
            <small><b> Remaining:</b><span class="text-gray-dark"> {{restriction.total_allowance - restriction.total_hauled | number}} (Barrels)</span>
            </small>
            <button class="btn btn-info float-right btn-sm" *ngIf="showEdit" (click)="onEditEvent()"><i
                    class="fa fa-edit"></i></button>
            <button class="btn btn-danger float-right btn-sm" *ngIf="showEdit" (click)="onDeleteEvent()"><i
                    class="fa fa-trash"></i></button>
        </div>
    </div>
</div><!--/.col-->
