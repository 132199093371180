import {PickupData} from './pickup-data.model';
import {Lease} from './Lease.model';
import {RejectReason} from './reject-reason.model';

export class Pickup {
    id: number;
    order_id: number;
    ticket_number: string;
    alt_ticket_number: string;
    external_ticket_number: string;
    lease_id: number;
    lease_name: string;
    lease_number: string;
    terminal_id: number;
    terminal_name: string;
    refinery_name: string;
    tank_id: number;
    tank_number: string;
    meter_id: number;
    meter_number: string;
    equipment_name: string;
    corrected_at: string;
    corrected_by: number;
    pickup_data: PickupData;
    driver_id: number;
    lease: Lease;
    reject: any;
    operator_name: string;
    meter_factor: number;
    seller_id: number;
    avg_corrected_gravity: number;
    driver_name: string;
}
