import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthService {
    private endPoint = '/oauth/token';
    public currentUser;

    constructor(private http: HttpClient, private cookie: CookieService) {
    }

    public isLoggedIn() {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            try {
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                return true;
            } catch (e) {
                return false;
            }
        }
        return false;
    }

    public hasRole(role: string) {
        if (!this.isLoggedIn()) {
            return false;
        }
        if (typeof this.currentUser === 'undefined') {
            return false;
        }

        let hasRole = false;
        try {
            hasRole = (this.currentUser.access.roles.indexOf(role) !== -1);
        } catch (e) {
            hasRole = false;
        }

        return hasRole;
    }

    public hasPermission(permission: string) {
        if (!this.isLoggedIn()) {
            return false;
        }

        if (typeof this.currentUser === 'undefined') {
            return false;
        }

        try {
            for (const userPermission of this.currentUser.access.permissions) {
                if (userPermission.name === permission) {
                    return true;
                }
            }
        } catch (e) {
            return false;
        }

        return false;
    }

    public async getPermissions() {
        if (!this.isLoggedIn()) {
            return false;
        }
        return await this.http.get<any>(environment.apiUrl + '/api/user/permissions')
            .pipe(map(permissions => {
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                const user = this.currentUser;
                user.access = permissions;
                this.currentUser = user;
                localStorage.setItem('currentUser', JSON.stringify(user));
                return this.currentUser
            }))
            .toPromise().catch();
    }

    public login(username: string, password: string): Observable<any[]> {
        return this.http.post<any>(environment.apiUrl + this.endPoint, {
            grant_type: 'password',
            client_id: environment.clientId,
            client_secret: environment.clientSecret,
            username: username,
            password: password,
            scope: ''
        }).pipe(
            map(user => {
                if (user && user.access_token) {
                    let refreshToken;
                    const expiresDate = new Date();
                    user.username = username;
                    user.expires = (expiresDate.getTime() + (user.expires_in * 1000) - 5000);
                    if (user.refresh_token) {
                        refreshToken = user.refresh_token;
                        delete user.refresh_token;
                        this.cookie.set('refresh_token', refreshToken);
                    }
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.currentUser = user;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.getPermissions().then();
                }

                return user;
            }));
    }

    public loginValid() {
        let refreshToken = '';
        if (localStorage.getItem('currentUser')) {
            if (refreshToken = this.cookie.get('refresh_token')) {
                if (refreshToken.length > 0) {
                    return true;
                }
            }
        }
    }

    public refresh() {
        return this.http.post<any>(environment.apiUrl + this.endPoint, {
            grant_type: 'refresh_token',
            client_id: environment.clientId,
            client_secret: environment.clientSecret,
            refresh_token: this.cookie.get('refresh_token'),
            scope: ''
        }).pipe(
            map(user => {
                if (user && user.access_token) {
                    let refreshToken;
                    const expiresDate = new Date();
                    user.expires = (expiresDate.getTime() + (user.expires_in * 1000) - 5000);
                    if (user.refresh_token) {
                        refreshToken = user.refresh_token;
                        delete user.refresh_token;
                        this.cookie.set('refresh_token', refreshToken);
                    }
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                return user;
            }));
    }

    public logout() {
        localStorage.removeItem('currentUser');
        this.currentUser = null;
        this.cookie.delete('refresh_token');
    }

}

