import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Meter} from '../../models/meter.model';

@Injectable()
export class MetersService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/meter');
    }
    getMeters(): Observable<Meter[]> {
        return this.http.get<Meter[]>(this.getEndpoint());
    }
}
