import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

export class UserCredentialsResponse {
    success: boolean;
    message: string;
}

export class UserCredentialsRequest {
    currentPwd: string;
    newPwd: string;
    confirmPwd: string;
}

@Injectable()
export class UsersService extends ApiService {

    constructor(private http: HttpClient) {
        super('/api/user');
    }

    getAvatar(): Observable<any> {
        return this.http.get<any>(this.getEndpoint() + '/avatar')
    }

    getUsers(search = {}, start = null, end = null, sort = null, order = null) {
        const params: any = {};
        if (start !== null && end !== null) {
            params.start = start;
            params.end = end;
        }

        if (sort !== null) {
            params.sort = sort;
        }

        if (order !== null) {
            params.order = order;
        }
        return this.http.post<any>(this.getEndpoint() + '/list', {_pageFilters: search}, {params: params})
    }

    saveUser(user) {
        return this.http.post<any>(this.getEndpoint() + '/save', user);
    }

    unlockUser(user) {
        console.log(user);
        return this.http.post<any>(this.getEndpoint() + '/unlock/' + user.id, {});
    }

    updatePassword(userCredentialsRequest: UserCredentialsRequest): Observable<any> {
        return this.http.post<any>(this.getEndpoint() + '/credentials', userCredentialsRequest);
    }
}
