import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {PageFiltersApiService} from '../../services/api/page-filters-api.service';

@Component({
    selector: 'app-modal-component',
    templateUrl: './page-filters-modal.component.html',
    styleUrls: ['./page-filters-modal.component.scss']
})
export class PageFiltersModalComponent implements OnInit {
    title: string;
    closeBtnName: string;
    onClose: Subject<any>;
    active: boolean;
    pageName: string;
    objectKeys = Object.keys;

    // PAGE FILTER PROPERTIES
    pageFilters = {};
    pageFilterValues = {};
    pageFilterSearch = {};
    pageFiltersLoaded = false;
    filterSearchDebounce;

    // END PAGE FILTER PROPERTIES

    constructor(public bsModalRef: BsModalRef,
                private changeDetector: ChangeDetectorRef,
                private pageFilterService: PageFiltersApiService
    ) {
        this.onClose = new Subject();
    }

    async ngOnInit() {
        this.getPageFilters();
    }

    public onConfirm(): void {
        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.active = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(this.pageFilterValues);
        this.bsModalRef.hide();
    }

    getPageFilters() {
        this.pageFilterService.getFilters(this.pageName, this.pageFilterSearch).subscribe((response) => {
            for (let i = 0; i < response.length; i++) {
                this.pageFilters[response[i].name] = response[i];
                if (typeof this.pageFilterValues[response[i].name] === 'undefined') {
                    this.pageFilterValues[response[i].name] = '';
                }
                if (this.pageFilters[response[i].name].searchable) {
                    if (typeof this.pageFilterValues[this.pageFilters[response[i].name].name] === 'undefined') {
                        this.pageFilterValues[this.pageFilters[response[i].name].name] = '';
                    }
                }
            }
            this.pageFiltersLoaded = true;
        });
    }

    updatePageFilters() {
        this.pageFilterService.getFilters(this.pageName, this.pageFilterSearch).subscribe((response) => {
            for (let i = 0; i < response.length; i++) {
                if (response[i].searchable) {
                    this.pageFilters[response[i].name].options = response[i].options;
                    if (response[i].options.length === 1 && this.pageFilterValues[response[i].name] !== response[i].options[0].value) {
                        this.pageFilterValues[response[i].name] = response[i].options[0].value;
                        this.filterSelected(this.pageFilters[response[i].name], {}, false);
                    }
                }
            }
        });
    }

    filterDeselected(filter, $event) {
        this.filterSelected(filter, $event, false);
    }

    filterSelected(filter, $event, autoUpdate = true) {
        const filterName = filter.name.split(' ').join('');
        const key = `selected${filterName}`;
        const selectedValue = (typeof filter.multiSelect !== 'undefined' && filter.multiSelect)
            ? this.pageFilterValues[filter.name].join(',') : this.pageFilterValues[filter.name];
        if (typeof selectedValue !== 'undefined') {
            this.pageFilterSearch[key] = selectedValue;
        } else {
            this.pageFilterSearch[filter.name] = '';
            this.pageFilterSearch[key] = '';
        }
        if (autoUpdate) {
            this.updatePageFilters();
        }
    }

    public filterSearch(filter, $event) {
        if (filter.searchable) {
            this.pageFilterSearch[filter.name] = $event;
            if (this.filterSearchDebounce) {
                clearTimeout(this.filterSearchDebounce);
            }

            this.filterSearchDebounce = setTimeout(() => {
                this.filterSearchDebounce = null;
                this.pageFilterService.getFilters(this.pageName, this.pageFilterSearch).subscribe((response) => {
                    for (let i = 0; i < response.length; i++) {
                        if (response[i].searchable) {
                            this.pageFilters[response[i].name].options = response[i].options;
                        }
                    }
                });
            }, 250);
        }
    }
}
