<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>
<div class="modal-body">
    <div>
        <div class="form-group">
            <label for="status">Status</label>
            <ng-select id="status" class="bootstrap" [(ngModel)]="status" [options]="statuses" filterPlaceholder="Type to search..." aria-describedby="statusHelp"></ng-select>
            <small id="statusHelp" class="form-text text-muted">Select a status</small>
        </div>
        <div class="form-group">
            <label for="priority">Priority</label>
            <ng-select id="priority" class="bootstrap" [(ngModel)]="priority" [options]="priorities" filterPlaceholder="Type to search..." aria-describedby="priorityHelp"></ng-select>
            <small id="priorityHelp" class="form-text text-muted">Select a priority</small>
        </div>
        <div class="form-group">
            <label for="dispatchDate">Dispatch Date</label>
            <input bsDatepicker id="dispatchDate" class="form-control" [(ngModel)]="dispatch_date"  aria-describedby="dispatchDateHelp" />
            <small id="dispatchDateHelp" class="form-text text-muted">Select a driver.</small>
        </div>
        <div class="form-group">
            <label for="offloadloc">Offload</label>
            <ng-select id="offloadloc" class="bootstrap" [(ngModel)]="offloadloc" [options]="offloads" filterPlaceholder="Type to search..." aria-describedby="offloadsHelp"></ng-select>
            <small id="offloadsHelp" class="form-text text-muted">Select an offload.</small>
        </div>
        <div class="form-group">
            <label for="driver">Driver</label>
            <ng-select id="driver" class="bootstrap" [(ngModel)]="driver" [allowClear]="true" [options]="drivers" filterPlaceholder="Type to search..." aria-describedby="driversHelp"></ng-select>
            <small id="driversHelp" class="form-text text-muted">Select a driver.</small>
        </div>
        <div class="form-group">
            <label for="multipleOrders">Multiple Orders</label>
            <input id="multipleOrders" class="bootstrap form-control form-control-lg" [(ngModel)]="multipleOrders"  aria-describedby="multipleOrdersHelp" />
            <small id="multipleOrdersHelp" class="form-text text-muted"></small>
        </div>
        <div class="form-group">
            <label for="driver">Notes</label>
            <textarea type="text" id="notes" class="bootstrap form-control form-control-lg" [(ngModel)]="notes"></textarea>
            <small id="notesHelp" class="form-text text-muted">Order notes.</small>
        </div>
        <button type="submit" class="btn btn-primary" (click)="onConfirm()">Submit</button>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="onCancel()">{{closeBtnName}}</button>
</div>