import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Truck} from '../../models/truck.model';

@Injectable()
export class TrucksService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/truck');
    }
    getTrucks(): Observable<Truck[]> {
        return this.http.get<Truck[]>(this.getEndpoint());
    }
    getDashboard(): Observable<any> {
        return this.http.get<any>(this.getEndpoint() + '/dashboard');
    }
}
