<div class="tank-strappings card">
    <div class="card-header">
        <span class="float-left">Third Party Haulers</span>


        <div class="float-right">
            <button class="btn btn-success float-right btn-md" (click)="add()"><i class="fa fa-plus"></i>
                Add
            </button>
            <button class="btn btn-info mb-1" (click)="showFilters()">
                <i class="fa fa-filter"></i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="input-group input-group-lg mb-2 mt-2">
            <div class="input-group-prepend">
                <span class="input-group-text" *ngIf="canEdit()">
                    <button (click)="edit()" class="btn btn-light">Edit</button>
                </span>
                <span class="input-group-text">
                    <button (click)="refresh()" class="btn btn-light">Refresh</button>
                </span>
            </div>
        </div>
        <div [ng2-loading]="gridLoading">
            <lib-table-grid class="table-responsive" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"></lib-table-grid>
        </div>
    </div>
</div>

<ng-template #addModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{modalTitle}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-lg text-center">
        <div class="form">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <input type="text" name="name" class="form-control" id="inputName" aria-describedby="nameHelp"
                           placeholder="Enter Name" [(ngModel)]="_current['name']">
                    <small id="nameHelp" class="form-text text-muted">Please enter the haulers unique name</small>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="save()">Submit</button>
    </div>
</ng-template>
