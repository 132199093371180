<div class="full-width-panel row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Leases</h5>
                <div class="input-group input-group-sm mb-2 mt-2">
                    <div class="input-group-prepend">
                        <button *ngIf="leasesSelected()" (click)="assign('lease')" class="btn btn-success">Assign</button>
                        <button *ngIf="leasesSelected()" (click)="unassign('lease')" class="btn btn-danger">Unassign</button>
                        <button *ngIf="!showUnassignedLeases" (click)="toggledUnassignedLeases()" class="btn btn-info">Show Unassigned</button>
                        <button *ngIf="showUnassignedLeases" (click)="toggledUnassignedLeases()" class="btn btn-info">Hide Unassigned</button>
                    </div>
                    <input
                        type="text"
                        [value]="leasesSearchString"
                        [(ngModel)]="leasesSearchString"
                        (change)="leasesSearch()"
                        class="form-control"
                        aria-describedby="inputGroup-sizing-lg"/>

                </div>
                <div [ng2-loading]="leasesGridLoading">
                <lib-table-grid *ngIf="data.id" [gridOptions]="leasesGridOptions"
                                (gridReady)="leasesGridReady($event)"></lib-table-grid>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="full-width-panel row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Offloads</h5>
                <div class="input-group input-group-sm mb-2 mt-2">
                    <div class="input-group-prepend">
                        <button *ngIf="offloadsSelected()" (click)="assign('offload')" class="btn btn-success">Assign</button>
                        <button *ngIf="offloadsSelected()" (click)="unassign('offload')" class="btn btn-danger">Unassign</button>
                        <button *ngIf="!showUnassignedOffloads" (click)="toggledUnassignedOffloads()" class="btn btn-info">Show Unassigned</button>
                        <button *ngIf="showUnassignedOffloads" (click)="toggledUnassignedOffloads()" class="btn btn-info">Hide Unassigned</button>
                    </div>
                    <input
                        type="text"
                        [value]="offloadsSearchString"
                        [(ngModel)]="offloadsSearchString"
                        (change)="offloadsSearch()"
                        class="form-control"
                        aria-describedby="inputGroup-sizing-lg"/>

                </div>
                <div class="table-responsive" [ng2-loading]="offloadsGridLoading">
                    <lib-table-grid *ngIf="data.id" [gridOptions]="offloadsGridOptions"
                                    (gridReady)="offloadsGridReady($event)"></lib-table-grid>
                </div>
            </div>
        </div>
    </div>
</div>
