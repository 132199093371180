import {Component, ElementRef, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {
    TableGridComponent, TableGridDetails,
    TableGridFilters,
    TableGridOptions,
    TableGridRowDataRequest,
    TableGridRowDataResponse
} from 'angular-table-grid';
import {map} from 'rxjs/operators';
import {ThirdPartyHaulersService} from '../../../services/api/third-party-haulers.service';
import {DispatchPickupDataOptionsProvider} from '../../../providers/dispatch-pickup-data-options.provider';
import {Observable} from 'rxjs';

@Component({
    templateUrl: 'third-party-hauler-details.component.html',
    styleUrls: ['./third-party-hauler-details.component.scss']
})
export class ThirdPartyHaulerDetailsComponent implements TableGridDetails {
    leasesGridOptions: TableGridOptions;
    offloadsGridOptions: TableGridOptions;
    data: any;
    public gridOptions: TableGridOptions;
    public gridRow;
    public gridCell;
    showUnassignedLeases = false;
    showUnassignedOffloads = false;
    public leasesGridApi;
    public offloadsGridApi;
    leasesGridLoading = false;
    offloadsGridLoading = false;
    leasesSearchString = '';
    offloadsSearchString = '';

    constructor(
        private thirdPartyHaulersService: ThirdPartyHaulersService,
    ) {
        this.leasesGridOptions = <TableGridOptions>{
            columns: [
                {
                    headerTitle: 'id',
                    fieldName: 'ID'
                },
                {
                    headerTitle: 'Name',
                    fieldName: 'name'
                },
                {
                    headerTitle: 'Number',
                    fieldName: 'number'
                },
                {
                    headerTitle: 'Operator',
                    fieldName: 'operator'
                },
                {
                    headerTitle: 'County',
                    fieldName: 'county'
                },
                {
                    headerTitle: 'State',
                    fieldName: 'state'
                }
            ],
            getRowData: (rowDataRequest: TableGridRowDataRequest) => {
                const page = rowDataRequest.pagination.page;
                const perPage = rowDataRequest.pagination.perPage;
                const start = (page - 1) * perPage;
                const end = page * perPage;
                this.leasesGridLoading = true;
                return this.thirdPartyHaulersService.getLeases(this.data.id, start, end, this.leasesSearchString, this.showUnassignedLeases)
                    .pipe(
                        map((response: any) => {
                            this.leasesGridLoading = false;
                            return {
                                rows: response.data.leases,
                                totalRows: response.data.rowCount
                            }
                        })
                    )
            },
            getRowStyles: (node: any) => {
                const styles = {
                    'background-color': 'none',
                };
                if (node.third_party_hauler_id != null) {
                    styles['background-color'] = 'rgba(3, 171, 122, 0.1)';
                }
                return styles;
            },
            enableDetails: false,
            perPage: 5,
            multiSelectOn: true
        };

        this.offloadsGridOptions = <TableGridOptions>{
            columns: [
                {
                    headerTitle: 'id',
                    fieldName: 'ID'
                },
                {
                    headerTitle: 'Name',
                    fieldName: 'name'
                }, {
                    headerTitle: 'Number',
                    fieldName: 'number'
                }
            ],
            getRowData: (rowDataRequest: TableGridRowDataRequest) => {
                this.offloadsGridLoading = true;
                const page = rowDataRequest.pagination.page;
                const perPage = rowDataRequest.pagination.perPage;
                const start = (page - 1) * perPage;
                const end = page * perPage;
                return this.thirdPartyHaulersService
                    .getOffloads(this.data.id, start, end, this.offloadsSearchString, this.showUnassignedOffloads).pipe(
                        map((response: any) => {
                            this.offloadsGridLoading = false;
                            return {
                                rows: response.data.offloads,
                                totalRows: response.data.rowCount
                            }
                        })
                    )
            },
            getRowStyles: (node: any) => {
                const styles = {
                    'background-color': 'none',
                };
                if (node.third_party_hauler_id != null) {
                    styles['background-color'] = 'rgba(3, 171, 122, 0.1)';
                }
                return styles;
            },
            enableDetails: false,
            perPage: 5,
            multiSelectOn: true
        };
    }

    detailsInit(node) {
        this.data = node;
    }

    leasesGridReady(gridApi: TableGridComponent) {
        this.leasesGridApi = gridApi;
        this.leasesGridApi.rowDataRequest.filters[0] = <TableGridFilters>{
            q: ''
        };
    }

    offloadsGridReady(gridApi: TableGridComponent) {
        this.offloadsGridApi = gridApi;
        this.offloadsGridApi.rowDataRequest.filters[0] = <TableGridFilters>{
            q: ''
        };
    }

    toggledUnassignedLeases() {
        this.showUnassignedLeases = !this.showUnassignedLeases;
        this.leasesGridApi.refresh();
    }

    leasesSearch() {
        this.leasesGridApi.refresh();
    }

    leasesSelected() {
        if (typeof this.leasesGridApi !== 'undefined') {
            return this.leasesGridApi.getSelectedRows().length;
        }
        return false;
    }

    toggledUnassignedOffloads() {
        this.showUnassignedOffloads = !this.showUnassignedOffloads;
        this.offloadsGridApi.refresh();
    }

    offloadsSearch() {
        this.offloadsGridApi.refresh();
    }

    offloadsSelected() {
        if (typeof this.offloadsGridApi !== 'undefined') {
            return this.offloadsGridApi.getSelectedRows().length;
        }
        return false;
    }

    async assign(type) {
        let ids;
        if (type === 'lease') {
            this.leasesGridLoading = true;
            ids = this.leasesGridApi.getSelectedRows().map((row) => {
                return row.id;
            });
        } else {
            this.offloadsGridLoading = true;
            ids = this.offloadsGridApi.getSelectedRows().map((row) => {
                return row.id;
            });
        }
        await this.thirdPartyHaulersService.assign(this.data.id, type, ids).toPromise();
        if (type === 'lease') {
            this.leasesGridLoading = false;
            this.leasesGridApi.refresh();
        } else {
            this.offloadsGridLoading = false;
            this.offloadsGridApi.refresh();
        }
    }

    async unassign(type) {
        let ids;
        if (type === 'lease') {
            this.leasesGridLoading = true;
            ids = this.leasesGridApi.getSelectedRows().map((row) => {
                return row.id;
            });
        } else {
            this.offloadsGridLoading = true;
            ids = this.offloadsGridApi.getSelectedRows().map((row) => {
                return row.id;
            });
        }
        await this.thirdPartyHaulersService.unassign(this.data.id, type, ids).toPromise();
        if (type === 'lease') {
            this.leasesGridLoading = false;
            this.leasesGridApi.refresh();
        } else {
            this.offloadsGridLoading = false;
            this.offloadsGridApi.refresh();
        }
    }
}
