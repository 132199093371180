export class UnloadData {
    id: number;
    ticket_number: string;
    order_id: number;
    odometer: number;
    meter: number;
    meter_open: number;
    meter_close: number;
    notes: string;
    load_start_formatted: string;
    load_end_formatted: string;
    offload_change_reasons: string;
    load_start: any;
    load_end: any;
}
