import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Priority} from '../../models/priority.model';

@Injectable()
export class PrioritiesService extends ApiService {

    constructor(private http: HttpClient) {
      super('/api/priority');
    }
    getPriorities(): Observable<Priority[]> {
        return this.http.get<Priority[]>(this.getEndpoint());
    }
}
