import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Lease} from '../../models/Lease.model';
import {ApiService} from '../api.service';

@Injectable()
export class LeasesService extends ApiService {
    constructor(private http: HttpClient) {
        super('/api/lease');
    }

    public getLeases(search = {}, start = null, end = null, sort = null, order = null): Observable<any> {
        const params: any = {};
        if (start !== null && end !== null) {
            params.start = start;
            params.end = end;
        }

        if (sort !== null) {
            params.sort = sort;
        }

        if (order !== null) {
            params.order = order;
        }
        return this.http.post<any>(this.getEndpoint() + '/list', {_pageFilters: search}, {params: params})
    }

    public getLease(id: number): Observable<any> {
        return this.http.get<Lease>(this.getEndpoint() + '/' + id);
    }

    public leaseSearch(term: any): Observable<any> {
        return this.http.post<Lease[]>(this.getEndpoint() + '/search', {
            data: {
                search_for: term
            }
        });
    }

    public getOperatorLeases(operatorId: number): Observable<Lease[]> {
        return this.http.post<Lease[]>(this.getEndpoint() + '/operator/search', {
            data: {
                search_for: operatorId
            }
        });
    }

    public getEquipment(id: number, search: string): Observable<Lease[]> {
        return this.http.post<Lease[]>(this.getEndpoint() + '/equipment/search', {
            data: {
                lease: id,
                search_for: search
            }
        });
    }

    public getOrderHistory(id: number): Observable<Lease[]> {
        return this.http.get<Lease[]>(this.getEndpoint() + '/' + id + '/order')
    }

    public async updateNotes(id: number, notes: string) {
        return await this.http.post(this.getEndpoint() + `/${id}/notes`, {notes: notes}).toPromise();
    }
}

