import {Component, OnInit} from '@angular/core';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-modal-component',
    templateUrl: './modal-alert.component.html',
    styleUrls: ['./modal-alert.component.scss',
    ]
})
export class ModalAlertComponent implements OnInit {
    onClose: Subject<boolean>;
    closeBtnName = 'Ok';
    title = 'Error';
    errors = [];

    constructor(
        public bsModalRef: BsModalRef
    ) {
        this.onClose = new Subject();
    }

    ngOnInit() {
        // placeholder
    }

    public onConfirm(): void {
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.onClose.next(null);
        this.bsModalRef.hide();
    }
}
