import {Component, OnInit} from '@angular/core';
import {BackgroundTaskService} from '../../services/api/background-task.service';
import * as moment from 'moment';
import {AuthService} from '../../services/auth/auth.service';
import {PageFiltersModalComponent} from '../page-filters-modal/page-filters-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'app-aside',
    templateUrl: './app-aside.component.html'
})
export class AppAsideComponent implements OnInit {

    public backgroundTasks = [];
    private timer;
    bsModalRef: BsModalRef;
    pageFilterServiceValues = {};
    pageFilterServiceSearch = {};
    perPage = 5;
    page = 1;
    pageCount = 1;
    pages = [];
    maxPages = 3;

    constructor(private backgroundTaskService: BackgroundTaskService,
                public auth: AuthService,
                private modalService: BsModalService) {
        this.checkForBackgroundTasks();
    }

    getProcessed(backgroundTask) {
        return Math.floor(backgroundTask.jobs.length * backgroundTask.progress);
    }

    getCreatedAt(backgroundTask) {
        return moment.utc(backgroundTask.created_at).local().format('M/D/YY h:mm a');
    }

    getProgress(backgroundTask) {
        let progress = Math.round(backgroundTask.progress * 100);
        if (progress < 0) {
            progress = Math.abs(progress) + 100;
        }

        return progress;
    }

    getProgressClass(backgroundTask) {
        const progress = this.getProgress(backgroundTask);
        let progressClass = 'progress-bar bg';
        if (progress >= 100) {
            progressClass += '-success';
        } else {
            progressClass += '-info';
        }

        return progressClass;
    }

    ngOnInit(): void {
    }

    trackByFn(index, item) {
        return item.id;
    }

    showFilters() {
        this.bsModalRef = this.modalService.show(PageFiltersModalComponent, {
            initialState: {
                pageName: 'f Background Tasks',
                pageFilterValues: this.pageFilterServiceValues,
                pageFilterSearch: this.pageFilterServiceSearch
            }
        });
        this.bsModalRef.content.closeBtnName = 'Close';
        this.bsModalRef.content.title = 'Filters';
        this.bsModalRef.content.template = '';
        this.bsModalRef.content.onClose.subscribe(result => {
            this.pageFilterServiceValues = this.bsModalRef.content.pageFilterValues;
            this.pageFilterServiceSearch = this.bsModalRef.content.pageFilterSearch;
            if (result === true) {
                // They pressed submit
                for (const key in this.bsModalRef.content.filterValues) {
                    if (this.bsModalRef.content.filterValues.hasOwnProperty(key)) {
                        if (typeof this.bsModalRef.content.filterValues[key] !== 'string') {
                            if (this.bsModalRef.content.filterValues[key] instanceof Date) {
                                this.bsModalRef.content.filterValues[key] =
                                    moment(this.bsModalRef.content.filterValues[key]).format();
                            } else {
                                this.bsModalRef.content.filterValues[key] = this.bsModalRef.content.filterValues[key].toString();
                            }
                        }
                    }
                }
                this.checkForBackgroundTasks();
            } else if (result === false) {
                // They pressed No
            } else {
                // Modal was just hidden
            }
        });
    }

    checkForBackgroundTasks() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if (this.auth.isLoggedIn()) {
            this.backgroundTaskService.getBackgroundTasks(this.pageFilterServiceValues, this.page, this.perPage).subscribe((response) => {
                this.backgroundTasks = response.data.data;
                this.pageCount = Math.ceil(response.data.rowCount / this.perPage);
                this.getPages();
                this.timer = setTimeout(() => {
                    this.checkForBackgroundTasks();
                }, 10000);
            });
        } else {
            this.timer = setTimeout(() => {
                this.checkForBackgroundTasks();
            }, 10000);
        }
    }


    private pageExists(pageNum: number) {
        if (pageNum < 1) {
            return false;
        }

        if (pageNum > this.pageCount) {
            return false;
        }

        return true;
    }

    public setPage(pageNum: number) {
        if (!this.pageExists(pageNum)) {
            pageNum = 1;
        }

        this.page = pageNum;
        this.checkForBackgroundTasks();
    }

    public nextPage() {
        let pageNum = this.page + 1;
        if (!this.pageExists(pageNum)) {
            pageNum = this.pageCount;
        }
        this.setPage(pageNum);
    }

    public prevPage() {
        let pageNum = this.page - 1;
        if (!this.pageExists(pageNum)) {
            pageNum = 1;
        }
        this.setPage(pageNum);
    }

    public isLastPage() {
        return (this.page === this.pageCount);
    }

    public isFirstPage() {
        return (this.page === 1);
    }

    public firstPage() {
        this.setPage(1);
    }

    public lastPage() {
        const pageNum = this.pageCount;
        this.setPage(pageNum);
    }


    public getPages() {
        const lastPage = this.pageCount;
        const pages = [];
        let firstPage = Math.max(1, (this.page) - Math.floor(this.maxPages / 2));
        const difference = lastPage - ((firstPage - 1) + this.maxPages);
        if (difference < 0) {
            firstPage = firstPage + difference;
            if (firstPage < 1) {
                firstPage = 1;
            }
        }

        for (let i = firstPage; i <= lastPage && pages.length < this.maxPages; i++) {
            pages.push(i);
        }
        this.pages = pages;
    }

    downloadBackgroundTask(backgroundTask) {
        this.backgroundTaskService.download(backgroundTask.id).subscribe((response: HttpResponse<Blob>) => {
            const fileName = backgroundTask.content.replace(/.*[\/,\\]/, '');

            const link = document.createElement('a');
            link.download = fileName;
            link.href = URL.createObjectURL(response.body);
            link.click();
        })
    }

    deleteBackgroundTask(backgroundTask) {
        if (confirm('Are you sure you want to delete this?')) {
            this.backgroundTaskService.delete(backgroundTask.id).subscribe((data) => {
                this.checkForBackgroundTasks();
            })
        }

    }

    clear() {
        if (confirm('Are you sure you want to delete all filtered tasks?')) {
            this.backgroundTaskService.clear(this.pageFilterServiceValues).subscribe((data) => {
                this.checkForBackgroundTasks();
            })
        }

    }
}

